<div class="mb-3">
  <label for="description">
    {{ "GENERALS.LOCATION" }}
  </label>
  <div class="mb-3">
    <input
      type="text"
      class="form-control"
      (keydown.enter)="$event.preventDefault()"
      placeholder="Search Location"
      type="text"
      [value]="address"
      [id]="'search' + id"
      #search
    />
  </div>
  <google-map
    #myGoogleMap
    height="300px"
    width="100%"
    [zoom]="zoom"
    [center]="center"
    [options]="options"
    (mapClick)="mapClicked($event)"
  >
    <map-marker [position]="center"></map-marker>
  </google-map>
</div>
