import { ColorPickerModule } from "ngx-color-picker";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { Ng2SearchPipeModule } from "ng2-search-filter";
import { TabsModule } from "ngx-bootstrap/tabs";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { ModalModule } from "ngx-bootstrap/modal";
import { PaginationModule } from "ngx-bootstrap/pagination";

import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { NgSelectModule } from "@ng-select/ng-select";
import { UiSwitchModule } from "ngx-ui-switch";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { CarouselModule } from "ngx-bootstrap/carousel";
import { NgApexchartsModule } from "ng-apexcharts";
import { SimplebarAngularModule } from "simplebar-angular";

import { QRCodeModule } from "angularx-qrcode";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { TranslateModule } from "@ngx-translate/core";
import { BrandBarComponent } from "./brand-bar/brand-bar.component";
import { NavBarComponent } from "./navbar/navbar.component";
import { CommonTabComponent } from "./common-tab-component/common-tab-component.component";
import { FeedsComponent } from "./feeds/feeds.component";
import { WebViewComponent } from "./web-view/web-view.component";
import { VideoViewComponent } from "./video-view/video-view.component";
import { SearchViewComponent } from "./search-view/search-view.component";
import { MenusComponent } from "./menus/menus.component";
import { QrViewComponent } from "./qr-view/qr-view.component";
import { QrPageViewComponent } from "./qr-page-view/qr-page-view.component";
import { ButtonSeparatorComponent } from "./button-separators/button-separator.component";
import { ButtonSubmitComponent } from "./button-submit/button-submit.component";
import { ButtonInputComponent } from "./button-input/button-input.component";
import { ButtonSingleChoiceComponent } from "./button-single-choice/button-single-choice.component";
import { ButtonMultiChoiceComponent } from "./button-multi-choice/button-multi-choice.component";
import { OffcanvasTabDragComponent } from "./offcanvas-tab-drag/offcanvas-tab-drag.component";
import { OffcanvasButtonDragComponent } from "./offcanvas-button-drag/offcanvas-button-drag.component";
import {DragDropModule} from '@angular/cdk/drag-drop';
import { OffcanvasTabSettingsComponent } from "./offcanvas-tab-settings/offcanvas-tab-settings.component";
import { OffcanvasSettingsComponentModule } from "../offcanvas-settings-component/offcanvas-settings-component.module";
import { MapSearchViewComponent } from "./map-search-view/map-search-view.component";
import { OffcanvasButtonSettingsComponent } from "./offcanvas-button-settings/offcanvas-button-settings.component";
import { ButtonPageTextComponent } from "./button-pagetext/button-pagetext.component";
import { ButtonPageSliderComponent } from "./button-pageslider/button-pageslider.component";
import { ChatViewComponent } from "./chat-view-component/chat-view-component.component";
import { ButtonPageTextViewComponent } from "./button-pagetext-view/button-pagetext-view.component";
import { ButtonPageGalleryComponent } from "./button-pagegallery/button-pagegallery.component";
import { ButtonPageSingleImageComponent } from "./button-pagesingleimage/button-pagesingleimage.component";
import { ButtonPageHeaderComponent } from "./button-pageheader/button-pageheader.component";
import { ButtonPageDividerComponent } from "./button-pagedivider/button-pagedivider.component";
import { ButtonPageVideoComponent } from "./button-pagevideo/button-pagevideo.component";
import { ButtonPageQrCodeComponent } from "./button-pageqrcode/button-pageqrcode.component";
import { ButtonPageLocationComponent } from "./button-pagelocation/button-pagelocation.component";
import { GoogleMapsModule } from "@angular/google-maps";
import { ButtonPagePdfViewerComponent } from "./button-pagepdfviewer/button-pagepdfviewer.component";
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonPageSocialBarComponent } from "./button-pagesocialbar/button-pagesocialbar.component";
import { ProgressParComponent } from "./progress-par/progress-par.component";
import { ButtonPageSliderLabelComponent } from "./button-pagesliderlabel/button-pagesliderlabel.component";
import { ButtonOutputComponent } from "./button-output/button-output.component";
import { ButtonDropdownComponent } from "./button-dropdown/button-dropdown.component";
import { ButtonSplashComponent } from "./button-splash/button-splash.component";
import { OffcanvasGenSettingsComponent } from "./offcanvas-gen-settings/offcanvas-gen-settings.component";
import { ButtonToggleComponent } from "./button-toggle/button-toggle.component";
import { ButtonCardComponent } from "./button-card/button-card.component";
import { ButtonCollectionComponent } from "./button-collection/button-collection.component";
import { PaletteComponent } from "./palette/palette.component";
import { MenuSliderComponent } from "./menu-slider/menu-slider.component";
import { MobileViewComponent } from "./mobile-view/mobile-view.component";
import { MobileViewButtonComponent } from "./mobile-view-button/mobile-view-button.component";
import { MobileViewSliderComponent } from "./mobile-view-slider/mobile-view-slider.component";
import { MobileViewTabComponent } from "./mobile-view-tab/mobile-view-tab.component";
import { OnClickNextMenuComponent } from "./onClick-next-menu/onClick-next-menu.component";
import { SharedModule } from "../shared/shared.module";
import { ScreenSelectorComponent } from "./screen-selector/screen-selector.component";

const config: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "https://httpbin.org/post",
  maxFilesize: 100,
};


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    BrandBarComponent,
    NavBarComponent,
    CommonTabComponent,
    FeedsComponent,
    WebViewComponent,
    VideoViewComponent,
    SearchViewComponent,
    MenusComponent,
    QrViewComponent,
    QrPageViewComponent,
    ChatViewComponent,
    ButtonSeparatorComponent,
    ButtonSubmitComponent,
    ButtonInputComponent,
    ButtonSingleChoiceComponent,
    ButtonMultiChoiceComponent,
    OffcanvasTabDragComponent,
    OffcanvasButtonDragComponent,
    OffcanvasTabSettingsComponent,
    MapSearchViewComponent,
    OffcanvasButtonSettingsComponent,
    ButtonPageSliderComponent,
    ButtonPageTextComponent,
    ButtonPageTextViewComponent,
    ButtonPageGalleryComponent,
    ButtonPageSingleImageComponent,
    ButtonPageHeaderComponent,
    ButtonPageDividerComponent,
    ButtonPageVideoComponent,
    ButtonPageQrCodeComponent,
    ButtonPageLocationComponent,
    ButtonPagePdfViewerComponent,
    ButtonPageSocialBarComponent,
    ProgressParComponent,
    ButtonPageSliderLabelComponent,
    ButtonCardComponent,
    ButtonCollectionComponent,
    ButtonOutputComponent,
    ButtonDropdownComponent,
    ButtonSplashComponent,
    OffcanvasGenSettingsComponent,
    ButtonToggleComponent,
    PaletteComponent,
    MenuSliderComponent,
    MobileViewComponent,
    MobileViewButtonComponent,
    MobileViewSliderComponent,
    MobileViewTabComponent,
    OnClickNextMenuComponent,
    ScreenSelectorComponent
  ],
  imports: [
    CommonModule,
    TabsModule.forRoot(),
    ModalModule.forRoot(),
    FormsModule,
    BsDropdownModule.forRoot(),
    DropzoneModule,
    ReactiveFormsModule,
    NgSelectModule,
    PaginationModule.forRoot(),
    UiSwitchModule,
    CKEditorModule,
    CarouselModule.forRoot(),
    NgApexchartsModule,
    SimplebarAngularModule,
    TranslateModule,
    QRCodeModule,
    AccordionModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DragDropModule,
    OffcanvasSettingsComponentModule,
    GoogleMapsModule,
    PdfViewerModule,

  ],
  exports: [
    BrandBarComponent,
    NavBarComponent,
    CommonTabComponent,
    FeedsComponent,
    WebViewComponent,
    VideoViewComponent,
    SearchViewComponent,
    SearchViewComponent,
    MenusComponent,
    ChatViewComponent,
    QrViewComponent,
    QrPageViewComponent,
    OffcanvasTabDragComponent,
    OffcanvasButtonDragComponent,
    OffcanvasTabSettingsComponent,
    OffcanvasGenSettingsComponent,
    MapSearchViewComponent,
    OffcanvasButtonSettingsComponent,
    ButtonPageTextComponent,
    ButtonPageSliderComponent,
    ButtonPageTextViewComponent,
    ButtonPageGalleryComponent,
    ButtonPageSingleImageComponent,
    ButtonPageHeaderComponent,
    ButtonPageDividerComponent,
    ButtonPageVideoComponent,
    ButtonPageQrCodeComponent,
    ButtonPageLocationComponent,
    ButtonPagePdfViewerComponent,
    ButtonPageSocialBarComponent,
    ProgressParComponent,
    ButtonPageSliderLabelComponent,
    ButtonCardComponent,
    ButtonCollectionComponent,
    ButtonOutputComponent,
    ButtonDropdownComponent,
    ButtonToggleComponent,
    PaletteComponent,
    MenuSliderComponent,
    MobileViewComponent,
    MobileViewButtonComponent,
    MobileViewSliderComponent,
    MobileViewTabComponent,
    OnClickNextMenuComponent,
    ScreenSelectorComponent,

  ],
})
export class BuilderComponentModule {}
