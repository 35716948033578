import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import customBuild from "src/ckeditor5/build/ckeditor";
import {
  PaymentProviderIDs,
  VendorsNames,
  VendorsTypes,
  mobCountries,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Smtp } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";
import {
  GetPaymentProviders,
  SetPaymentProvider,
} from "src/app/core/services/outbound";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";

@Component({
  selector: "app-gen-appPayment-inappPurchases",
  templateUrl: "./gen-appPayment-inappPurchases.component.html",
  styleUrls: ["./gen-appPayment-inappPurchases.component.scss"],
})
export class GenAppPaymentInappPurchasesComponent
  implements OnInit, OnDestroy
{
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService,
    private modalService: BsModalService
  ) {}

  inAppPurchasesform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() page_id: string;
  button: MenuButton;
  mode: string;
  public Editor = customBuild;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  verifySubscription: any;
  paymentProvidersSubscription: any;
  testedMessagingSubscription: any;
  thirdPartyInfoSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  component_ref: any;

  message: any;

  submitted = false;
  vendorTestSubmitted = false;

  loadding = false;

  smtpOpj: Smtp;
  app_info: any = {};
  showTest = false;
  modalRef?: BsModalRef;
  paymentProviderIDs = PaymentProviderIDs;
  inAppPurchasesConf: any;

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);
    this.inAppPurchasesform = this.formBuilder.group({
      active: new UntypedFormControl(0),
      file: new UntypedFormControl(""),
      provider_id: new UntypedFormControl(this.paymentProviderIDs.INAPPGOOGLE),
    });

    this.field_id = "lable";

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          this.buttonFunction();
        }
      }
    );

    this.paymentProvidersSubscription =
      this.ws.paymentProvidersContainer$.subscribe((data) => {
        if (data && data.ref === this.component_ref) {
          const provider = data.data.find(
            (provider) =>
              provider.provider_id === this.paymentProviderIDs.INAPPGOOGLE
          );
          this.inAppPurchasesConf =
            provider && provider.config ? provider.config : null;

          this.inAppPurchasesform = this.formBuilder.group({
            active: new UntypedFormControl(
              (provider && provider.active) ||
              (this.app_info &&
                this.app_info.payment_provider.includes(
                  this.paymentProviderIDs.INAPPGOOGLE
                ))
                ? 1
                : 0
            ),
            file: new UntypedFormControl(""),
            provider_id: new UntypedFormControl(
              this.paymentProviderIDs.INAPPGOOGLE
            ),
          });
        }
      });

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          const appInfo = this.indexDBService.getItem("item", "app_info");
          appInfo.onsuccess = (event) => {
            if (appInfo.result) {
              this.app_info = appInfo.result;
              this.ws.processMethod(
                new GetPaymentProviders(this.component_ref)
              );
            }
          };
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;
    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  fileChange(event: Event) {
    const file = (event.target as HTMLInputElement).files[0]; // Here we use only the first file (single file)
    this.inAppPurchasesform.patchValue({ file: file });
  }

  onSubmit() {
    this.submitted = true;
    if (this.inAppPurchasesform.invalid) {
      return;
    } else {
      this.setInAppPurchases();
    }
  }

  async setInAppPurchases() {
    const fileReader = await this.readFileAsText(
      this.inAppPurchasesform.value.file
    );
    const config = JSON.parse(fileReader);
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result) {
        this.app_info = appInfo.result;
        if (this.app_info.payment_provider) {
          let paymentProvider = [...this.app_info.payment_provider];
          if (this.inAppPurchasesform.value.active) {
            if (
              !paymentProvider.includes(
                this.inAppPurchasesform.value.provider_id
              )
            ) {
              paymentProvider.push(this.inAppPurchasesform.value.provider_id);
            }
          } else {
            paymentProvider = paymentProvider.filter(
              (res) => res !== this.inAppPurchasesform.value.provider_id
            );
          }
          this.app_info["payment_provider"] = paymentProvider;
          this.indexDBService.updateItem("item", this.app_info);
        } else {
          this.app_info["payment_provider"] = [
            this.inAppPurchasesform.value.provider_id,
          ];
          this.indexDBService.updateItem("item", this.app_info);
        }
      }
    };
    this.ws.processMethod(
      new SetPaymentProvider(
        this.inAppPurchasesform.value.active,
        config,
        this.inAppPurchasesform.value.provider_id,
        this.component_ref
      )
    );
  }

  buttonFunction() {
    this.button_label = this.builderService.getButtonSplashItem(
      this.button,
      "button_label",
      this.data.splashTab.param.splash.style
    );
    if (this.button_label) {
      this.inAppPurchasesform = this.formBuilder.group({
        lableControl: new UntypedFormControl(this.button_label),
      });
    }
  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.button.button_label = this.f.lableControl.value;
      this.updateButtonData();
    }
  }

  get f() {
    if (this.inAppPurchasesform && this.inAppPurchasesform.controls) {
      return this.inAppPurchasesform.controls;
    }
  }

  colorChange(event) {
    this.button.button_label_color = this.labelColor;
    this.updateButtonData();
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "splash" } },
      this.mode,
      true
    );
  }

  enableStripePayment(status) {
    this.inAppPurchasesform.patchValue({
      active: status ? 1 : 0,
    });

    if (status) {
      this.inAppPurchasesform.get("file").enable();
    } else {
      this.inAppPurchasesform.get("file").disable();
    }
  }

  readFileAsText(file: File): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = (event: any) => resolve(event.target.result);
    });
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.paymentProvidersSubscription) {
      this.paymentProvidersSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
    if (this.testedMessagingSubscription) {
      this.testedMessagingSubscription.unsubscribe();
    }
    if (this.thirdPartyInfoSubscription) {
      this.thirdPartyInfoSubscription.unsubscribe();
    }
  }
}
