<div
  class="offcanvas offcanvas-end navbar-brand-box p-0 z-1"
  style="top: 70px; width: 400px !important; visibility: visible !important"
  data-bs-scroll="true"
  data-bs-backdrop="false"
  tabindex="-1"
  id="offcanvasScrollingGenConf"
  aria-labelledby="offcanvasScrollingLabel"
>
  <div class="offcanvas-header ps-0 mb-0 offcanvas-divider">
    <div id="sidebar-menu" class="offcanvas-menu p-0">
      <ul class="metismenu list-unstyled">
        <li draggable="false">
          <a class="side-nav-link-ref">
            <!-- <span>  Component: {{title}}</span> -->
            <h5 class="mb-0">
              {{ title }}
            </h5>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="text-reset enablePointer"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      (click)="hideOffcanvas()"
    >
      <i class="mdi mdi-close font-size-22 icon-close"></i>
    </div>
  </div>
  <div class="offcanvas-body p-0 mt-3 mb-0">
    <ng-container *ngIf="offcanvas_data.length == 1">
      <ng-container *ngFor="let tab of offcanvas_data">
        <div id="sidebar-menu" class="offcanvas-menu p-0">
          <!-- Left Menu Start -->
          <ul class="metismenu list-unstyled p-0">
            <!-- {{menuItems}} -->
            <ng-container *ngFor="let cat of tab?.data; let i = index">
              <li
                *ngIf="cat.show"
                draggable="false"
                class="bg-offcanvas-header"
              >
                <a class="side-nav-link-ref p-0">
                  <span>{{ cat.cat }}</span>
                </a>
              </li>

              <div *ngFor="let item of cat?.list; let j = index">
                <!-- **** componets Start **** -->

                <div
                  *ngIf="item?.anavtab"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-anavtab-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-anavtab-component>
                </div>

                <div
                  *ngIf="item?.anavtabstyle"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-anavtabstyle-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-anavtabstyle-component>
                </div>

                <div
                  *ngIf="item?.anavcolor"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-anavcolor-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-anavcolor-component>
                </div>

                <div
                  *ngIf="item?.inavinfo"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-inavinfo-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-inavinfo-component>
                </div>

                <div
                  *ngIf="item?.inavtab"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-inavtab-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-inavtab-component>
                </div>

                <div
                  *ngIf="item?.inavcolor"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-inavcolor-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-inavcolor-component>
                </div>

                <div
                  *ngIf="item?.inavtabstyle"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-inavtabstyle-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-inavtabstyle-component>
                </div>

                <div
                  *ngIf="item?.asidetoggle"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-asidetoggle-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-asidetoggle-component>
                </div>

                <div
                  *ngIf="item?.isidetoggle"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-isidetoggle-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-isidetoggle-component>
                </div>

                <div
                  *ngIf="item?.iside"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-iside-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-iside-component>
                </div>

                <div
                  *ngIf="item?.isideinfo"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-isideinfo-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-isideinfo-component>
                </div>

                <div
                  *ngIf="item?.asidecolor"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-asidecolor-component
                    [page_id]="page_id"
                    [tabData]="current_tab"
                  ></app-gen-asidecolor-component>
                </div>
                <div
                  *ngIf="item?.buttonapploginmessagehtml"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-apploginmessage-html
                    [page_id]="page_id"
                  ></app-gen-apploginmessage-html>
                </div>
                <div
                  *ngIf="item?.buttonsmtpserver"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-apploginsmtp-server
                    [page_id]="page_id"
                  ></app-gen-apploginsmtp-server>
                </div>

                <div
                  *ngIf="item?.buttonAllowUsertoChangeEmail"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-changeemail
                    [page_id]="page_id"
                  ></app-gen-changeemail>
                </div>
                <div
                  *ngIf="item?.buttonAppLoginVendorsMobile"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-apploginvendors-mobile
                    [page_id]="page_id"
                  ></app-gen-apploginvendors-mobile>
                </div>
                <div
                  *ngIf="item?.buttonAppLoginverificationaccount"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-verificationaccount
                    [page_id]="page_id"
                  ></app-gen-verificationaccount>
                </div>
                <div
                  *ngIf="item?.buttonAppPaymentStripe"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-appPayment-stripe
                    [page_id]="page_id"
                  ></app-gen-appPayment-stripe>
                </div>

                <div
                  *ngIf="item?.buttonAppPaymentPaypal"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-appPayment-paypal
                    [page_id]="page_id"
                  ></app-gen-appPayment-paypal>
                </div>
                <div
                  *ngIf="item?.buttonAppPaymentInappPurchases"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-appPayment-inappPurchases
                    [page_id]="page_id"
                  ></app-gen-appPayment-inappPurchases>
                </div>
                <div
                  *ngIf="item?.buttonAppPaymentGoogleAdsAndroid"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-appPaymentInGoogleAds-android
                    [page_id]="page_id"
                  ></app-gen-appPaymentInGoogleAds-android>
                </div>
                <div
                  *ngIf="item?.buttonAppPaymentGoogleAdsIOS"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-appPaymentInGoogleAds-ios
                    [page_id]="page_id"
                  ></app-gen-appPaymentInGoogleAds-ios>
                </div>
                <div
                  *ngIf="item?.buttonAppPaymentGoogleAdsIOS"
                  [ngClass]="
                    i == 0 && j == 0
                      ? i == tab.data.length - 1 && j == cat.list.length - 1
                        ? ''
                        : 'border-bottom-3d'
                      : i == tab.data.length - 1 && j == cat.list.length - 1
                      ? 'border-top-3d'
                      : 'border-3d'
                  "
                >
                  <app-gen-appPaymentInGoogleAds-ios
                    [page_id]="page_id"
                  ></app-gen-appPaymentInGoogleAds-ios>
                </div>

                <!-- **** componets Ends **** -->
              </div>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </ng-container>

    <tabset
      #tabset
      id="tabset"
      [justified]="true"
      class="nav-tabs nav-tabs-custom nav-justified tabPrimaryFilled"
      *ngIf="offcanvas_data.length > 1"
    >
      <ng-container *ngFor="let tab of offcanvas_data">
        <tab
          [heading]="tab?.tab"
          id="{{ tab?.id }}"
          [active]="tab?.active"
          (selectTab)="tabSetChange($event)"
          class="pb-2"
        >
          <div id="sidebar-menu" class="offcanvas-menu p-0">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled p-0">
              <!-- {{menuItems}} -->
              <ng-container *ngFor="let cat of tab?.data; let i = index">
                <li
                  *ngIf="cat.show"
                  draggable="false"
                  class="bg-offcanvas-header"
                >
                  <a class="side-nav-link-ref">
                    <span>{{ cat.cat }}</span>
                  </a>
                </li>

                <div *ngFor="let item of cat?.list; let j = index">
                  <!-- **** componets Start **** -->

                  <div
                    *ngIf="item?.anavtab"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-anavtab-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-anavtab-component>
                  </div>

                  <div
                    *ngIf="item?.anavtabstyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-anavtabstyle-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-anavtabstyle-component>
                  </div>

                  <div
                    *ngIf="item?.anavcolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-anavcolor-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-anavcolor-component>
                  </div>

                  <div
                    *ngIf="item?.inavinfo"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-inavinfo-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-inavinfo-component>
                  </div>

                  <div
                    *ngIf="item?.inavtab"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-inavtab-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-inavtab-component>
                  </div>

                  <div
                    *ngIf="item?.inavcolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-inavcolor-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-inavcolor-component>
                  </div>

                  <div
                    *ngIf="item?.inavtabstyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-inavtabstyle-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-inavtabstyle-component>
                  </div>

                  <div
                    *ngIf="item?.asidetoggle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-asidetoggle-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-asidetoggle-component>
                  </div>

                  <div
                    *ngIf="item?.isidetoggle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-isidetoggle-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-isidetoggle-component>
                  </div>

                  <div
                    *ngIf="item?.iside"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-iside-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-iside-component>
                  </div>

                  <div
                    *ngIf="item?.isideinfo"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-isideinfo-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-isideinfo-component>
                  </div>

                  <div
                    *ngIf="item?.asidecolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-asidecolor-component
                      [page_id]="page_id"
                      [tabData]="current_tab"
                    ></app-gen-asidecolor-component>
                  </div>

                  <div
                    *ngIf="item?.buttonapploginmessagehtml"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-apploginmessage-html
                      [page_id]="page_id"
                    ></app-gen-apploginmessage-html>
                  </div>
                  <div
                    *ngIf="item?.buttonsmtpserver"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-apploginsmtp-server
                      [page_id]="page_id"
                    ></app-gen-apploginsmtp-server>
                  </div>
                  <div
                    *ngIf="item?.buttonAllowUsertoChangeEmail"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-changeemail
                      [page_id]="page_id"
                    ></app-gen-changeemail>
                  </div>

                  <div
                    *ngIf="item?.buttonAppLoginVendorsMobile"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-apploginvendors-mobile
                      [page_id]="page_id"
                    ></app-gen-apploginvendors-mobile>
                  </div>

                  <div
                    *ngIf="item?.buttonAppLoginverificationaccount"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-verificationaccount
                      [page_id]="page_id"
                    ></app-gen-verificationaccount>
                  </div>

                  <div
                    *ngIf="item?.buttonAppPaymentStripe"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-appPayment-stripe
                      [page_id]="page_id"
                    ></app-gen-appPayment-stripe>
                  </div>
                  <div
                    *ngIf="item?.buttonAppPaymentPaypal"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-appPayment-paypal
                      [page_id]="page_id"
                    ></app-gen-appPayment-paypal>
                  </div>
                  <div
                    *ngIf="item?.buttonAppPaymentInappPurchases"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-appPayment-inappPurchases
                      [page_id]="page_id"
                    ></app-gen-appPayment-inappPurchases>
                  </div>

                  <div
                    *ngIf="item?.buttonAppPaymentGoogleAdsAndroid"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-appPaymentInGoogleAds-android
                      [page_id]="page_id"
                    ></app-gen-appPaymentInGoogleAds-android>
                  </div>

                  <div
                    *ngIf="item?.buttonAppPaymentGoogleAdsIOS"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-gen-appPaymentInGoogleAds-ios
                      [page_id]="page_id"
                    ></app-gen-appPaymentInGoogleAds-ios>
                  </div>

                  <!-- **** componets Ends  **** -->
                </div>
              </ng-container>
            </ul>
          </div>
        </tab>
      </ng-container>
    </tabset>
  </div>
</div>
