import { Component , OnInit} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { WebsocketService } from './core/services/websocket.service';
import { LoaderService } from './core/services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

  refreshSubscription: any;
  routeExclude = [
    "/calendars/edit"
  ]
  constructor(
    private router: Router,
    private ws: WebsocketService,
    private load: LoaderService
  ){}

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.refreshSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if(!this.router.navigated && event.url.slice(0, 4) !== "/hub"){
          console.log("LOADER TRUE");
          this.load.isLoading.next(true);
          console.log("reconnect app");
          this.ws.reconnect(1);
        }
      }
      if (event instanceof NavigationEnd) {
        // console.log("url" + this.router.url);
        if(this.router.url.slice(0, 4) !== "/hub" && !(this.routeExclude.includes(this.router.url))){
          localStorage.setItem("returnUrl", this.router.url);
          // console.log("PRODUCTION" + environment.production);
        }
      }
    });
  }
}
