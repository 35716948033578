<div class="card m-0 bg-transparent">
  <div class="card-body">
    <h5
      *ngIf="config?.title"
      class="card-title text-start offcanvas-header-text mb-3"
    >
      {{ os_name }} Main Settings
    </h5>
    <ng-container *ngIf="config?.icon || config?.trailing_icon || config?.text">
      <div class="row mb-4">
        <ng-container *ngIf="config?.icon">
          <div
            class="me-auto"
            [ngClass]="
              config?.icon && config?.trailing_icon
                ? 'col-6'
                : 'col-3 pe-0 order-first'
            "
          >
            <label class="offcanvas-header-text w-100" style="text-align: left">
              {{
                config?.icon && !config?.trailing_icon ? "Icon" : "Leading Icon"
              }}
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <button
                type="button"
                class="form-control input-lg offcanvas-primary border-0 d-flex align-items-center justify-content-center"
                (click)="openSelector(selectorIcons, 0)"
              >
                <i
                  class="fs-4"
                  [class]="
                    icon?.type == 'mir'
                      ? 'material-icons-outlined'
                      : 'material-icons'
                  "
                >
                  {{ icon?.id }}
                </i>
              </button>
              <button
                type="button"
                class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
                (click)="openSelector(selectorIcons, 0)"
              >
                <i class="mdi mdi-chevron-down"></i>
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="config?.trailing_icon">
          <div
            [ngClass]="
              config?.icon && config?.trailing_icon
                ? 'col-6'
                : 'col-3 ps-0 order-last'
            "
          >
            <label class="offcanvas-header-text w-100" style="text-align: left">
              {{
                !config?.icon && config?.trailing_icon
                  ? "Icon"
                  : "Trailing Icon"
              }}
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <button
                type="button"
                class="form-control input-lg offcanvas-primary border-0 d-flex align-items-center justify-content-center"
                (click)="openSelector(selectorIcons, 1)"
              >
                <i
                  class="fs-4"
                  [class]="
                    trailing_icon?.type == 'mir'
                      ? 'material-icons-outlined'
                      : 'material-icons'
                  "
                >
                  {{ trailing_icon?.id }}
                </i>
              </button>
              <button
                type="button"
                class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
                (click)="openSelector(selectorIcons, 1)"
              >
                <i class="mdi mdi-chevron-down"></i>
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="config?.text">
          <div
            [ngClass]="
              config?.icon && config?.trailing_icon
                ? 'col-12 w-100 order-first mb-3'
                : config?.icon || config?.trailing_icon
                ? 'col-9'
                : 'col-12 w-100 order-first'
            "
          >
            <label
              for="text-color"
              class="offcanvas-header-text w-100"
              style="text-align: left"
            >
              {{ config?.name }}
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <form class="needs-validation w-100" [formGroup]="labelform">
                <input
                  type="text"
                  id="lablecolor"
                  formControlName="lableControl"
                  class="form-control input-lg offcanvas-primary border-0"
                  [value]="button_text"
                  (change)="buttonLabel()"
                  placeholder="Lable"
                  [ngClass]="{ 'is-invalid': f.lableControl.errors }"
                />
                <div *ngIf="f.lableControl.errors" class="invalid-feedback">
                  <span *ngIf="f.lableControl.errors.required">
                    Field is required
                  </span>
                  <span *ngIf="f.lableControl.errors.maxlength">
                    Actual length is
                    {{ f.lableControl.errors.maxlength.actualLength }} required
                    not more than
                    {{ f.lableControl.errors.maxlength.requiredLength }}
                    chars.
                  </span>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <div class="row mb-3" *ngIf="config?.font">
      <div class="col-12 w-100 d-flex flex-column align-items-start">
        <label class="offcanvas-header-text w-100" style="text-align: left">
          Font Size
        </label>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            [name]="'btnradiofont' + button?.button_id + fontID"
            [id]="'font1' + button?.button_id + fontID"
            autocomplete="off"
            [checked]="fontSize === 'md'"
            (change)="updateFontSize('md')"
          />
          <label
            class="btn btn-secondary"
            [for]="'font1' + button?.button_id + fontID"
            >Default</label
          >

          <input
            type="radio"
            class="btn-check"
            [name]="'btnradiofont' + button?.button_id + fontID"
            [id]="'font2' + button?.button_id + fontID"
            autocomplete="off"
            [checked]="fontSize === 'lg'"
            (change)="updateFontSize('lg')"
          />
          <label
            class="btn btn-secondary"
            [for]="'font2' + button?.button_id + fontID"
            >Large</label
          >

          <input
            type="radio"
            class="btn-check"
            [name]="'btnradiofont' + button?.button_id + fontID"
            [id]="'font3' + button?.button_id + fontID"
            autocomplete="off"
            [checked]="fontSize === 'sm'"
            (change)="updateFontSize('sm')"
          />
          <label
            class="btn btn-secondary"
            [for]="'font3' + button?.button_id + fontID"
            >Small</label
          >
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="config?.align">
      <div class="col-12 w-100 d-flex flex-column align-items-start">
        <label class="offcanvas-header-text w-100" style="text-align: left">
          Text Alignment :: {{ text_id }}
        </label>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            [name]="'alignment' + button?.button_id + alginID"
            [id]="'alignment1' + button?.button_id + alginID"
            autocomplete="off"
            [checked]="textAlign === 'left'"
            (change)="updateLapleAlignmet('left')"
          />
          <label
            class="btn btn-secondary"
            [for]="'alignment1' + button?.button_id + alginID"
            >Left</label
          >

          <input
            type="radio"
            class="btn-check"
            [name]="'alignment' + button?.button_id + alginID"
            [id]="'alignment2' + button?.button_id + alginID"
            autocomplete="off"
            [checked]="textAlign === 'center'"
            (change)="updateLapleAlignmet('center')"
          />
          <label
            class="btn btn-secondary"
            [for]="'alignment2' + button?.button_id + alginID"
            >Center</label
          >

          <input
            type="radio"
            class="btn-check"
            [name]="'alignment' + button?.button_id + alginID"
            [id]="'alignment3' + button?.button_id + alginID"
            autocomplete="off"
            [checked]="textAlign === 'right'"
            (change)="updateLapleAlignmet('right')"
          />
          <label
            class="btn btn-secondary"
            [for]="'alignment3' + button?.button_id + alginID"
            >Right</label
          >
        </div>
      </div>
    </div>
    <div
      *ngIf="config?.color"
      class="row align-items-center"
      [class.mt-4]="config?.text"
    >
      <label
        for="bg-color"
        class="offcanvas-header-text col-9 m-0"
        style="text-align: left"
      >
        {{ config?.name }} Tint
      </label>
      <div
        class="col-3 text-start p-0"
        style="margin-bottom: 0.5rem"
        *ngIf="!enableCustomColor"
      >
        <a class="link-primary curser-pointer" (click)="toggle(true)"> Set </a>
      </div>
      <ng-container *ngIf="enableCustomColor">
        <div class="col-9 mt-2">
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="bg-color"
              class="form-control input-lg offcanvas-primary border-0"
              (change)="colorChange($event, true)"
              [value]="labelColor"
              [readOnly]="!enableCustomColor"
            />
            <span class="input-group-text p-1 border-0 offcanvas-primary">
              <span
                class="input-group-text colorpicker-input-addon h-100"
                [style.background]="labelColor"
                [cpOutputFormat]="'hex'"
                [(colorPicker)]="labelColor"
                [cpPosition]="'bottom-left'"
                (colorPickerChange)="colorChange($event, false)"
                [cpDisabled]="!enableCustomColor"
              >
              </span>
            </span>
          </div>
        </div>
        <div class="col-3 text-start p-0 mt-2">
          <a class="link-primary curser-pointer" (click)="toggle(false)">
            Remove
          </a>
        </div>
      </ng-container>
    </div>
    <div
      *ngIf="config?.setGradient"
      class="row align-items-center"
      [class.mt-4]="config?.text"
    >
      <label
        for="bg-color"
        class="offcanvas-header-text col-9 m-0"
        style="text-align: left"
      >
        set Gradient
      </label>
      <div
        class="col-3 text-start p-0"
        style="margin-bottom: 0.5rem"
        *ngIf="!enableCustomGradientColor"
      >
        <a
          class="link-primary curser-pointer"
          (click)="toggleGradientColor(true)"
        >
          Set
        </a>
      </div>
      <div *ngIf="enableCustomGradientColor" class="col-3 text-start p-0">
        <a
          class="link-primary curser-pointer"
          (click)="toggleGradientColor(false)"
        >
          Remove
        </a>
      </div>
      <ng-container *ngIf="enableCustomGradientColor">
        <div class="col-6 mt-2">
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="bg-color"
              class="form-control input-lg offcanvas-primary border-0"
              (change)="startColorChange($event, true)"
              [value]="startColor"
              [readOnly]="!enableCustomGradientColor"
            />
            <span class="input-group-text p-1 border-0 offcanvas-primary">
              <span
                class="input-group-text colorpicker-input-addon h-100"
                [style.background]="startColor"
                [cpOutputFormat]="'hex'"
                [(colorPicker)]="startColor"
                [cpPosition]="'bottom-left'"
                (colorPickerChange)="startColorChange($event, false)"
                [cpDisabled]="!enableCustomGradientColor"
              >
              </span>
            </span>
          </div>
        </div>
        <div class="col-6 mt-2">
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="bg-color"
              class="form-control input-lg offcanvas-primary border-0"
              (change)="endColorChange($event, true)"
              [value]="endColor"
              [readOnly]="!enableCustomGradientColor"
            />
            <span class="input-group-text p-1 border-0 offcanvas-primary">
              <span
                class="input-group-text colorpicker-input-addon h-100"
                [style.background]="endColor"
                [cpOutputFormat]="'hex'"
                [(colorPicker)]="endColor"
                [cpPosition]="'bottom-left'"
                (colorPickerChange)="endColorChange($event, false)"
                [cpDisabled]="!enableCustomGradientColor"
              >
              </span>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <div *ngIf="config?.divider" class="form-check d-flex align-items-center p-0">
      <label for="formReply" class="offcanvas-header-text w-100 m-0">
        Divider
      </label>
      <input
        type="checkbox"
        id="formReply"
        class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
        style="width: 18px; height: 18px"
        (change)="toggleDivider($event)"
      />
    </div>
  </div>
</div>

<ng-template #selectorIcons let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">
      {{ iconType ? "Choose Trailing Icon" : "Choose Leading Icon" }}
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-icons-selector
      (selectedIcon)="iconChange($event)"
    ></app-icons-selector>
  </div>
</ng-template>
