import { androidColors } from "./interface";
export enum TabNames {
  FEED = "feed",
  WEBVIEW = "web_view",
  CHANNEL = "channel",
  OPENBOT = "open_bot",
  CHAT = "chat",
  CONTACT = "contact",
  CHANNELLIST = "channel_list",
  VIDEOAUDIO = "VIDEOAUDIO",
  SETTINGS = "setting",
  CALENDAR = "event",
  INVITATION = "invitation",
  OPENCHAT = "open_chat",
  OPENGROUP = "open_group",
  MYLIST = "mylist",
  QR = "qr",
  MAP = "map",
  PAGE = "page",
  SEARCH = "search",
  GROUP = "group",
  BOOKINGLIST = "booking_list",
  BOOKINGMARKETPLACE = "booking_marketplace",
  CALL_LOG = "call_log",
  BUNDLE = "bundle",
  VIDEO = "video",
  PACKAGE = "package",
  QR_PAGE = "qr_page",
  VALIDATE_TICKET = "validate_ticket", // fake type that will map to QR_PAGE with options = 1
  REDEEM_TICKET = "redeem_ticket", // fake type that will map to QR_PAGE with options = 2
  MAP_SEARCH = "map_search",
  MENU = "menu",
  STORE ="store",
  POLL ="poll",
  ONLINECHANNEL = "online_channels",
  ONLINEGROUP = "online_groups",
  EMPTY = "empty",
  SPEEDDIALER = "speedailer",
  ORDERLIST = "orderlist",
  BOOKING = "booking",
  QUEUINGLIST = "queuinglist",
  EVENT = "myevent",
  EVENTLIST = "eventlist",
  SPLASH = "splash",
  PALETTE = "palette",
  ONBOARDING = "onboarding"
}

export enum CHAT_TYPE {
  CHANNEL = "Channel",
  GROUP = "Group",
  REPLY = "Reply",
  LEVEL1 = "Level1"
}

export enum MENU_CELL_STYLE {
  STYLE_0 = 0,
  STYLE_1 = 1,
  STYLE_2 = 2,
  STYLE_3 = 3,
  STYLE_4 = 4,
  STYLE_5 = 5,
  STYLE_6 = 6,
  STYLE_7 = 7,
  STYLE_8 = 8,
  STYLE_9 = 9,
}

export enum CELLNAMES {
  HEADLINE = "Headline",
  CARDS = "Cards",
  TEXTFIELD = "Text Fields",
  BUTTONS = "Buttons",
  OUTPUTS = "Outputs",
  RADIOBUTTONS = "Radio Buttons",
  CHECKBOX = "Checkbox",
  TOGGLE = "Toggle Buttons",
  DROPDOWN = "Dropdown Menu",
}

export enum MENU_BUTTON_STYLE {
  STYLE_1 = "1",
  STYLE_2 = "2",
  STYLE_3 = "3",
  STYLE_4 = "4",
  STYLE_0 = "0",
  STYLE_01 = "01",
  STYLE_02 = "02",
  STYLE_03 = "03",
  STYLE_04 = "04",
  STYLE_05 = "05",
  STYLE_06 = "06",
  STYLE_07 = "07",
  STYLE_08 = "08",
  STYLE_1000 = "1000",
  STYLE_2000 = "2000",
  STYLE_3000 = "3000",
  STYLE_4000 = "4000",
  STYLE_5000 = "5000",
  STYLE_6000 = "6000",
  STYLE_7000 = "7000",
  STYLE_8000 = "8000",
  STYLE_9000 = "9000",
  STYLE_01_A = "01_A",
  STYLE_02_A = "02_A",
  STYLE_03_A = "03_A",
  STYLE_04_A = "04_A",
  STYLE_05_A = "05_A",
  STYLE_06_A = "06_A",
  STYLE_07_A = "07_A",
  STYLE_08_A = "08_A",
}

export enum MENU_BUTTON_FORM {
  SEPARATOR = "bar",
  BUTTON = "button",
  INPUT = "inout",
  OUTPUT = "out",
  SUBMIT = "submit",
  CARD = "card",
  SINGLECHOICE = "singlechoice",
  DROPDOWN = "dropdown",
  MULTICHOICE = "multiplechoice",
  TOGGLE = "toggle",
  PRODUCT = "product",
  COLLECTION = "collection",
  PLACEHOLDER = "placeholder",
  PAGESLIDER = "pageslider",
  PAGEGALLERY = "pagegallery",
  PAGESINGLEIMAGE = "pagesingleimage",
  PAGEFEATURE = "pagefeature",
  PAGETEXT = "pagetext",
  PAGEHEADER = "pageheader",
  PAGEDIVIDER = "pagedivider",
  PAGEVIDEO = "pagevideo",
  PAGEQR = "pageqr",
  PAGELOCATION = "pagelocation",
  PAGEPDFVIEWER = "pagepdfviewer",
  PAGESOCIALBAR = "pagesocialbar",
  PAGESLIDERLABEL = "pagesliderlabel",
  SPLASH = "splash",
}

export enum MENU_BUTTON_CODE {
  SEPARATOR_01 = "bar_01",
  BUTTON_01 = "button_01",
  BUTTON_02 = "button_02",
  BUTTON_03 = "button_03",
  BUTTON_04 = "button_04",
  BUTTON_05 = "button_05",
  BUTTON_06 = "button_06",
  BUTTON_07 = "button_07",
  BUTTON_08 = "button_08",
  CARD_01 = "card_01",
  CARD_03 = "card_03",
  CARD_04 = "card_04",
  CARD_05 = "card_05",
  CARD_06 = "card_06",
  CARD_07 = "card_07",
  CARD_08 = "card_08",
  INPUT_01 = "inout_01",
  INPUT_02 = "inout_02",
  INPUT_03 = "inout_03",
  OUTPUT_01 = "out_01",
  OUTPUT_02 = "out_02",
  OUTPUT_03 = "out_03",
  SUBMIT_01 = "submit_01",
  SUBMIT_02 = "submit_02",
  SUBMIT_03 = "submit_03",
  SUBMIT_04 = "submit_04",
  SUBMIT_05 = "submit_05",
  SINGLECHOICE_1000 = "singlechoice_1000",
  SINGLECHOICE_2000 = "singlechoice_2000",
  SINGLECHOICE_3000 = "singlechoice_3000",
  MULTICHOICE_1000 = "multiplechoice_1000",
  MULTICHOICE_2000 = "multiplechoice_2000",
  MULTICHOICE_3000 = "multiplechoice_3000",
  MULTICHOICE_5000 = "multiplechoice_5000",
  MULTICHOICE_6000 = "multiplechoice_6000",
  TOGGLE_1000 = "toggle_1000",
  TOGGLE_2000 = "toggle_2000",
  TOGGLE_3000 = "toggle_3000",
  DROPDOWN_1000 = "dropdown_1000",
  DROPDOWN_2000 = "dropdown_2000",
  DROPDOWN_3000 = "dropdown_3000",
  TOGGLECHOICE = "togglechoice",
  PRODUCT = "product",
  COLLECTION = "collection",
  COLLECTION_01 = "collection_01",
  COLLECTION_02 = "collection_02",
  COLLECTION_03 = "collection_03",
  COLLECTION_04 = "collection_04",
  COLLECTION_05 = "collection_05",
  COLLECTION_06 = "collection_06",
  COLLECTION_07 = "collection_07",
  COLLECTION_08 = "collection_08",
  COLLECTION_09 = "collection_09",
  COLLECTION_10 = "collection_10",
  COLLECTION_11 = "collection_11",
  COLLECTION_12 = "collection_12",
  COLLECTION_13 = "collection_13",
  COLLECTION_14 = "collection_14",
  COLLECTION_15 = "collection_15",
  COLLECTION_16 = "collection_16",

  PAGETEXT = "pagetext",
  PAGESLIDER = "pageslider",
  PAGEGALLERY = "pagegallery",
  PAGESINGLEIMAGE = "pagesingleimage",
  PAGEFEATURE = "pagefeature",
  PLACEHOLDER = "placeholder",
  PAGEHEADER = "pageheader",
  PAGEDIVIDER = "pagedivider",
  PAGEVIDEO = "pagevideo",
  PAGEQR = "pageqr",
  PAGELOCATION = "pagelocation",
  PAGEPDFVIEWER = "pagepdfviewer",
  PAGESOCIALBAR = "pagesocialbar",
  PAGESLIDERLABEL = "pagesliderlabel",
  SPLASH_01 = "splash_01",
  SPLASH_02 = "splash_02",
  SPLASH_03 = "splash_03",
  SPLASH_04 = "splash_04",
  SPLASH_05 = "splash_05",
  SPLASH_06 = "splash_06",
  SPLASH_07 = "splash_07",
}

export enum MENU_CELLS_TYPE {
  BUTTON = "in",
  INPUT = "inout",
  OUTPUT = "out",
  SUBMIT = "submit-all",
  BAR = "bar",
  CHOICE = "choice",
}

export enum MessageTypes {
  PHOTO = "photo",
  TEXT = "text",
  TEXT_FILE = "text_file",
  VIDEO = "video",
  AUDIO = "audio",
  VOICE = "voice",
  LOCATION = "location",
  STICKER = "sticker",
  CONTACT = "contact",
  GIF = "gif",
  DOCUMENT = "document",
  ARTICLE = "article",
  CALENDAR = "calendar",
}

export const offcanvasColors = {
  activeColor: "#50a5f1",
  switchColor: "#ffffff",
  bgColor: "#585E72",
  bgColorSecondary: "#151B2C",
  borderColor: "#3f4454",
  activeTextColor: "#ffffff",
  offTextColor: "#a6b0cf",
  disabledBgColor: "#f8f8fb",
  disabledTextColor: "#343a40",
};

export enum MarkerTypes {
  Group = "group",
  mStore = "mstore",
  Booking = "booking",
  Event = "event",
  Channel = "channel",
  Vapp = "vapp",
}

export enum RowTypes {
  TEXT = "text",
  PAGEGALLERY = "pagegallery",
  PAGESINGLEIMAGE = "pagesingleimage",
  PAGEFEATURE = "pagefeature",
  PAGESLIDER = "pageslider",
  YOUTUBECHANNEL = "youtubeChannel",
}
export const submitType: string[] = [
  "submit-cell",
  "submit-screen",
  "submit-all",
];

export enum BubbleStatus {
  nandbox_bubble = "nandbox",
  whatsapp_bubble = "whatsapp",
  viber_bubble = "viber",
  darktheme_bubble = "darktheme",
  wechat_bubble = "wechat",
  telegram_bubble = "telegram",
}

export enum PagesSocialBar {
  facebook = "facebook",
  twitter = "twitter",
  google = "google",
  instagram = "instagram",
  linkedin = "linkedin",
  pinterest = "pinterest",
  youtube = "youtube",
  slack = "slack",
  reddit = "reddit",
  whatsapp = "whatsapp",
}

export enum RELEASEAPP_STATUS {
  Pending = "Pending",
  Complete = "Completed",
  Download = "Download",
  Successful = "Successful",
  Uploading = "Uploading",
  Uploaded = "Uploaded",
  Publishing = "Publishing",
  Published = "Published",
  Failed = "Failed",
  CreatingApp = "Creating App",
  Created = "App Created",
  Building = "Building",
}

export enum AppStoreStatus {
  PENDING = "pending_invitation",
  VERIFYING = "verifying",
  SETTING = "setting",
  RENEWING = "Renewing ",
  CERTIFICATE = "Certificate ",
  FINISHED = "finished",
  FAILED = "failed",
}

export enum c1 {
  ID = "c1",
  ANDRIOD = "a",
  IOS = "i",
}

export enum c2 {
  ID = "c2",
  FULL = "f",
  HOME = "h",
  SIDE = "s",
  COMPONENT = "c",
}

export enum c3 {
  ID = "c3",
  SINGLE = "s",
  NOTAB = "n",
  MANYTAB = "m",
}

export enum c4 {
  ID = "c4",
  TOP = "t",
  BOTTOM = "b",
}
export enum c5 {
  ID = "c5",
  WIDE = "w",
  STANDARD = "s",
}

export enum c6 {
  ID = "c6",
  TOP = "t",
  BOTTOM = "b",
}

export enum c7 {
  ID = "c7",
  LARGE = "l",
  STANDARD = "s",
}

export enum c8 {
  ID = "c8",
  SIDE = "t",
  NOSIDE = "f",
}

export enum c9 {
  ID = "c9",
  SIDE = "t",
  NOSIDE = "f",
}

export enum c10 {
  ID = "c10",
  WIDE = "w",
  STANDARD = "s",
}

export enum c11{
  ID = "c11",
  M2 = "2",
  M3 = "3",
}

export enum c12 {
  ID = "c12",
  ALL = "a",
  HOME = "h",
  SIDE = "s"
}

export enum c13 {
  ID = "c13",
  M2 = "2",
  M3 = "3",
}

export enum c14 {
  ID = "c14",
  PRIMARY = "p",
  SECONDARY = "s",
  TERTIARY = "t",
}

export enum c15 {
  ID = "c15",
  LOWEST = "0",
  LOW = "1",
  HIGH = "2",
}

export enum c16 {
  ID = "c16",
  LIGHT = "l",
  DARK = "d",
}


export enum MobileView {
  a_full = "a_full",
  a_main_tab = "a_main_tab",
  a_main_notab = "a_main_notab",
  a_main_many_top_wide = "a_main_many_top_wide",
  a_main_many_bottom_wide = "a_main_many_bottom_wide",
  a_main_many_top_std = "a_main_many_top_std",
  a_main_many_bottom_std = "a_main_many_bottom_std",
  a_component = "a_component",
  i_full = "i_full",
  i_main_tab = "i_main_tab",
  i_main_notab = "a_main_notab",
  i_main_noside = "i_main_noside",
  i_main_top_large = "i_main_top_large",
  i_main_top_small = "i_main_top_small",
  i_main_bottom_large = "i_main_bottom_large",
  i_main_bottom_small = "i_main_bottom_small",
  i_side = "i_side",
  i_component = "i_component",
}

export enum SPLASH_STYLE {
  STYLE_01 = "01",
  STYLE_02 = "02",
  STYLE_03 = "03",
  STYLE_04 = "04",
  STYLE_05 = "05",
  STYLE_06 = "06",
  STYLE_07 = "07",
}

//////////////

export const errorMap = {
  12000: {
    title: "Failed",
    html: "Profile Not Saved",
    icon: "error",
  },
  12003: {
    title: "Failed",
    html: "Domain Not Saved",
    icon: "error",
  },
  12009: {
    title: "Failed",
    html: "Order Not Created",
    icon: "error",
  },
  12010: {
    title: "Failed",
    html: "Order Not Approved",
    icon: "error",
  },
  12011: {
    title: "Failed",
    html: "Order Not Approved",
    icon: "error",
  },
  12026: {
    title: "Failed",
    html: "Domain Already Exists",
    icon: "error",
  },
  12027: {
    title: "Failed",
    html: "Domain Edit Not Saved",
    icon: "error",
  },
  100: {
    title: "Failed",
    html: "The file you are trying to upload is too large",
    icon: "error",
  },
  101: {
    title: "Failed",
    html: "The file you are trying to upload is Empty",
    icon: "error",
  },
  102: {
    title: "Failed",
    html: "The file you are trying to upload is not Supported",
    icon: "error",
  },
};

export const plans_map = [
  {
    period: "Week",
    period_server: "WEEK",
    period_number: 1,
  },
  {
    period: "Month",
    period_server: "MONTH",
    period_number: 1,
  },
  {
    period: "Month",
    period_server: "MONTH",
    period_number: 3,
  },
  {
    period: "Month",
    period_server: "MONTH",
    period_number: 6,
  },
  {
    period: "Year",
    period_server: "YEAR",
    period_number: 1,
  },
];

export const user_status_map = {
  A: "GENERALS.ACTIVE",
  N: "GENERALS.NOTACTIVE",
  B: "GENERALS.BLOCKED",
};

export const user_status_color_map = {
  A: "success-badge",
  N: "danger-badge",
  B: "danger-badge"
};

export const bot_status_color_map = {
  0: "danger-badge",
  1: "success-badge",
};

export const bot_status_map = {
  0: "GENERALS.PRIVATE",
  1: "GENERALS.PUBLIC",
};

// export const bot_status_map = {
//   A: "GENERALS.ACTIVE",
//   N: "GENERALS.NOTACTIVE",
//   B: "GENERALS.BLOCKED",
// };

export const published_status_map = {
  0: "GENERALS.NOTUBLISHED",
  1: "GENERALS.PUBLISHED",
};

export const payment_enabled_map = {
  0: "Free",
  1: "Paid",
};

export const payment_enabled_color_map = {
  0: "success-badge",
  1: "warning-badge"
};

export const service_profile_map = [
  { name: "Subscription", id: 2, key: "SUBSCRIPTION" },
  { name: "Booking", id: 3, key: "BOOKING" },
  { name: "Inventory", id: 4, key: "INVENTORY" },
  { name: "Tag", id: 7, key: "TAG" },
  { name: "Negativeinventory", id: 9, key: "NEGATIVEINVENTORY" },
  { name: "Bot_Subscription", id: 14, key: "BOT_SUBSCRIPTION" },
];

export const user_search_placeholder_map = {
  name: "GENERALS.NAMEPLACEHOLDER",
  member_id: "USERS.USERIDPLACEHOLDER",
  msisdn: "USERS.LOGINIDPLACEHOLDER",
  channel: "CHANNELS.CHANNELIDPLACEHOLDER",
  group: "GROUPS.GROUPIDPLACEHOLDER",
  booking: "BOOKINGS.BOOKINGIDPLACEHOLDER",
  calendar: "CALENDARS.CALENDARIDPLACEHOLDER",
  event: "EVENTS.EVENTIDPLACEHOLDER",
  coupon_id: "COUPONS.COUPONIDPLACEHOLDER",
  campaign_id: "CAMPAIGNS.CAMPAIGNIDPLACEHOLDER",
  menu_id: "POLLS.POLLIDPLACEHOLDER",
  product: "PRODUCTS.PRODUCTIDPLACEHOLDER",
  collection: "COLLECTIONS.COLLECTIONIDPLACEHOLDER",
};

export const user_role_map = {
  "0": "USERS.MEMBER",
  "1": "USERS.OWNER",
  "2": "USERS.SUPERADMIN",
  "3": "USERS.MODERATOR",
  "4": "USERS.ADVERTISER",
  "5": "USERS.ANALYST",
  "6": "USERS.EDITOR",
  "7": "USERS.ADMIN",
};

export const user_roles_map = [
  { id: 0, name: "Member" },
  { id: 2, name: "Super Admin" },
  { id: 7, name: "Admin" },
  { id: 3, name: "Moderator" },
  { id: 4, name: "Advertiser" },
  { id: 5, name: "Analyst" },
  { id: 6, name: "Editor" },
];

export const user_role_color_map = {
  "0": "secondary-container-badge",
  "1": "success-badge",
  "2": "primary-container-badge",
  "3": "primary-container-badge",
  "4": "primary-container-badge",
  "5": "primary-container-badge",
  "6": "primary-container-badge",
  "7": "primary-container-badge",
};

export const month_names_map = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const chat_categories_map = [
  {
    key: "S",
    value: "CHANNELS.SOCIAL",
  },
  {
    key: "E",
    value: "CHANNELS.ENTERTAINMENT",
  },
  {
    key: "H",
    value: "CHANNELS.HEALTHANDFITNESS",
  },
  {
    key: "L",
    value: "CHANNELS.LIFESTYLE",
  },
  {
    key: "M",
    value: "CHANNELS.MEDIA",
  },
  {
    key: "N",
    value: "CHANNELS.NEWSANDMAGAZINES",
  },
  {
    key: "T",
    value: "CHANNELS.SPORTS",
  },
  {
    key: "D",
    value: "CHANNELS.EDUCATION",
  },
  {
    key: "C",
    value: "CHANNELS.CORPORATE",
  },
  {
    key: "G",
    value: "CHANNELS.GOVERNMENT",
  },
  {
    key: "P",
    value: "CHANNELS.PROFESSIONAL",
  },
  {
    key: "O",
    value: "CHANNELS.STORESANDRETAIL",
  },
  {
    key: "R",
    value: "CHANNELS.RELIGION",
  },
];

export const product_types = [
  {
    key: null,
    value: "Product",
  },
  {
    key: "digital",
    value: "Digital",
  },
  {
    key: "event",
    value: "Event",
  },
  {
    key: "booking",
    value: "Booking",
  },
];

export const message_media_status_map = {
  INIT: "INIT",
  DOWNLOADING: "DOWNLOADING",
  DOWNLOAD_FAILED: "DOWNLOAD_FAILED",
  UPLOADING: "UPLOADING",
  UPLOAD_FAILED: "UPLOAD_FAILED",
  DOWNLOADED: "DOWNLOADED",
  UPLOADED: "UPLOADED",
};

export const message_status_map = {
  DELETED: "deleted",
  UPDATED: "updated",
};

export const device_os_map = {
  ANDROID: "bx bxl-android text-success",
  IOS: "bx bxl-apple text-muted",
  0: "bx bxl-android text-success",
  1: "bx bxl-apple text-muted",
};

export const coupon_share_icon_map = {
  0: "mdi mdi-share-off",
  1: "mdi mdi-share",
};

export const splash_config_map = {
  [SPLASH_STYLE.STYLE_01]: {
    tab_part: {
      style: "01",
      title: "",
      button_title: "ACCEPT AND CONTINUE",
      button_bgcolor: "#ffb02e",
      button_text_color: "#000000",
      tnc_text_color: "#000000",
      tnc_link_color: "#ffb02e",
      tnc_bgcolor: "#ffffff",
    },
    button_part: {
      title: "", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "",
      images: {
        "01": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxxhdpi.jpg?temp_url_sig=015541611a83b3cb6efff805b350f718b02f47f1&temp_url_expires=2029425688",
          width: 540,
          hight: 897,
          aspect: "360x598",
          type: null,
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_hdpi.jpg?temp_url_sig=38c218e7554e592beab71fa7b2488f57e749efb8&temp_url_expires=2029425680",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_1x.jpg?temp_url_sig=59234fb47b3127fb420078a6ab5f23d2205a61aa&temp_url_expires=2029425690",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_2x.jpg?temp_url_sig=d614c6cdc80b76ce06815ab7e251d4b23389484a&temp_url_expires=2029425693",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_3x.jpg?temp_url_sig=a23c901a689c967870579ae73422569d1372e814&temp_url_expires=2029425696",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_mdpi.jpg?temp_url_sig=3c2090d18487060a395b700a75e378593c834061&temp_url_expires=2029425682",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xhdpi.jpg?temp_url_sig=852a0c4e46da7e504f64fbbc4c08ccdcf9cd13c4&temp_url_expires=2029425684",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxhdpi.jpg?temp_url_sig=3575b13ff913aa291bf670a294af18ccad5e2270&temp_url_expires=2029425686",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_Bac684PM6K_s_xxxhdpi.jpg?temp_url_sig=015541611a83b3cb6efff805b350f718b02f47f1&temp_url_expires=2029425688",
          },
        },
      },
      // image:"https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684293000559_6323a65cbe6461e1f8aff1521f8e593638a5565b93fcd239eb0017cab677afd6.jpg?temp_url_sig=d73ac5a2aba2cbf466059477ab838322e33814ac&temp_url_expires=2028055587",        //button.button_img_url
      bg_image: "", // this.button.button_bgimage;
      start_color: "#ffffff", //this.button.button_bgstart;
      end_color: "#ffffff", // this.button.button_bgend;
      info: { width: 360, hight: 598, ratio: "360x598", imageType: null },
    },
  },
  [SPLASH_STYLE.STYLE_02]: {
    tab_part: {
      style: "02",
      title: "",
      button_title: "ACCEPT AND CONTINUE",
      button_bgcolor: "#000000",
      button_text_color: "#ffffff",
      tnc_text_color: "#ffffff",
      tnc_link_color: "#000000",
      tnc_bgcolor: null,
    },
    button_part: {
      title:
        "<h3><strong><em>Flowers Store</em></strong></h3> <p>30 years of market expertise and a full-fledged team.</p>", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "",
      images: {
        "02": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxxhdpi.png?temp_url_sig=67d62b05aeea186a84aef6a36b60a17c276dca96&temp_url_expires=2029422073",
          width: 260,
          hight: 260,
          aspect: null,
          type: "image/png,",
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_hdpi.png?temp_url_sig=4e14bda3c06e8f9dce9db0e71ce038d14efc1369&temp_url_expires=2029422062",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_1x.png?temp_url_sig=44f44c731fbd7d7179216e137f8609d35e95b6c1&temp_url_expires=2029422079",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_2x.png?temp_url_sig=896b75c4afc29a856ecdb6b1a077c49eb55756a3&temp_url_expires=2029422080",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_3x.png?temp_url_sig=f622588b4a841b764152f5165538fae4765d9246&temp_url_expires=2029422083",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_mdpi.png?temp_url_sig=ee29be9fee7b770b800a968329b363b2c0eddd65&temp_url_expires=2029422064",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xhdpi.png?temp_url_sig=493db2153fee482d0b9757b432744f5396efc1ba&temp_url_expires=2029422066",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxhdpi.png?temp_url_sig=ea1570d96091bb691950387ba74bb1805ae6d85c&temp_url_expires=2029422067",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_xxxhdpi.png?temp_url_sig=67d62b05aeea186a84aef6a36b60a17c276dca96&temp_url_expires=2029422073",
          },
        },
      },
      bg_image: "", // this.button.button_bgimage;
      start_color: "#fad961", //this.button.button_bgstart;
      end_color: "#e47a3e", // this.button.button_bgend;
    },
  },
  [SPLASH_STYLE.STYLE_03]: {
    tab_part: {
      style: "03",
      title:
        "<h3><strong><em>Flowers Store</em></strong></h3> <p>30 years of market expertise and a full-fledged team.</p>",
      button_title: "ACCEPT AND CONTINUE",
      button_bgcolor: "#ffb02e",
      button_text_color: "#000000",
      tnc_text_color: "#ffffff",
      tnc_link_color: "#ffb02e",
      tnc_bgcolor: null,
    },
    button_part: {
      title: "", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "",
      images: { "03": { url: "", width: 540, hight: 1107, aspect: "360x738" } },
      bg_image:
        "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxxhdpi.jpg?temp_url_sig=0d0cfdb8136554d2c9b711c7a5413865ba1ebfe0&temp_url_expires=2029430455", // this.button.button_bgimage;
      bg_imageSet: {
        hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_hdpi.jpg?temp_url_sig=e3a0940c8f9a5f04034dcad3d7aaa7138b549efa&temp_url_expires=2029430445",
        ios1x:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_1x.jpg?temp_url_sig=70c0fb410bd5add69d256298ce3b8d8d3d443ec5&temp_url_expires=2029430457",
        ios2x:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_2x.jpg?temp_url_sig=aee737323f64ad9975871ccc58f4491ce027869d&temp_url_expires=2029430460",
        ios3x:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_3x.jpg?temp_url_sig=9ad573b3368f69bb5c4406abf3ae9d4ca26c7586&temp_url_expires=2029430465",
        mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_mdpi.jpg?temp_url_sig=f1c0d5a582713da9a932615f39b209b3e4231a07&temp_url_expires=2029430447",
        xhdpi:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xhdpi.jpg?temp_url_sig=f18a66138829a6747ef6e7384f330941f018aed1&temp_url_expires=2029430450",
        xxhdpi:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxhdpi.jpg?temp_url_sig=c73b97fc0be16e139ea03a6c6d9c9d376bd3c514&temp_url_expires=2029430452",
        xxxhdpi:
          "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_EkvKy0qtXz_s_xxxhdpi.jpg?temp_url_sig=0d0cfdb8136554d2c9b711c7a5413865ba1ebfe0&temp_url_expires=2029430455",
      },
      start_color: "", //this.button.button_bgstart;
      end_color: "", // this.button.button_bgend;
    },
  },
  [SPLASH_STYLE.STYLE_04]: {
    tab_part: {
      style: "04",
      title: null,
      button_title: "ACCEPT AND CONTINUE",
      button_bgcolor: "#3d796f",
      button_text_color: "#ffffff",
      tnc_text_color: "#9fa0a5",
      tnc_link_color: "#3d796f",
      tnc_bgcolor: "#ffffff",
    },
    button_part: {
      title:
        "<h4><strong>Free Messaging</strong></h4> <p>Chats with seamless media sharing options.</p>", //this.button.button_label
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/oval260.png",
      images: {
        "04": {
          url: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxxhdpi.png?temp_url_sig=11ab0a15e94c652b6b714a159d4a77910645a80f&temp_url_expires=2029422498",
          width: 260,
          hight: 260,
          aspect: null,
          type: "image/png,",
          imageSet: {
            hdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_hdpi.png?temp_url_sig=2351bc984524b86cfff487b2f6736a569bdf92f9&temp_url_expires=2029422489",
            ios1x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_1x.png?temp_url_sig=7b77e200bb6e54126ad068276a53e73d518f5edb&temp_url_expires=2029422500",
            ios2x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_2x.png?temp_url_sig=cef5d78dffdef9bdc2ebbb70d126655aa3aede57&temp_url_expires=2029422501",
            ios3x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_3x.png?temp_url_sig=500fb231f1d6cedf6df0e37739aa59f6a78df96b&temp_url_expires=2029422504",
            mdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_mdpi.png?temp_url_sig=6cdbeaed82abb0c87ad590421bd21b6b7f5e1cc0&temp_url_expires=2029422492",
            xhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xhdpi.png?temp_url_sig=a7e1b5778de40c498ab11f8274111c5f13f8ec8e&temp_url_expires=2029422493",
            xxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxhdpi.png?temp_url_sig=c091a289be8e5c94cb8a271f469adc0a5bde48a3&temp_url_expires=2029422497",
            xxxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0FbcTPAptb_s_xxxhdpi.png?temp_url_sig=11ab0a15e94c652b6b714a159d4a77910645a80f&temp_url_expires=2029422498",
          },
        },
      },
      // image:"./assets/images/small/imageSplash4.png",
      // 100x 202 aspect
      bg_image: "", // this.button.button_bgimage;
      start_color: "#3d796f", //this.button.button_bgstart;
      end_color: "#3d796f", // this.button.button_bgend;
    },
  },

  [SPLASH_STYLE.STYLE_05]: {
    tab_part: {
      style: "05",
      title: null,
      button_title: "ACCEPT AND CONTINUE",
      button_bgcolor: "#5e6dba",
      button_text_color: "#ffffff",
      tnc_text_color: "#9fa0a5",
      tnc_link_color: "#5e6dba",
      tnc_bgcolor: "#ffffff",
    },
    button_part: {
      title:
        "<h4><strong><em>Free Messaging</em></strong></h4> <p>Chats with seamless media sharing options.</p>",
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/oval260.png",
      images: {
        "05": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxxhdpi.png?temp_url_sig=412969a90811990b3a4088614db1c6c33f754549&temp_url_expires=2029422714",
          width: 260,
          hight: 260,
          aspect: null,
          type: "image/png,",
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_hdpi.png?temp_url_sig=a6e2a8c50d83181753bce18eb7a297651421192e&temp_url_expires=2029422709",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_1x.png?temp_url_sig=1fcb01c466ad3648d982fb37f66909f2d9c57d82&temp_url_expires=2029422716",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_2x.png?temp_url_sig=deab9c10f1bc09780e20c7816f3939bf5173cb4c&temp_url_expires=2029422717",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_3x.png?temp_url_sig=3a71eee768113e645023d0a875a039a39cfb51f3&temp_url_expires=2029422719",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_mdpi.png?temp_url_sig=d937e3636c76d61052c76a6d781d08db2d442e4e&temp_url_expires=2029422710",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xhdpi.png?temp_url_sig=0858505b0fcf60f346fe6f2a8e87b3051a1f0057&temp_url_expires=2029422711",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxhdpi.png?temp_url_sig=3acaa76cd1b303763e60eb951b7d38157e8a73fa&temp_url_expires=2029422712",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_1nuyLYRclD_s_xxxhdpi.png?temp_url_sig=412969a90811990b3a4088614db1c6c33f754549&temp_url_expires=2029422714",
          },
        },
      },
      // image:"./assets/images/small/imageSplash5.png",
      bg_image: "",
      start_color: "#5e6dba", //this.button.button_bgstart;
      end_color: "#5e6dba", // this.button.button_bgend;
    },
  },

  [SPLASH_STYLE.STYLE_06]: {
    tab_part: {
      style: "06",
      title: null,
      button_title: "ACCEPT AND CONTINUE",
      button_bgcolor: "#06c755",
      button_text_color: "#ffffff",
      tnc_text_color: "#9fa0a5",
      tnc_link_color: "#06c755",
      tnc_bgcolor: null,
    },
    button_part: {
      title:
        "<h4><strong><em>Interactive Channels</em></strong></h4> <p>Enjoy unlimited Channels</p>",
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/oval200.png",
      images: {
        "06": {
          url: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxxhdpi.png?temp_url_sig=85a2393c57aa14d006f05fcd8daa91af2d8a07de&temp_url_expires=2029423833",
          width: 260,
          hight: 260,
          aspect: null,

          type: "image/png,",
          imageSet: {
            hdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_hdpi.png?temp_url_sig=70af0e76b1f7b501d22de7a8f905c1b40bce6371&temp_url_expires=2029423824",
            ios1x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_1x.png?temp_url_sig=5a9eb3c6fac1956f5b968af738c6e87c704ddaa8&temp_url_expires=2029423834",
            ios2x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_2x.png?temp_url_sig=88fd7c326d0ea46eca4d63bae8a0f88169b312ca&temp_url_expires=2029423836",
            ios3x:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_3x.png?temp_url_sig=c3dda49f2092d6da5a05c3f817f16adb61a5c9fc&temp_url_expires=2029423837",
            mdpi: "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_mdpi.png?temp_url_sig=e60611011847fbf8bb95d4390f13c6f3bb9eb28c&temp_url_expires=2029423826",
            xhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xhdpi.png?temp_url_sig=d92cd07d7b4a23b687d6f4b2f42412894581b651&temp_url_expires=2029423829",
            xxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxhdpi.png?temp_url_sig=24d08bc8033daaba80ff42db30623e858ef7cf81&temp_url_expires=2029423831",
            xxxhdpi:
              "https://m2.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_97WsUq1pdC_s_xxxhdpi.png?temp_url_sig=85a2393c57aa14d006f05fcd8daa91af2d8a07de&temp_url_expires=2029423833",
          },
        },
      },
      // image:"./assets/images/small/imageSplash6.png",
      // 100x 202 aspect
      bg_image: "", // this.button.button_bgimage;
      start_color: "#ffffff", //this.button.button_bgstart;
      end_color: "#ffffff", // this.button.button_bgend;
    },
  },
  [SPLASH_STYLE.STYLE_07]: {
    tab_part: {
      style: "07",
      title: "",
      button_title: "ACCEPT AND CONTINUE",
      button_bgcolor: "#3b6ee2",
      button_text_color: "#ffffff",
      tnc_text_color: "#9fa0a5",
      tnc_link_color: "#3b6ee2",
      tnc_bgcolor: null,
    },
    button_part: {
      title:
        "<h4><strong><em>Bigger Groups</em></strong></h4> <p>Chat with up to 50,000 group memebers.</p>",
      desc: "", //this.button.button_sublabel;
      ovalImage: "./assets/images/small/oval180.png",
      images: {
        "07": {
          url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxxhdpi.png?temp_url_sig=f1615d81dc44c10094ee6cc84b8f6ca157c0ec5c&temp_url_expires=2029424078",
          width: 260,
          hight: 260,
          aspect: null,

          type: "image/png,",
          imageSet: {
            hdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_hdpi.png?temp_url_sig=b70f25aa2ae025f5703c8e70d3edb1b9cbb4fd21&temp_url_expires=2029424073",
            ios1x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_1x.png?temp_url_sig=1142c67ac4f61913526e218599f90b0bddc5374b&temp_url_expires=2029424080",
            ios2x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_2x.png?temp_url_sig=f4500e300b7e644e1009b670aefd799088f7112b&temp_url_expires=2029424082",
            ios3x:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_3x.png?temp_url_sig=caab83be3afd33c3064162b9ad3abab548a5deb7&temp_url_expires=2029424085",
            mdpi: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_mdpi.png?temp_url_sig=ee4b1fd3088fb15b591907339979da1ab42a990b&temp_url_expires=2029424074",
            xhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xhdpi.png?temp_url_sig=809ac57010906b7a7276d601bc547decb7a4ab32&temp_url_expires=2029424075",
            xxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxhdpi.png?temp_url_sig=f1fc71bf16f958486c0e7aca0a7651a0968868c2&temp_url_expires=2029424077",
            xxxhdpi:
              "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_MYVfAJl6lq_s_xxxhdpi.png?temp_url_sig=f1615d81dc44c10094ee6cc84b8f6ca157c0ec5c&temp_url_expires=2029424078",
          },
        },
      },
      // image:"./assets/images/small/imageSplash7.png",
      // 100x 202 aspect
      bg_image: "",
      start_color: "#ffffff", //this.button.button_bgstart;
      end_color: "#ffffff", // this.button.button_bgend;
    },
  },
};

export const common_button_migration_map = {
  // we need to take the button code from "button_form + style"  Hazem A.
  [MENU_BUTTON_FORM.SEPARATOR]: {
    button_code: MENU_BUTTON_CODE.SEPARATOR_01,
  },

  [MENU_BUTTON_FORM.BUTTON]: {
    button_code: MENU_BUTTON_CODE.CARD_05,
  },


  [MENU_BUTTON_FORM.INPUT]: {
    button_code: MENU_BUTTON_CODE.INPUT_01,
  },
  [MENU_BUTTON_FORM.OUTPUT]: {
    button_code: MENU_BUTTON_CODE.OUTPUT_01,
  },

  [MENU_BUTTON_FORM.SUBMIT]: {
    button_code: MENU_BUTTON_CODE.BUTTON_01,
  },

  [MENU_BUTTON_FORM.SINGLECHOICE]: {
    button_code: MENU_BUTTON_CODE.SINGLECHOICE_1000,
  },

  [MENU_BUTTON_FORM.MULTICHOICE]: {
    button_code: MENU_BUTTON_CODE.MULTICHOICE_1000,
  },

  [MENU_BUTTON_FORM.DROPDOWN]: {
    button_code: MENU_BUTTON_CODE.DROPDOWN_1000,
  },

  [MENU_BUTTON_FORM.TOGGLE]: {
    button_code: MENU_BUTTON_CODE.TOGGLE_1000,
  },

  [MENU_BUTTON_FORM.PRODUCT]: {
    button_code: MENU_BUTTON_CODE.PRODUCT,
  },

  [MENU_BUTTON_FORM.COLLECTION]: {
    button_code: MENU_BUTTON_CODE.COLLECTION,
  },

  [MENU_BUTTON_FORM.PAGESLIDER]: {
    button_code: MENU_BUTTON_CODE.PAGESLIDER,
  },
  [MENU_BUTTON_FORM.PAGESLIDERLABEL]: {
    button_code: MENU_BUTTON_CODE.PAGESLIDERLABEL,
  },
  [MENU_BUTTON_FORM.PAGEGALLERY]: {
    button_code: MENU_BUTTON_CODE.PAGEGALLERY,
  },

  [MENU_BUTTON_FORM.PAGEFEATURE]: {
    button_code: MENU_BUTTON_CODE.PAGEFEATURE,
  },
  [MENU_BUTTON_FORM.PLACEHOLDER]: {
    button_code: MENU_BUTTON_CODE.PLACEHOLDER,
  },
};

export const   collection_composed_images = {
  ["collection-7-8"]:  {image:  "./assets/images/temp/collection-7-8.png",   },
  ["collection-9-10"]: {image:  "./assets/images/temp/collection-9-10.png",  },
  ["collection-11-12"]:{image:  "./assets/images/temp/collection-11-12.png", },
  ["collection-13-16"]:{image:  "./assets/images/temp/collection-13-16.png", },
}
export const   menu_button_map = {
  [MENU_BUTTON_CODE.SEPARATOR_01]: {
    button_code: "bar_01",
    button_form: "bar",
    button_style: "1004",
    button_style_ios: "1004",
    button_type: "bar",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_divider: 1,
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    info: { min_grid: 15, image: "./assets/images/workflow/headline.webp" },
  },
  [MENU_BUTTON_CODE.CARD_01]: {
    button_code: "card_01",
    button_form: "card",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},

    // button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/card_01.jpg",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.CARD_03]: {
    button_code: "card_03",
    button_form: "card",
    button_style: "0303",
    button_style_ios: "0303",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2022/12/rectangle-sky.webp",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},

    info: {
      onClick: true,
      image: "./assets/images/workflow/card_03.jpg",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.CARD_04]: {
    button_code: "card_04",
    button_form: "card",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    info: {
      onClick: true,
      image: "./assets/images/workflow/card_04.webp",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.CARD_05]: {
    button_code: "card_05",
    button_form: "card",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2022/12/button-bg.webp",
    info: {
      onClick: true,
      image: "./assets/images/workflow/card_05.jpg",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.CARD_06]: {
    button_code: "card_06",
    button_form: "card",
    button_style: "0603",
    button_style_ios: "0603",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_img_url: "https://nandbox.com/wp-content/uploads/2022/12/yoga.webp",
    button_img_url_ios: "https://nandbox.com/wp-content/uploads/2022/12/yoga.webp",
    button_keyboard: "text",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},

    // button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/card_06.jpg",
      min_grid: 20, // 60/20= 3cols
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },


  [MENU_BUTTON_CODE.CARD_07]: {
    button_code: "card_07",
    button_form: "card",
    button_style: "07",
    button_style_ios: "0703",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2022/12/yoga-2.webp",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2022/12/yoga-2.webp",
      button_text_fontsize: {headline:"md", subhead:"md"},
      button_text_fontsize_ios: {headline:"md", subhead:"md"},
      button_text_align:{headline:"center", subhead:"center"},
      button_text_align_ios:{headline:"center", subhead:"center"},

    // button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/card_07.jpg",
      min_grid: 60, // 60/60= 1col
      hight: 100,
      ratio: {
        "1": "360x100",
        "2": "180x100",
        "3": "120x100",
        "4": "90x100",
        "5": "72x100",
        "6": "60x100",
      },
    },
  },

  [MENU_BUTTON_CODE.CARD_08]: {
    button_code: "card_08",
    button_form: "card",
    button_style: "0803",
    button_style_ios: "0803",
    button_type: "card",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_keyboard: "text",
    button_img_url:
      "https://nandbox.com/wp-content/uploads/2022/12/fitness.webp",
    button_img_url_ios:
      "https://nandbox.com/wp-content/uploads/2022/12/fitness.webp",
      button_text_fontsize: {headline:"md", subhead:"md"},
      button_text_fontsize_ios: {headline:"md", subhead:"md"},
      button_text_align:{headline:"center", subhead:"center"},
      button_text_align_ios:{headline:"center", subhead:"center"},

    // button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/card_08.jpg",
      min_grid: 60, // 60/60= 1col
      hight: 80,
      ratio: {
        "1": "360x80",
        "2": "180x80",
        "3": "120x80",
        "4": "90x80",
        "5": "72x80",
        "6": "60x80",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_01]: {
    button_code: "collection_01",
    button_form: "collection",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-7.png",
    button_img_url_ios:
      "./assets/images/temp/collection-7.png",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-7.png",
      min_grid: 30,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_02]: {
    button_code: "collection_02",
    button_form: "collection",
    button_style: "0203",
    button_style_ios: "0203",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"sm"},
    button_text_fontsize_ios: {headline:"sm"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-2.png",
    button_img_url_ios:
      "./assets/images/temp/collection-2.png",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-2.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_04]: {
    button_code: "collection_04",
    button_form: "collection",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "collection",
    button_img_url:
      "./assets/images/temp/collection-4.jpg",
    button_img_url_ios:
      "./assets/images/temp/collection-4.jpg",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-4.jpg",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_05]: {
    button_code: "collection_05",
    button_form: "collection",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"left"},
    button_text_align_ios:{headline:"left",},
    button_img_url:
      "./assets/images/temp/collection-5.png",
    button_img_url_ios:
      "./assets/images/temp/collection-5.png",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-5.png",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_07]: {
    // same as style 0503
    button_code: "collection_07",
    button_form: "collection",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-7.png",
    button_img_url_ios:
      "./assets/images/temp/collection-7.png",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-7.png",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_08]: {
    // same as style 0503
    button_code: "collection_08",
    button_form: "collection",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-8.png",
    button_img_url_ios:
      "./assets/images/temp/collection-8.png",
    info: {
      onClick: true,
      image: "./assets/images/temp/collection-8.png",
      min_grid: 20,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_09]: {
    button_code: "collection_09",
    button_form: "collection",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "collection",
    button_img_url:
      "./assets/images/temp/collection-9.png",
    button_img_url_ios:
      "./assets/images/temp/collection-9.png",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-9.png",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_10]: {
    button_code: "collection_10",
    button_form: "collection",
    button_style: "0403",
    button_style_ios: "0403",
    button_type: "collection",
    button_img_url:
      "./assets/images/temp/collection-10.png",
    button_img_url_ios:
      "./assets/images/temp/collection-10.png",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-10.png",
      min_grid: 60,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_11]: {
    // same as style 0503
    button_code: "collection_11",
    button_form: "collection",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-11.png",
    button_img_url_ios:
      "./assets/images/temp/collection-11.png",
    info: {
      onClick: true,
      image:  "./assets/images/temp/collection-11.png",
      min_grid: 30,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.COLLECTION_12]: {
    // same as style 0503
    button_code: "collection_12",
    button_form: "collection",
    button_style: "0503",
    button_style_ios: "0503",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"md"},
    button_text_fontsize_ios: {headline:"md"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-12.png",
    button_img_url_ios:
      "./assets/images/temp/collection-12.png",
    info: {
      onClick: true,
      image: "./assets/images/temp/collection-12.png",
      min_grid: 30,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_13]: {
    // same as style 0503
    button_code: "collection_13",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"sm"},
    button_text_fontsize_ios: {headline:"sm"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-13.png",
    button_img_url_ios:
      "./assets/images/temp/collection-13.png",
    info: {
      onClick: true,
      image: "./assets/images/temp/collection-13.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_14]: {
    // same as style 0503
    button_code: "collection_14",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"sm"},
    button_text_fontsize_ios: {headline:"sm"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-14.png",
    button_img_url_ios:
      "./assets/images/temp/collection-14.png",
    info: {
      onClick: true,
      image: "./assets/images/temp/collection-14.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },


  [MENU_BUTTON_CODE.COLLECTION_15]: {
    // same as style 0503
    button_code: "collection_15",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"sm"},
    button_text_fontsize_ios: {headline:"sm"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-15.png",
    button_img_url_ios:
      "./assets/images/temp/collection-15.png",
    info: {
      onClick: true,
      image: "./assets/images/temp/collection-15.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.COLLECTION_16]: {
    // same as style 0503
    button_code: "collection_16",
    button_form: "collection",
    button_style: "0204",
    button_style_ios: "0204",
    button_type: "collection",
    button_headline: "Headline",
    button_text_fontsize: {headline:"sm"},
    button_text_fontsize_ios: {headline:"sm"},
    button_text_align:{headline:"center"},
    button_text_align_ios:{headline:"center",},
    button_img_url:
      "./assets/images/temp/collection-16.png",
    button_img_url_ios:
      "./assets/images/temp/collection-16.png",
    info: {
      onClick: true,
      image: "./assets/images/temp/collection-16.png",
      min_grid: 15,
      hight: 120,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },


  [MENU_BUTTON_CODE.INPUT_01]: {
    button_code: "inout_01",
    button_form: "inout",
    button_style: "01",
    button_style_ios: "01",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_input: "Input Text",
    button_placeholder: null,

    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/outlined_input.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.INPUT_03]: {
    button_code: "inout_03",
    button_form: "inout",
    button_style: "03",
    button_style_ios: "03",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_input: "Input Text",
    button_placeholder: null,
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/filled_input.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.INPUT_02]: {
    button_code: "inout_02",
    button_form: "inout",
    button_style: "02",
    button_style_ios: "02",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_input: "Input Text",
    button_placeholder: null,
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/elevated_input.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.DROPDOWN_1000]: {
    button_code: "dropdown_1000",
    button_form: "dropdown",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_label: "Title",
    button_keyboard: "text",
    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],
    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/outlined_drop.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.DROPDOWN_2000]: {
    button_code: "dropdown_2000",
    button_form: "dropdown",
    button_style: "2000",
    button_style_ios: "2000",
    button_option_color: null,
    button_url: null,
    button_type: "inout",
    button_keyboard: "text",
    button_bgcolor: "#EFEFEF",
    button_label_color: "#666666",
    button_label_color_ios: "#000000",
    button_textcolor: "#000000",
    button_label: "Title",
    button_sublabel: "Select Option",
    button_sublabel_color: "#666666",
    button_sublabel_color_ios: "#c4c4c6",

    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],

    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/elevated_drop.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.DROPDOWN_3000]: {
    button_code: "dropdown_3000",
    button_form: "dropdown",
    button_style: "3000",
    button_style_ios: "3000",
    button_type: "inout",
    button_keyboard: "text",
    button_label_color: "#666666",
    button_label_color_ios: "#000000",
    button_textcolor: "#000000",
    button_label: "Title",
    button_sublabel: "Select Option",
    button_sublabel_color: "#666666",
    button_sublabel_color_ios: "#c4c4c6",
    button_option: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
      {
        id: "o_3",
        label: "Option 3",
        value: "option-3",
      },
    ],

    button_value: [
      {
        id: "o_0",
        label: "Select Option",
        value: "select-option",
      },
    ],

    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/filled_drop.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },

  [MENU_BUTTON_CODE.OUTPUT_01]: {
    button_code: "out_01",
    button_form: "out",
    button_style: "0103",
    button_style_ios: "0103",
    button_type: "inout",
    button_keyboard: "text",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    button_newpage: true,
    info: {
      onClick: false,
      image: "./assets/images/workflow/output_01.webp",
      min_grid: 20,
      hight: 100,
      ratio: {
        "1": "360x100",
        "2": "180x100",
        "3": "120x100",
        "4": "90x100",
        "5": "72x100",
        "6": "60x100",
      },
    },
  },
  [MENU_BUTTON_CODE.OUTPUT_02]: {
    button_code: "out_02",
    button_form: "out",
    button_style: "0203",
    button_style_ios: "0203",
    button_type: "out",
    button_keyboard: "text",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_text_fontsize: {headline:"md", subhead:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md"},
    button_text_align:{headline:"center", subhead:"center"},
    button_text_align_ios:{headline:"center", subhead:"center"},
    button_newpage: true,
    info: {
      onClick: false,
      image: "./assets/images/workflow/output_02.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x40",
        "2": "180x40",
        "3": "120x40",
        "4": "90x40",
        "5": "72x40",
        "6": "60x40",
      },
    },
  },
  [MENU_BUTTON_CODE.OUTPUT_03]: {
    button_code: "out_03",
    button_form: "out",
    button_style: "0303",
    button_style_ios: "0303",
    button_type: "out",
    button_keyboard: "text",
    button_headline: "Headline",
    button_subhead: "Subhead",
    button_body: "Value Text",

    button_text_fontsize: {headline:"md", subhead:"md", body:"md"},
    button_text_fontsize_ios: {headline:"md", subhead:"md", body: "md"},
    button_text_align:{headline:"center", subhead:"center", body:"center"},
    button_text_align_ios:{headline:"center", subhead:"center",body:"center"},

    button_newpage: true,
    info: {
      onClick: false,
      image: "./assets/images/workflow/output_03.webp",
      min_grid: 20,
      hight: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.SINGLECHOICE_3000]: {
    button_code: "singlechoice_3000",
    button_form: "singlechoice",
    button_style: "3000",
    button_style_ios: "3000",
    button_type: "inout",
    button_keyboard: "text",
    button_label: "Title",
    button_sublabel: "Your description.",
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
    ],

    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      }
    ],
    button_newpage: true,
    info: {
      image: "./assets/images/workflow/radio_3000.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLECHOICE_2000]: {
    button_code: "singlechoice_2000",
    button_form: "singlechoice",
    button_style: "2000",
    button_style_ios: "2000",
    button_type: "inout",
    button_label: "Title",
    button_sublabel: "Your description.",
    button_keyboard: "text",
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "button_optionOption 2",
        image: "./assets/images/workflow/work.png",
        value: "option-2",
      },
    ],
    button_value:[
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        value: "option-1",
      }
    ],

    button_newpage: true,
    info: {
      image: "./assets/images/workflow/radio_2000.jpg",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.SINGLECHOICE_1000]: {
    button_code: "singlechoice_1000",
    button_form: "singlechoice",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_label: "Title",
    button_sublabel: "Your description.",
    button_keyboard: "text",
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        value: "option-2",

      },
    ],

    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        value: "option-1",
      },
    ],
    button_newpage: true,
    info: {
      image: "./assets/images/workflow/radio_1000.webp",
      min_grid: 60,
      hight: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.MULTICHOICE_1000]: {
    button_code: "multiplechoice_1000",
    button_form: "multiplechoice",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "fas fa-home",
        value: "option-1",
      },
    ],

    button_newpage: true,
    info: {
      image: "./assets/images/workflow/checkbox_1000.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.MULTICHOICE_2000]: {
    button_code: "multiplechoice_2000",
    button_form: "multiplechoice",
    button_style: "2000",
    button_style_ios: "2000",
    button_type: "inout",
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option:     [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        image: "./assets/images/workflow/work.png",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        value: "option-1",
      },
    ],


    button_newpage: true,
    info: {
      image: "./assets/images/workflow/checkbox_2000.jpg",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.MULTICHOICE_3000]: {
    button_code: "multiplechoice_3000",
    button_form: "multiplechoice",
    button_style: "3000",
    button_style_ios: "3000",
    button_type: "inout",
    button_text_align:{headline:"left", subhead:"left"},
    button_text_align_ios:{headline:"left", subhead:"left"},
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
    ],
    button_value: {
      o_1: {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
    },

    button_keyboard: "text",
    button_newpage: true,
    info: {
      image: "./assets/images/workflow/checkbox_3000.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.TOGGLE_1000]: {
    button_code: "toggle_1000",
    button_form: "toggle",
    button_style: "1000",
    button_style_ios: "1000",
    button_type: "inout",
    button_label_color: "#2d546b",
    button_textcolor: "#2d546b",
    button_border_color: "#2d546b",
    button_bgcolor: "#ffffff",
    button_sublabel_color: "#2d546b",
    button_label: "Title",
    button_sublabel: "Your description.",
    button_keyboard: "text",
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        image: "./assets/images/workflow/work.png",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        image: "./assets/images/workflow/home.png",
        value: "option-1",
      }
    ],


    button_newpage: true,
    info: {
      image: "./assets/images/workflow/toggle_1000.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.TOGGLE_2000]: {
    button_code: "toggle_2000",
    button_form: "toggle",
    button_style: "2000",
    button_style_ios: "2000",
    button_type: "inout",
    button_textcolor: "#2d546b",
    button_border_color: "#2d546b",
    button_bgcolor: "#ffffff",
    button_sublabel_color: "#2d546b",
    button_icon_bgcolor: "#000000",
    button_label_color: "#2d546b",
    button_label: "Title",
    button_sublabel: "Your description.",
    button_keyboard: "text",
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        icon: "mir_bookmarks",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        icon: "mir_home",
        value: "option-1",
      }
    ],

    button_newpage: true,
    info: {
      image: "./assets/images/workflow/toggle_2000.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },
  [MENU_BUTTON_CODE.TOGGLE_3000]: {
    button_code: "toggle_3000",
    button_form: "toggle",
    button_style: "3000",
    button_style_ios: "3000",
    button_type: "inout",
    button_sublabel_color: "#2d546b",
    button_label_color: "#2d546b",
    button_textcolor: "#2d546b",
    button_border_color: "#2d546b",
    button_bgcolor: "#ffffff",
    button_label: "Title",
    button_sublabel: "Your description.",
    button_option: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      },
      {
        id: "o_2",
        label: "Option 2",
        value: "option-2",
      },
    ],
    button_value: [
      {
        id: "o_1",
        label: "Option 1",
        value: "option-1",
      }
    ],

    button_keyboard: "text",
    button_newpage: true,
    info: {
      image: "./assets/images/workflow/toggle_3000.webp",
      min_grid: 60,
      ratio: {
        "1": "360x120",
        "2": "180x120",
        "3": "120x120",
        "4": "90x120",
        "5": "72x120",
        "6": "60x120",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_01]: {
    button_code: "button_01",
    button_form: "button",
    button_style: "01",
    button_style_ios: "01",
    button_type: "submit-all",
    button_label: "Elevated",
    button_icon: "mir_add",
    button_icon_ios: "mir_add",
    button_db: 1,
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/elevated_button.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_02]: {
    button_code: "button_02",
    button_form: "button",
    button_style: "02",
    button_style_ios: "02",
    button_type: "submit-all",
    button_label: "Filled",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/filled_button.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_03]: {
    button_code: "button_03",
    button_form: "button",
    button_style: "03",
    button_style_ios: "03",
    button_type: "submit-all",
    button_label: "Tonal",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/tonal_button.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },
  [MENU_BUTTON_CODE.BUTTON_04]: {
    button_code: "button_04",
    button_form: "button",
    button_style: "04",
    button_style_ios: "04",
    button_type: "submit-all",
    button_label: "Outlined",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/outlined_button.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.BUTTON_05]: {
    button_code: "button_05",
    button_form: "button",
    button_style: "05",
    button_style_ios: "05",
    button_type: "submit-all",
    button_label: "Text",
    button_keyboard: "text",
    button_db: 1,
    button_newpage: true,
    info: {
      onClick: true,
      image: "./assets/images/workflow/text_button.webp",
      min_grid: 20,
      hight: 40,
      ratio: {
        "1": "360x20",
        "2": "180x20",
        "3": "120x20",
        "4": "90x20",
        "5": "72x20",
        "6": "60x20",
      },
    },
  },

  [MENU_BUTTON_CODE.PAGETEXT]: {
    button_code: "pagetext",
    button_form: "pagetext",
    button_style: "0",
    button_style_ios: "0",
    button_type: "pagetext",
    button_page_data: { title: "Title", body: "" },

    info: {
      min_grid: 60,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGESLIDER]:
{
        button_code:"pageslider",
        button_form: "pageslider",
        button_style: "1000",
        button_style_ios: "1000",
        button_label:"Page Title",
        button_sublabel: "Page Description",
        button_label_color: "#2D546B",
        button_sublabel_color: "#2D546B",
        button_type: "photo",
        button_page_data:{
           interval:0,
           animation: false
          },
        button_components:[
          { id: "0",  order:0, type: "photo", image: "https://st2.depositphotos.com/2306293/7723/i/950/depositphotos_77237624-stock-photo-happy-face-mug-on-the.jpg"}
          ,{ id: "1",  order:1,type: "photo", image: "https://st3.depositphotos.com/2306293/15341/i/1600/depositphotos_153418610-stock-photo-sign-happy-on-the-miami.jpg"}
          ,{ id: "2",  order:2, type: "photo", image: "https://st2.depositphotos.com/4215343/10981/i/950/depositphotos_109810160-stock-photo-wish-you-were-here.jpg"} ],
        info: {
            min_grid: 60,
            image: ""
           },
  },
  [MENU_BUTTON_CODE.PAGESLIDERLABEL]:
  {
          button_code:"pagesliderlabel",
          button_form: "pagesliderlabel",
          button_style: "2000",
          button_style_ios: "2000",
          button_label:"Page Title",
          button_sublabel: "Page Description",
          button_label_color: "#2D546B",
          button_sublabel_color: "#2D546B",

          button_type: "photo",
          button_page_data:{
            interval:0,
            animation: false
            },
          button_components:[
            { id: "0",  order:0, title:"Page Title",desc:"Page Description", type: "photo", image: "https://st2.depositphotos.com/2306293/7723/i/950/depositphotos_77237624-stock-photo-happy-face-mug-on-the.jpg"}
            ,{ id: "1",  order:1,title:"Page Title",desc:"Page Description", type: "photo", image: "https://st3.depositphotos.com/2306293/15341/i/1600/depositphotos_153418610-stock-photo-sign-happy-on-the-miami.jpg"}
            ,{ id: "2",  order:2,title:"Page Title",desc:"Page Description", type: "photo", image: "https://st2.depositphotos.com/4215343/10981/i/950/depositphotos_109810160-stock-photo-wish-you-were-here.jpg"} ],
          info: {
              min_grid: 60,
              image: ""
            },
  },
  [MENU_BUTTON_CODE.PAGEGALLERY]:
  {
          button_code:"pagegallery",
          button_form: "pagegallery",
          button_style: "1000",
          button_style_ios: "1000",
          button_type: "photo",
          button_components:[
              { id: "0",  order:0, type: "photo", image: "https://images.pexels.com/photos/20145987/pexels-photo-20145987/free-photo-of-a-person-riding-a-motorcycle-on-a-road.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "1",  order:1, type: "photo", image: "https://images.pexels.com/photos/16113289/pexels-photo-16113289/free-photo-of-close-up-of-reed.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "2",  order:2, type: "photo", image: "https://images.pexels.com/photos/20001995/pexels-photo-20001995/free-photo-of-a-black-and-white-photo-of-a-roller-coaster.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "3",  order:3, type: "photo", image: "https://images.pexels.com/photos/20084774/pexels-photo-20084774/free-photo-of-a-cloud-in-the-sky-over-some-rocks.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "4",  order:4, type: "photo", image: "https://images.pexels.com/photos/20103012/pexels-photo-20103012/free-photo-of-a-snowy-forest-with-trees-and-sun-shining-through.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
              ,{ id: "5",  order:5, type: "photo", image: "https://images.pexels.com/photos/16575290/pexels-photo-16575290/free-photo-of-view-from-car-window-on-road-in-forest-in-rain.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}
],
          info: {
              min_grid: 20,
              image: ""
            },
  },
  [MENU_BUTTON_CODE.PAGESINGLEIMAGE]:
  {
          button_code:"pagesingleimage",
          button_form: "pagesingleimage",
          button_style: "1000",
          button_style_ios: "1000",
          button_type: "photo",
          button_label:"Page Title",
          button_sublabel: "Page Description",
          button_label_color: "#2D546B",
          button_sublabel_color: "#2D546B",
          button_components:[
            { id: "1",  order:1, type: "photo",image: "https://images.pexels.com/photos/16575290/pexels-photo-16575290/free-photo-of-view-from-car-window-on-road-in-forest-in-rain.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500"}]
              ,
          info: {
              onClick: true,
              min_grid: 20,
              image: ""
            },
  },
  [MENU_BUTTON_CODE.PAGEHEADER]:
{
        button_code:"pageheader",
        button_form: "pageheader",
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        button_page_data:{
          header: "Heading"
          },
        info: {
            min_grid: 20,
            image: ""
           },
  },
  [MENU_BUTTON_CODE.PAGEDIVIDER]:
{
        button_code: MENU_BUTTON_CODE.PAGEDIVIDER,
        button_form: MENU_BUTTON_FORM.PAGEDIVIDER,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      border_margin_top: 1,
      border_margin_bottom: 1,
      border_margin_start: 1,
      border_margin_end: 1,
      border_size: 1,
      border_color: "#00000",
    },
    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEVIDEO]:
{
        button_code: MENU_BUTTON_CODE.PAGEVIDEO,
        button_form: MENU_BUTTON_FORM.PAGEVIDEO,
        button_style: "1000",
        button_style_ios: "1000",
        button_label:"Page Title",
        button_sublabel: "Page Description",
        button_label_color: "#2D546B",
        button_sublabel_color: "#2D546B",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      video_url:
        "https://www.youtube.com/embed/Weu3iPqOX3Q?si=xMuofzCTr21_4KMa",
    },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEQR]:
  {
        button_code: MENU_BUTTON_CODE.PAGEQR,
        button_form: MENU_BUTTON_FORM.PAGEQR,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        button_page_data:{
          qr_value: "QRCode:uBJcae/QYj/PctjYYsq"
          },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGELOCATION]:
  {
        button_code: MENU_BUTTON_CODE.PAGELOCATION,
        button_form: MENU_BUTTON_FORM.PAGELOCATION,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      map_options: {
        center: { lat: 48.8588548, lng: 2.347035 },
        zoom: 13,
      },
      map_markers: [
        { id: 1, position: { lat: 48.8584, lng: 2.2945 } }, // Eiffel Tower
        { id: 2, position: { lat: 48.8606, lng: 2.3376 } }, // Louvre Museum
        { id: 3, position: { lat: 48.853, lng: 2.3499 } }, // Cathédrale Notre-Dame de Paris
      ],
      zoom: 13,
    },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEPDFVIEWER]:
  {
        button_code: MENU_BUTTON_CODE.PAGEPDFVIEWER,
        button_form: MENU_BUTTON_FORM.PAGEPDFVIEWER,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {
      pdf_url: "https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf",
    },

    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGESOCIALBAR]:
{
        button_code: MENU_BUTTON_CODE.PAGESOCIALBAR,
        button_form: MENU_BUTTON_FORM.PAGESOCIALBAR,
        button_style: "1000",
        button_style_ios: "1000",
        button_type: "photo",
        web_button:{},

    button_page_data: {},
    button_components: [
      {
        id: "1",
        order: 0,
        type: "facebook",
        title: "Facebook",
        icon: "fab fa-facebook-f fa-1x",
        color: "#3b5998",
      },
      {
        id: "2",
        order: 1,
        type: "whatsapp",
        title: "Whatsapp",
        icon: "fab fa-whatsapp fa-1x",
        color: "#25d366",
      },
      {
        id: "3",
        order: 3,
        type: "instagram",
        title: "Instagram",
        icon: "fab fa-instagram",
        color: "#ac2bac",
      },
    ],
    info: {
      min_grid: 20,
      image: "",
    },
  },
  [MENU_BUTTON_CODE.PAGEFEATURE]: {
    button_code: "pagefeature",
    button_form: "pagefeature",
    button_style: "0",
    button_style_ios: "0",
    button_type: "",
    web_button: {},
    info: {
      min_grid: 20,
    },
  },
  [MENU_BUTTON_CODE.PLACEHOLDER]: {
    button_form: "placeholder",
    button_style: "0",
    button_style_ios: "0",
    button_type: "placeholder",
    info: {
      min_grid: 10,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_01]: {
    button_code: "splash_01",
    button_form: "splash",
    button_style: "01",
    button_style_ios: "01",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["01"].button_part.title,
    button_sublabel: splash_config_map["01"].button_part.desc,
    button_newpage: true,
    button_bgimage: splash_config_map["01"].button_part.bg_image,
    button_bgstart: splash_config_map["01"].button_part.start_color,
    button_bgend: splash_config_map["01"].button_part.end_color,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
  [MENU_BUTTON_CODE.SPLASH_02]: {
    button_code: "splash_02",
    button_form: "splash",
    button_style: "02",
    button_style_ios: "02",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["02"].button_part.title,
    button_sublabel: splash_config_map["02"].button_part.desc,
    button_newpage: true,
    button_bgimage: splash_config_map["02"].button_part.bg_image,
    button_bgstart: splash_config_map["02"].button_part.start_color,
    button_bgend: splash_config_map["02"].button_part.end_color,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
  [MENU_BUTTON_CODE.SPLASH_03]: {
    button_code: "splash_03",
    button_form: "splash",
    button_style: "03",
    button_style_ios: "03",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["03"].button_part.title,
    button_sublabel: splash_config_map["03"].button_part.desc,
    button_newpage: true,
    button_bgimage: splash_config_map["03"].button_part.bg_image,
    button_bgstart: splash_config_map["03"].button_part.start_color,
    button_bgend: splash_config_map["03"].button_part.end_color,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_04]: {
    button_code: "splash_04",
    button_form: "splash",
    button_style: "04",
    button_style_ios: "04",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["04"].button_part.title,
    button_sublabel: splash_config_map["04"].button_part.desc,
    button_newpage: true,
    button_bgimage: splash_config_map["04"].button_part.bg_image,
    button_bgstart: splash_config_map["04"].button_part.start_color,
    button_bgend: splash_config_map["04"].button_part.end_color,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_05]: {
    button_code: "splash_05",
    button_form: "splash",
    button_style: "05",
    button_style_ios: "05",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["05"].button_part.title,
    button_sublabel: splash_config_map["05"].button_part.desc,
    button_newpage: true,
    button_bgimage: splash_config_map["05"].button_part.bg_image,
    button_bgstart: splash_config_map["05"].button_part.start_color,
    button_bgend: splash_config_map["05"].button_part.end_color,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },

  [MENU_BUTTON_CODE.SPLASH_06]: {
    button_code: "splash_06",
    button_form: "splash",
    button_style: "06",
    button_style_ios: "06",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["06"].button_part.title,
    button_sublabel: splash_config_map["06"].button_part.desc,
    button_newpage: true,
    button_bgimage: splash_config_map["06"].button_part.bg_image,
    button_bgstart: splash_config_map["06"].button_part.start_color,
    button_bgend: splash_config_map["06"].button_part.end_color,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
  [MENU_BUTTON_CODE.SPLASH_07]: {
    button_code: "splash_07",
    button_form: "splash",
    button_style: "07",
    button_style_ios: "07",
    button_type: "splash",
    button_bgcolor: "",
    button_label: splash_config_map["07"].button_part.title,
    button_sublabel: splash_config_map["07"].button_part.desc,
    button_newpage: true,
    button_bgimage: splash_config_map["07"].button_part.bg_image,
    button_bgstart: splash_config_map["07"].button_part.start_color,
    button_bgend: splash_config_map["07"].button_part.end_color,
    title: "Onboarding",
    info: {
      lock: true,
      onClick: false,
      min_grid: 60,
    },
  },
};

export const common_components_map = {
  [TabNames.MENU]: {
    type: TabNames.MENU,

    icon: "mir_dashboard",
    icon_ios: "mir_dashboard",

    title: "Workflow",
    title_ios: "Workflow",

    options: "0",
    access: 0,
    name: "Custom Native Workflow",
    hint: "Full custom native cells with API access",
  },
  [TabNames.POLL]: {
    type: TabNames.POLL,
    icon: "mir_shopping_card",
    icon_ios: "mir_shopping_card",
    title: "Poll",
    title_ios: "Poll",
    options: "0",
    access: 0,
    name: "Customize Your Poll",
    hint: "Design Your Poll and Questionnaire",
  },
  [TabNames.PAGE]: {
    type: TabNames.PAGE,
    icon: "mir_web",
    icon_ios: "mir_web",

    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",

    title: "Page",
    title_ios: "Page",
    options: "0",
    access: 0,
   name: "Custom Wepages",
   hint: "Full custom webpages",
  },
  [TabNames.FEED]: {
    type: TabNames.FEED,
    icon: "mir_feed",
    icon_ios: "mir_feed",
    title: "Feeds",
    title_ios: "Feeds",
    options: "0",
    access: 1,
    post: {
      column: 1,
      type: "wide",
    },
    footer: {
      status: 1,
      action: [
        {
          link: "like",
          icon: "mir_thumb_up",
          hazem: 'hazem',
          status: true,
        },
        {
          link: "like_highlight",
          icon: "mis_thumb_up",
          fill_color: "#F20000",
          status: true,
        },
        {
          link: "reply",
          icon: "mir_mode_comment",
          status: true,
        },
        {
          link: "view",
          icon: "mis_check_circle",
          status: true,
        },
        {
          link: "share",
          icon: "mir_east",
          status: true,
        },
      ],
    },

    menu: [
      {
        link: "share",
        title: "Share",
        icon: "mir_east",
        status: true,
      },
      {

        link: "forward",
        title: "Forward",
        icon: "mir_call_made",
        status: true,
      },
      {
        link: "copy",
        title: "Copy",
        icon: "mir_import_contacts",
        status: true,
      },
      {

        link: "edit",
        title: "Edit",
        icon: "mir_add",
        status: true,
      },
      {
        link: "save_to_gallery",
        title: "Save to Gallery",
        icon: "mir_library_books",
        status: true,
      },
      {
        link: "save_to_music",
        title: "Save to Music",
        icon: "mir_library_music",
        status: true,
      },
      {
        link: "save_to_downloads",
        title: "Save to Downloads",
        icon: "mir_south",
        status: true,
      },
      {
        // icon: "fas fa-exclamation-circle",
        link: "abuse",
        title: "Report abuse",
        icon: "mir_report",
        status: true,
      },
      {
        link: "delete",
        title: "Delete",
        icon: "mir_warrning",
        status: true,
      },
    ],

    name: "App News Feed",
    hint: "Give your App users a dynamic feed of posts that can interact with.",
  },
  [TabNames.CHANNEL]: {
    type: TabNames.CHANNEL,

    icon: "mir_rss_feed",
    icon_ios:"mir_rss_feed",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/active-channels-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/active-channels-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Active-Channels_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Active-Channels_iOS_S.webp",

    title: "Active Channels",
    title_ios: "Active Channels",

    options: "0",
    access: 0,
    name: "Channels Dashboard",
    hint: "Display a dashboard of active channels the user has joined.",
  },
  [TabNames.CHANNELLIST]: {
    type: TabNames.CHANNELLIST,
    icon: "mir_space_dashboard",
    icon_ios: "mir_space_dashboard",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/my-channels-channel-list-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/my-channels-channel-list-AN_S.webp",
    ios_url_l: "",
    ios_url_s: "",
    title: "My Channels",
    title_ios: "My Channels",
    options: "0",
    access: 3,
    name: "My Active Channels",
    hint: "Display a list of all channels the user has joined.",
  },
  [TabNames.ONLINECHANNEL]: {
    type: TabNames.ONLINECHANNEL,
    icon: "mir_near_me",
    icon_ios: "mir_near_me",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/my-channels-channel-list-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/my-channels-channel-list-AN_S.webp",
    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Channels-_Channel-List_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Channels-_Channel-List_iOS_S.webp",
    title: "Channels",
    title_ios: "Channels",
    options: "0",
    access: 2,
    name: "App Channels List",
    hint: "Display all avaliable App channels list.",
  },
  [TabNames.CHAT]: {
    type: TabNames.CHAT,
    icon: "mir_rocket_lunch",
    icon_ios: "mir_rocket_lunch",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/messenger-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/messenger-AN_S.webp",
    nandbox_bubble_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_L.webp",
    nandbox_bubble_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_S.webp",
    darktheme_bubble_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_L.webp",
    darktheme_bubble_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_S.webp",
    whatsapp_bubble_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_L.webp",
    whatsapp_bubble_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_S.webp",
    viber_bubble_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_L.webp",
    viber_bubble_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_S.webp",
    wechat_bubble_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_L.webp",
    wechat_bubble_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_S.webp",
    telegram_bubble_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_L.webp",
    telegram_bubble_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_S.webp",
    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Messenger_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Messenger_iOS_S.webp",

    title: "Messenger",
    title_ios: "Messenger",
    options: "0",
    access: 0,
    name: "Chat Messenger",
    hint: "A full functioning messenger enables users to chat with contact or group and share photos, videos, voice notes, and multimedia files. Ordered by recent conversions.",
  },
  [TabNames.VIDEOAUDIO]: {
    type: TabNames.CHAT,
    icon: "mir_video_call",
    icon_ios: "mir_video_call",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/videoand-audio_AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/videoand-audio-calling_AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Audio-Calling_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Audio-Calling_iOS_S.webp",

    title: "Calls",
    title_ios: "Calls",
    video_call: true,
    options: "0",
    access: 2,
    name: "Video & Audio Chats",
    hint: "A full functioning messenger with Video and Audio Calls",
  },
  [TabNames.OPENBOT]: {
    type: TabNames.OPENBOT,
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-bot-AN_S.webp",
      icon: "mir_account_circle",
      icon_ios: "mir_account_circle",
    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Chat-Bot_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Chat-Bot_iOS_S.webp",

    title: "Chatbot",
    title_ios: "Chatbot",
    options: "0",
    access: 3,
    name: "Bot Chat",
    hint: "Integrate third-party bots, build your own, or choose from ready made bots to perform certain functions within your App.",
  },
  [TabNames.CONTACT]: {
    type: TabNames.CONTACT,
    icon: "mir_person_add",
    icon_ios: "mir_person_add",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/contacts-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/contacts-AN_S.webp",
    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Contacts_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Contacts_iOS_S.webp",

    title: "Contacts",
    title_ios: "Contacts",
    show_invite_friends: 1,
    name: "User Contacts",
    hint: "Users contact allows user to invite contact to the app, join channel, group and start chat or call.",

  },
  [TabNames.SPEEDDIALER]: {
    type: TabNames.SPEEDDIALER,
    icon: "mir_phone_in_talk",
    icon_ios: "mir_phone_in_talk",
    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",
    title: "Phone",
    title_ios: "Phone",
    options: "0",
    access: 0,
    name: "Calls Dialer",
    hint: "Allow users to make Video and Audio Calls from your App.",
  },



  [TabNames.CALL_LOG]: {
    type: TabNames.CALL_LOG,
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/call-log-AN_S.webp",

    icon: "mir_call",
    icon_ios: "mir_call",
    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Call-Log_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Call-Log_iOS_S.webp",

    options: "0",
    access: 0,

    title: "Call Log",
    title_ios: "Call Log",
    name: "Call History",
    hint: "User recent received and sent Video and Audio calls.",

  },
  [TabNames.GROUP]: {
    type: TabNames.GROUP,
    icon: "mir_group_add",
    icon_ios: "mir_group_add",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/my-group-group-list-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/my-group-group-list-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Group-Group-List_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Group-Group-List_iOS_S.webp",
    title: "My Groups",
    title_ios: "My Groups",
    options: "0",
    access: 1,
    name: "Group Chats",
    hint: "Allow users to access their joined group chats, send multimedia files and search throughout their conversations",
  },
  [TabNames.OPENCHAT]: {
    type: TabNames.OPENCHAT,
    title: "Chat Group",
    title_ios: "Chat Group",
    icon: "mir_chat",
    icon_ios: "mir_chat",
    access: 1,
    options: "0",
    android_url_l:"https://nandbox.com/wp-content/uploads/2024/01/chat-group-AN_L.webp",
    android_url_s:"https://nandbox.com/wp-content/uploads/2024/01/chat-group-AN_S.webp",
    ios_url_l:"https://nandbox.com/wp-content/uploads/2024/01/Chat-Group_iOS_L.webp",
    ios_url_s:"https://nandbox.com/wp-content/uploads/2024/01/Chat-Group_iOS_S.webp",
    name: "Chat with Bot",
    hint: "Allow user to chat with a bot",
    },

  [TabNames.ONBOARDING]: {
    type: TabNames.OPENCHAT,
    title: "Chat Group",
    title_ios: "Chat Group",
    icon: "mir_chat",
    icon_ios: "mir_chat",
    access: 1,
    options: "0",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-group-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-group-AN_S.webp",
    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Chat-Group_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Chat-Group_iOS_S.webp",
    name: "Onboarding and Support Group",
   hint: "User chats in private with Group Adminstrator based on one to one chat.",
  },
  [TabNames.ONLINEGROUP]: {
    type: TabNames.ONLINEGROUP,

    icon: "mir_groups",
    icon_ios: "mir_groups",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/my-group-group-list-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/my-group-group-list-AN_S.webp",

    ios_url_l: "",
    ios_url_s: "",

    title: "Groups",
    title_ios: "Groups",

    options: "0",
    access: 0,
    name: "App Group List",
    hint: "Display all avaliable App group list.",

  },
  [TabNames.STORE]: {
    type: TabNames.STORE,
    icon: "mir_shopping_cart",
    icon_ios: "mir_shopping_cart",
    title: "Store",
    title_ios: "Store",
    use_default:1,
    name: "Mobile Store",
    hint: "Full custom native Mobile Store",
    image_url: "https://m1.nandbox.ca/v1/AUTH_137b624a8e434c4e88e9be30fa5e7bed/g/90090684357077161_0ytfClGNUx_s_hdpi.png?temp_url_sig=4e14bda3c06e8f9dce9db0e71ce038d14efc1369&temp_url_expires=2029422062",

  },
  [TabNames.BOOKINGMARKETPLACE]: {
    type: TabNames.STORE,
    icon: "mir_shopping_cart",
    icon_ios: "mir_shopping_cart",
    title: "Store",
    title_ios: "Store",
    options: "0",
    access: 0,
    classname: "booking",
    name: "Booking/Event Marketplace",
    hint: "Design Booking, Event and/or Queuing Marketplace",
  },
  [TabNames.CALENDAR]: {
    type: TabNames.CALENDAR,

    icon: "mir_calendar",
    icon_ios: "mir_calendar",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/my-calendar-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/my-calendar-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Calendar_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Calendar_iOS_S.webp",

    title: "Calendar",
    title_ios: "Calendar",

    options: "0",
    access: 3,
    name: "My Calendar",
    hint: "The App calendar will display all user's activities within the App and upcoming events. The calendar syncs optionally events to the user's device calendar",

  },
  [TabNames.INVITATION]: {
    type: TabNames.INVITATION,

    icon: "mir_event",
    icon_ios: "mir_event",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/my-invitations-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/my-invitations-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Invitations_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/My-Invitations_iOS_S.webp",

    title: "My Invitations",
    title_ios: "My Invitations",

    options: "0",
    access: 0,
    name: "Invitation Dashboard",
                          hint: "Allow your App users to send manage received invitation for joining channel, group, chat and/or upgrade member to adminstator",
  },
  [TabNames.QR]: {
    type: TabNames.QR,

    icon: "mir_qr_code_2",
    icon_ios: "mir_qr_code_2",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/qr-code-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/qrcode-AN_S.webp",
    opt2_android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/myqr-an-L.webp",
    opt2_android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/myqr-AN_S.webp",
    opt1_android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/qr-link-AN_L.webp",
    opt1_android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/qr-link-an-s.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/QR-Code_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/QR-Code_iOS_S.webp",
    opt2_ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/MyQRCode_iOS_L.webp",
    opt2_ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/MyQRCode_iOS_S.webp",
    opt1_ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/QRCodeLink_iOS_L.webp",
    opt1_ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/QRCodeLink_iOS_S.webp",

    title: "QR",
    title_ios: "Qr",

    options: "1",
    access: 1,
    generic_qr: {
      enable_share: 0,
      enable_open_web: 0,
      enable_copy: 0,
    },

    name: "QR Scanner",
    hint: "Allow users to scan avaliable QRs to join chat, group, channel and login to Webchat or Web-Admin",
  },
  [TabNames.WEBVIEW]: {
    type: TabNames.WEBVIEW,
    icon: "mir_web",
    icon_ios: "mir_web",
    title: "Webview",
    title_ios: "Webview",
    options: "0",
    access: 0,
    web_view: {},
    name: "Webview Page",
    hint: "Allow you to redirect users to your website, blog or any external webpage of your choice to be displayed within the App",
  },
  [TabNames.VIDEO]: {
    type: TabNames.VIDEO,

    icon: "mir_video_library",
    icon_ios: "mir_video_library",

    title: "Video",
    title_ios: "Video",

    options: "0",
    access: 0,

    bg_color: "#082b44",
    text_color: "#ffffff",
    desc: "play video",
    image_url: "https://nandbox.com/wp-content/uploads/2024/01/video_AN_L.webp",
    name: "Video Page",
    hint: "With the new Video module, you can include a link that will redirect the users to a Video of your choice",
  },
  [TabNames.MAP_SEARCH]: {
    type: TabNames.MAP_SEARCH,
    icon: "mir_map",
    icon_ios: "mir_map",
    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/map-search-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/map-search-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Map-Search_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Map-Search_iOS_S.webp",

    title: "Map Search",
    title_ios: "Map Search",
    options: "0",
    access: 2,
    name: "Map Search",
    hint: "Allow users to explore their neighborhoods and find nearby places based on their interest and geo-location.",

  },
  [TabNames.SEARCH]: {
    type: TabNames.SEARCH,
    icon: "mir_saved_search",
    icon_ios: "mir_saved_search",
    title: "Search",
    title_ios: "Search",
    options: "0",
    access: 0,
    search: {
      filter: [],
      search: [],
      search_by_time: 0,
      search_btn_icon: "mir_search",
      page_index: 1,
      search_text_hint: "What are you searching for?",
      search_by_date: 0,
       },
  name: "Search",
  hint: "Include a powerful search tool that work throughout the App contents and service",
  },
  [TabNames.SETTINGS]: {
    type: TabNames.SETTINGS,
    icon: "fas_fa_cogs",
    icon_ios: "fas_fa_cogs",
    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",
    title: "Empty",
    title_ios: "Empty",
    options: "0",
    access: 0,
    bg_color: "#3e51ff",
    text_color: "#ffffff",
    name: "User Settings",
    hint: "Allow user to control thier own Downloads, Media storage, Account, Notifications, and Chats settings. This module is essential to get your App approved by Apple.",

  },
  [TabNames.OPENGROUP]: {
    type: TabNames.OPENGROUP,

    icon: "mir_group",
    icon_ios: "mir_group",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-group-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/chat-group-AN_S.webp",
    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Chat-Group_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Chat-Group_iOS_S.webp",

    title: "Chat Group",
    title_ios: "Chat Group",

    options: "0",
    access: 0,
    name: "Chat With A Group",
    hint: "Allow user to chat with a specific group. All group chats created on your App can include up to 10k users",
  },

/////// ***** not yet completed ********** ////
  [TabNames.BOOKINGLIST]: {
    type: TabNames.BOOKINGLIST,

    icon: "mir_bookmarks",
    icon_ios: "mir_bookmarks",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/booking-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/booking-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Booking_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Booking_iOS_S.webp",

    title: "Booking",
    title_ios: "Booking",

    options: "0",
    access: 3,
    name: "App Booking List",
    hint: "Display all avaliable App booking list. Allow user to book slot or ticket",

  },
  [TabNames.BOOKING]: {
    type: TabNames.BOOKING,

    icon: "mir_bookmarks",
    icon_ios: "mir_bookmarks",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/booking-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/booking-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Booking_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Booking_iOS_S.webp",

    title: "Booking",
    title_ios: "Booking",

    options: "0",
    access: 3,
    name: "App Booking ",
    hint: "Display all avaliable App booking list. Allow user to book slot or ticket",
  },
  [TabNames.REDEEM_TICKET]: {
    type: TabNames.QR_PAGE,

    icon: "mir_qr_code",
    icon_ios: "mir_qr_code",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/redeem-code-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/redeem-code-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Redeem_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Redeem_iOS_S.webp",

    title: "Redemption",
    title_ios: "Redemption",
    options: "2",
    access: 0,
    name: "Ticket Redeemtion",
    hint: "Allows adminstators to redeem or cancle the user booking or queuing tickets using QR scan",

  },
  [TabNames.VALIDATE_TICKET]: {
    type: TabNames.QR_PAGE,

    icon: "mir_qr_code_scanner",
    icon_ios: "mir_qr_code_scanner",

    android_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/validation-check-AN_L.webp",
    android_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/validation-check-AN_S.webp",

    ios_url_l:
      "https://nandbox.com/wp-content/uploads/2024/01/Validation_iOS_L.webp",
    ios_url_s:
      "https://nandbox.com/wp-content/uploads/2024/01/Validation_iOS_S.webp",

    title: "Validation",
    title_ios: "Validation",

    options: "1",
    access: 1,
    name: "Ticket Validation",
    hint: "Allows adminstators to validate the user booking or queuing tickets using QR scan",

  },

  [TabNames.EMPTY]: {
    type: TabNames.EMPTY,

    icon: "mir_add",
    icon_ios: "mir_add",
    android_url_l: "./assets/images/emptyImageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",
    title: "New Tab",
    title_ios: "New Tab",
    options: "0",
    access: 0,
    bg_color: "#3e51ff",
    text_color: "#ffffff",
  },
  [TabNames.ORDERLIST]: {
    type: TabNames.ORDERLIST,
    icon: "fas_fa_shopping_bag",
    icon_ios: "fas_fa_shopping_bag",

    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",

    title: "Empty",
    title_ios: "Empty",
    options: "0",
    access: 0,
    name: "User Order List",
    hint: "Display user recent order history.",

  },
  [TabNames.EVENTLIST]: {
    type: TabNames.EVENTLIST,

    icon: "fas_fa_list_ul",
    icon_ios: "fas_fa_list_ul",

    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",

    title: "Empty",
    title_ios: "Empty",
    options: "0",
    access: 0,
    name: "App Event List",
    hint: "Display all avaliable App booking list.",

  },
  [TabNames.EVENT]: {
    type: TabNames.EVENT,

    icon: "far_fa_calendar_plus",
    icon_ios: "far_fa_calendar_plus",

    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",

    title: "Empty",
    title_ios: "Empty",
    options: "0",
    access: 0,
    bg_color: "#3e51ff",
    text_color: "#ffffff",
  },
  [TabNames.QUEUINGLIST]: {
    type: TabNames.QUEUINGLIST,

    icon: "fas_fa_th_list",
    icon_ios: "fas_fa_th_list",

    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",

    title: "Empty",
    title_ios: "Empty",
    options: "0",
    access: 0,
    bg_color: "#3e51ff",
    text_color: "#ffffff",

    name: "App Queuing List",
    hint: "Display all avaliable App queuing list. Allow user to reserve slot or ticket in the queue service",

  },
  [TabNames.SPLASH]: {
    type: TabNames.SPLASH,
    module: TabNames.SPLASH,
    cat: "splash",
    icon: "",
    icon_ios: "",
    android_url_l: "",
    android_url_s: "",
    title: "Splash",
    title_ios: "Splash",
    options: "0",
    access: 0,
    param: {
      splash: {
        style: "",
        title: null,
        button_title: null,
        button_bgcolor: null,
        button_text_color: null,
        tnc_text_color: null,
        tnc_link_color: null,
        tnc_bgcolor: null,
        bg_color: {},
      },
    },
  },
  [TabNames.PALETTE]: {
    type: TabNames.PALETTE,
    module: TabNames.PALETTE,
    icon: "fas_fa_th_list",
    icon_ios: "fas_fa_th_list",

    android_url_l: "./assets/images/empty-imageL.webp",
    android_url_s: "./assets/images/emptyImageS.webp",

    title: "Empty",
    title_ios: "Empty",
    options: "0",
    access: 0,
    bg_color: "#3e51ff",
    text_color: "#ffffff",
  },
};

export const common_tab_migration_map = {
  [TabNames.BOOKING]: {
    icon: common_components_map[TabNames.BOOKING].icon,
    icon_ios: common_components_map[TabNames.BOOKING].icon_ios,
  },

  [TabNames.BOOKINGLIST]: {
    icon: common_components_map[TabNames.BOOKINGLIST].icon,
    icon_ios: common_components_map[TabNames.BOOKINGLIST].icon_ios,
  },

  [TabNames.CALENDAR]: {
    icon: common_components_map[TabNames.CALENDAR].icon,
    icon_ios: common_components_map[TabNames.CALENDAR].icon_ios,
  },

  [TabNames.CALL_LOG]: {
    icon: common_components_map[TabNames.CALL_LOG].icon,
    icon_ios: common_components_map[TabNames.CALL_LOG].icon_ios,
  },

  [TabNames.CHANNEL]: {
    icon: common_components_map[TabNames.CHANNEL].icon,
    icon_ios: common_components_map[TabNames.CHANNEL].icon_ios,
  },

  [TabNames.CHANNELLIST]: {
    icon: common_components_map[TabNames.CHANNELLIST].icon,
    icon_ios: common_components_map[TabNames.CHANNELLIST].icon_ios,
  },

  [TabNames.CHAT]: {
    icon: common_components_map[TabNames.CHAT].icon,
    icon_ios: common_components_map[TabNames.CHAT].icon_ios,
  },

  [TabNames.CONTACT]: {
    icon: common_components_map[TabNames.CONTACT].icon,
    icon_ios: common_components_map[TabNames.CONTACT].icon_ios,
  },

  [TabNames.EMPTY]: {
    icon: common_components_map[TabNames.EMPTY].icon,
    icon_ios: common_components_map[TabNames.EMPTY].icon_ios,
  },

  [TabNames.EVENT]: {
    icon: common_components_map[TabNames.EVENT].icon,
    icon_ios: common_components_map[TabNames.EVENT].icon_ios,
  },
  [TabNames.EVENTLIST]: {
    icon: common_components_map[TabNames.EVENTLIST].icon,
    icon_ios: common_components_map[TabNames.EVENTLIST].icon_ios,
  },
  [TabNames.FEED]: {
    icon: common_components_map[TabNames.FEED].icon,
    icon_ios: common_components_map[TabNames.FEED].icon_ios,
    like_icon: "mir_thumb_up",
    liked_icon: "mis_thumb_up",
    reply_icon: "mir_mode_comment",
    view_icon: "mis_check_circle",
    share_icon: "mir_east",
  },
  [TabNames.GROUP]: {
    icon: common_components_map[TabNames.GROUP].icon,
    icon_ios: common_components_map[TabNames.GROUP].icon_ios,
  },
  [TabNames.INVITATION]: {
    icon: common_components_map[TabNames.INVITATION].icon,
    icon_ios: common_components_map[TabNames.INVITATION].icon_ios,
  },
  [TabNames.MAP_SEARCH]: {
    icon: common_components_map[TabNames.MAP_SEARCH].icon,
    icon_ios: common_components_map[TabNames.MAP_SEARCH].icon_ios,
  },


  [TabNames.MENU]: {
    icon: common_components_map[TabNames.MENU].icon,
    icon_ios: common_components_map[TabNames.MENU].icon_ios,
  },
  // [TabNames.MYLIST]:{
  //   icon: common_components_map[TabNames.MYLIST].icon,
  //   icon_ios: common_components_map[TabNames.MYLIST].icon_ios,
  //   },
  [TabNames.ONLINECHANNEL]: {
    icon: common_components_map[TabNames.ONLINECHANNEL].icon,
    icon_ios: common_components_map[TabNames.ONLINECHANNEL].icon_ios,
  },
  [TabNames.ONLINEGROUP]: {
    icon: common_components_map[TabNames.ONLINEGROUP].icon,
    icon_ios: common_components_map[TabNames.ONLINEGROUP].icon_ios,
  },

  [TabNames.OPENBOT]: {
    icon: common_components_map[TabNames.OPENBOT].icon,
    icon_ios: common_components_map[TabNames.OPENBOT].icon_ios,
  },

  [TabNames.OPENCHAT]: {
    icon: common_components_map[TabNames.OPENCHAT].icon,
    icon_ios: common_components_map[TabNames.OPENCHAT].icon_ios,
  },

  [TabNames.OPENGROUP]: {
    icon: common_components_map[TabNames.OPENGROUP].icon,
    icon_ios: common_components_map[TabNames.OPENGROUP].icon_ios,
  },

  [TabNames.ORDERLIST]: {
    icon: common_components_map[TabNames.ORDERLIST].icon,
    icon_ios: common_components_map[TabNames.ORDERLIST].icon_ios,
  },

  [TabNames.PAGE]: {
    icon: common_components_map[TabNames.PAGE].icon,
    icon_ios: common_components_map[TabNames.PAGE].icon_ios,
  },

  [TabNames.QR]: {
    icon: common_components_map[TabNames.QR].icon,
    icon_ios: common_components_map[TabNames.QR].icon_ios,
  },

  // [TabNames.QR_PAGE]:{
  //   icon: common_components_map[TabNames.QR_PAGE].icon,
  //   icon_ios: common_components_map[TabNames.QR_PAGE].icon_ios,
  //   },

    [TabNames.QUEUINGLIST]: {
    icon: common_components_map[TabNames.QUEUINGLIST].icon,
    icon_ios: common_components_map[TabNames.QUEUINGLIST].icon_ios,
  },

  [TabNames.REDEEM_TICKET]: {
    icon: common_components_map[TabNames.REDEEM_TICKET].icon,
    icon_ios: common_components_map[TabNames.REDEEM_TICKET].icon_ios,
  },

  [TabNames.SEARCH]: {
    icon: common_components_map[TabNames.SEARCH].icon,
    icon_ios: common_components_map[TabNames.SEARCH].icon_ios,
  },

  [TabNames.SETTINGS]: {
    icon: common_components_map[TabNames.SETTINGS].icon,
    icon_ios: common_components_map[TabNames.SETTINGS].icon_ios,
  },

  [TabNames.SPEEDDIALER]: {
    icon: common_components_map[TabNames.SPEEDDIALER].icon,
    icon_ios: common_components_map[TabNames.SPEEDDIALER].icon_ios,
  },

  [TabNames.VALIDATE_TICKET]: {
    icon: common_components_map[TabNames.VALIDATE_TICKET].icon,
    icon_ios: common_components_map[TabNames.VALIDATE_TICKET].icon_ios,
  },

  [TabNames.VIDEO]: {
    icon: common_components_map[TabNames.VIDEO].icon,
    icon_ios: common_components_map[TabNames.VIDEO].icon_ios,
  },

  [TabNames.VIDEOAUDIO]: {
    icon: common_components_map[TabNames.VIDEOAUDIO].icon,
    icon_ios: common_components_map[TabNames.VIDEOAUDIO].icon_ios,
  },

  [TabNames.WEBVIEW]: {
    icon: common_components_map[TabNames.WEBVIEW].icon,
    icon_ios: common_components_map[TabNames.WEBVIEW].icon_ios,
  },

  [TabNames.PACKAGE]: {
    icon: common_components_map[TabNames.EMPTY].icon,
    icon_ios: common_components_map[TabNames.EMPTY].icon_ios,
  },

  [TabNames.BUNDLE]: {
    icon: common_components_map[TabNames.EMPTY].icon,
    icon_ios: common_components_map[TabNames.EMPTY].icon_ios,
  },
};

export const splash_album_map = {
  [SPLASH_STYLE.STYLE_01]: {
    albums: [
      {
        id: 1,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash81.webp",
        padding: 0,
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: false,
        emptyImage: "",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_02]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash12.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash25.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash25.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_03]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash12.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash50.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: "3px",
        opacity: 0,
        blur: "20px",
        round: "rounded-top-4",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: "0",
        opacity: 0,
        blur: "20px",
        round: "rounded-0",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "30px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_04]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash10.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash33.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash19.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
        round: "rounded-top-4",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_05]: {
    albums: [
      {
        id: 1,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash50.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash27.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash23.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_06]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash8.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash31.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "5px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash23.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
  [SPLASH_STYLE.STYLE_07]: {
    albums: [
      {
        id: 1,
        en: true,
        emptyImage: "./assets/images/mobile/splash12.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 2,
        en: true,
        emptyImage: "./assets/images/mobile/splash27.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 3,
        en: true,
        emptyImage: "./assets/images/mobile/splash38.webp",
        padding: "3px",
        opacity: 0,
        blur: "0px",
        round: "rounded-0",
      },
      {
        id: 4,
        en: true,
        emptyImage: "./assets/images/mobile/splash23.webp",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
      {
        id: 5,
        en: false,
        emptyImage: "",
        padding: "0",
        opacity: 0,
        blur: "0px",
      },
    ],
  },
};



export const album_input_map = {
  [MENU_BUTTON_STYLE?.STYLE_01]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash8.webp",
          2: "./assets/images/mobile-2/splash8.webp",
          3: "./assets/images/mobile-3/splash8.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-2",
        border: "border border-2 rounded-2",
        border_s: "border border-1 rounded-1",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash10.webp",
      2: "./assets/images/mobile-2/splash10.webp",
      3: "./assets/images/mobile-3/splash10.webp",
    }, //100
    settings: { titleSize: "14px", round: "rounded-2", round_s: "rounded-1" },
  },

  [MENU_BUTTON_STYLE?.STYLE_02]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash8.webp",
          2: "./assets/images/mobile-2/splash8.webp",
          3: "./assets/images/mobile-3/splash8.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-2",
        border: "border border-2 rounded-2",
        border_s: "border border-1 rounded-1",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash8.webp",
      2: "./assets/images/mobile-2/splash8.webp",
      3: "./assets/images/mobile-3/splash8.webp",
    },
    settings: { titleSize: "14px", round: "rounded-2", round_s: "rounded-1" },
  },

  [MENU_BUTTON_STYLE?.STYLE_03]: {
    albums: [
      {
        id: 0,
        en: true,
        emptyImage: {
          1: "./assets/images/mobile/splash8.webp",
          2: "./assets/images/mobile-2/splash8.webp",
          3: "./assets/images/mobile-3/splash8.webp",
        },
        padding: 0,
        opacity: 0,
        blur: "0px",
        round: "rounded-2",
        border: "",
        border_s: "",
      },
    ],
    emptyFullImage: {
      1: "./assets/images/mobile/splash8.webp",
      2: "./assets/images/mobile-2/splash8.webp",
      3: "./assets/images/mobile-3/splash8.webp",
    }, //100
    settings: {
      titleSize: "14px",
      round: "rounded-top-2",
      round_s: "rounded-top-1",
    },
  },
};




export const AndroidAPILevel = {
  "14": "Ice Cream Sandwich",
  "15": "Ice Cream Sandwich",
  "16": "Jelly Bean",
  "17": "Jelly Bean",
  "18": "Jelly Bean",
  "19": "Kitkat",
  "20": "Kitkat Watch",
  "21": "Lollipop",
  "22": "Lollipop",
  "23": "Marshmallow",
  "24": "Nougat",
  "25": "Nougat",
  "26": "Oreo",
  "27": "Oreo",
  "28": "Pie",
  "29": "Android 10",
  "30": "Android 11",
  "31": "Android 12",
  "32": "Android 12",
  "33": "Android 13",
  "34": "Android 14",
  "14+": "Ice Cream Sandwich",
  "15+": "Ice Cream Sandwich",
  "16+": "Jelly Bean",
  "17+": "Jelly Bean",
  "18+": "Jelly Bean",
  "19+": "Kitkat",
  "20+": "Kitkat Watch",
  "21+": "Lollipop",
  "22+": "Lollipop",
  "23+": "Marshmallow",
  "24+": "Nougat",
  "25+": "Nougat",
  "26+": "Oreo",
  "27+": "Oreo",
  "28+": "Pie",
  "29+": "Android 10",
  "30+": "Android 11",
  "31+": "Android 12",
  "32+": "Android 12",
  "33+": "Android 13",
  "34+": "Android 14",
};


export const material_icons = [
  {
    name: "Account Balance",
    id: "account_balance",
    cat: "Business",
  },
  {
    name: "Account Balance Wallet",
    id: "account_balance_wallet",
    cat: "Business",
  },
  {
    name: "Account Circle",
    id: "account_circle",
    cat: "Common Actions",
  },
  {
    name: "Add",
    id: "add",
    cat: "UI actions",
  },
  {
    name: "Add Card",
    id: "add_card",
    cat: "Business",
  },
  {
    name: "Airlines",
    id: "airlines",
    cat: "Transportation",
  },
  {
    name: "Airplane Ticket",
    id: "airplane_ticket",
    cat: "Travel",
  },
  {
    name: "Album",
    id: "album",
    cat: "Audio & Video",
  },
  {
    name: "Apartment",
    id: "apartment",
    cat: "Travel",
  },
  {
    name: "Apps",
    id: "apps",
    cat: "UI actions",
  },
  {
    name: "Arrow Right",
    id: "arrow_right",
    cat: "UI actions",
  },
  {
    name: "Article",
    id: "article",
    cat: "Text Formatting",
  },
  {
    name: "Assignment",
    id: "assignment",
    cat: "Text Formatting",
  },
  {
    name: "Assured Workload",
    id: "assured_workload",
    cat: "Privacy & Security",
  },
  {
    name: "Attach Money",
    id: "attach_money",
    cat: "Business",
  },
  {
    name: "Auto Stories",
    id: "auto_stories",
    cat: "Photo & Image",
  },
  {
    name: "Badge",
    id: "badge",
    cat: "Privacy & Security",
  },
  {
    name: "Beach Access",
    id: "beach_access",
    cat: "Travel",
  },
  {
    name: "Bed",
    id: "bed",
    cat: "Household",
  },
  {
    name: "Bloodtype",
    id: "bloodtype",
    cat: "Social",
  },
  {
    name: "Book",
    id: "book",
    cat: "Text Formatting",
  },
  {
    name: "Bookmarks",
    id: "bookmarks",
    cat: "Common Actions",
  },
  {
    name: "Build",
    id: "build",
    cat: "Common Actions",
  },
  {
    name: "Calculate",
    id: "calculate",
    cat: "Business",
  },
  {
    name: "Calendar month",
    id: "calendar_month",
    cat: "Common Actions",
  },
  {
    name: "Call",
    id: "call",
    cat: "Communication",
  },
  {
    name: "Call Made",
    id: "call_made",
    cat: "Communication",
  },
  {
    name: "Camera",
    id: "camera",
    cat: "Photo & Image",
  },
  {
    name: "Campaign",
    id: "campaign",
    cat: "Activities",
  },
  {
    name: "Car Rental",
    id: "car_rental",
    cat: "Travel",
  },
  {
    name: "Car Repair",
    id: "car_repair",
    cat: "Travel",
  },
  {
    name: "Card Membership",
    id: "card_membership",
    cat: "Business",
  },
  {
    name: "Carpenter",
    id: "carpenter",
    cat: "Travel",
  },
  {
    name: "Category",
    id: "category",
    cat: "Common Actions",
  },
  {
    name: "Chair",
    id: "chair",
    cat: "Household",
  },
  {
    name: "Chalet",
    id: "chalet",
    cat: "Travel",
  },
  {
    name: "Chat",
    id: "chat",
    cat: "Communication",
  },
  {
    name: "Chat Bubble",
    id: "chat_bubble",
    cat: "Communication",
  },
  {
    name: "Check Circle",
    id: "check_circle",
    cat: "Travel",
  },
  {
    name: "Checkroom",
    id: "checkroom",
    cat: "Household",
  },
  {
    name: "Church",
    id: "church",
    cat: "Maps",
  },
  {
    name: "Circle",
    id: "circle",
    cat: "Photo & Image",
  },
  {
    name: "Cleaning Services",
    id: "cleaning_services",
    cat: "Maps",
  },
  {
    name: "Clear All",
    id: "clear_all",
    cat: "UI actions",
  },
  {
    name: "Coffee",
    id: "coffee",
    cat: "Household",
  },
  {
    name: "Comment",
    id: "comment",
    cat: "Communication",
  },
  {
    name: "Confirmation Number",
    id: "confirmation_number",
    cat: "Activities",
  },
  {
    name: "Construction",
    id: "construction",
    cat: "Activities",
  },
  {
    name: "Contact Page",
    id: "contact_page",
    cat: "Text Formatting",
  },
  {
    name: "Contacts",
    id: "contacts",
    cat: "Communication",
  },
  {
    name: "Credit Card",
    id: "credit_card",
    cat: "Business",
  },
  {
    name: "Dashboard",
    id: "dashboard",
    cat: "Text Formatting",
  },
  {
    name: "Dataset",
    id: "dataset",
    cat: "UI actions",
  },
  {
    name: "Dehaze",
    id: "dehaze",
    cat: "Photo & Image",
  },
  {
    name: "Diamond",
    id: "diamond",
    cat: "Social",
  },
  {
    name: "Dining",
    id: "dining",
    cat: "Household",
  },
  {
    name: "Directions Bus",
    id: "directions_bus",
    cat: "Transportation",
  },
  {
    name: "Diversity 1",
    id: "diversity_1",
    cat: "Social",
  },
  {
    name: "Diversity 3",
    id: "diversity_3",
    cat: "Social",
  },
  {
    name: "Domain",
    id: "domain",
    cat: "Business",
  },
  {
    name: "Done",
    id: "done",
    cat: "Travel",
  },
  {
    name: "Doorbell",
    id: "doorbell",
    cat: "Household",
  },
  {
    name: "Double Arrow",
    id: "double_arrow",
    cat: "UI actions",
  },
  {
    name: "Downhill Skiing",
    id: "downhill_skiing",
    cat: "Activities",
  },
  {
    name: "Drag Indicator",
    id: "drag_indicator",
    cat: "UI actions",
  },
  {
    name: "East",
    id: "east",
    cat: "Maps",
  },
  {
    name: "Eco",
    id: "eco",
    cat: "Social",
  },
  {
    name: "Electric Bolt",
    id: "electric_bolt",
    cat: "Household",
  },
  {
    name: "Electrical services",
    id: "electrical_services",
    cat: "Maps",
  },
  {
    name: "Emergency",
    id: "emergency",
    cat: "Maps",
  },
  {
    name: "Emoji Events",
    id: "emoji_events",
    cat: "Social",
  },
  {
    name: "Emoji Objects",
    id: "emoji_objects",
    cat: "Social",
  },
  {
    name: "Emoji Transportation",
    id: "emoji_transportation",
    cat: "Social",
  },
  {
    name: "Equalizer",
    id: "equalizer",
    cat: "Audio & Video",
  },
  {
    name: "Event",
    id: "event",
    cat: "Common Actions",
  },
  {
    name: "Event Available",
    id: "event_available",
    cat: "Common Actions",
  },
  {
    name: "Explore",
    id: "explore",
    cat: "Maps",
  },
  {
    name: "Fastfood",
    id: "fastfood",
    cat: "Maps",
  },
  {
    name: "Favorite",
    id: "favorite",
    cat: "UI actions",
  },
  {
    name: "Feed",
    id: "feed",
    cat: "Text Formatting",
  },
  {
    name: "Fitness Center",
    id: "fitness_center",
    cat: "Travel",
  },
  {
    name: "Flag",
    id: "flag",
    cat: "Maps",
  },
  {
    name: "Flatware",
    id: "flatware",
    cat: "Household",
  },
  {
    name: "Flight",
    id: "flight",
    cat: "Transportation",
  },
  {
    name: "Format List Bulleted",
    id: "format_list_bulleted",
    cat: "Text Formatting",
  },
  {
    name: "Forum",
    id: "forum",
    cat: "Communication",
  },
  {
    name: "Fullscreen",
    id: "fullscreen",
    cat: "UI actions",
  },
  {
    name: "Gite",
    id: "gite",
    cat: "Travel",
  },
  {
    name: "Grade",
    id: "grade",
    cat: "UI actions",
  },
  {
    name: "Grain",
    id: "grain",
    cat: "Photo & Image",
  },
  {
    name: "Grass",
    id: "grass",
    cat: "Household",
  },
  {
    name: "Grid View",
    id: "grid_view",
    cat: "Text Formatting",
  },
  {
    name: "Group",
    id: "group",
    cat: "Social",
  },
  {
    name: "Group Add",
    id: "group_add",
    cat: "Social",
  },
  {
    name: "Groups",
    id: "groups",
    cat: "Social",
  },
  {
    name: "Handshake",
    id: "handshake",
    cat: "Social",
  },
  {
    name: "Handyman",
    id: "handyman",
    cat: "Maps",
  },
  {
    name: "Hdr Strong",
    id: "hdr_strong",
    cat: "Photo & Image",
  },
  {
    name: "Headphones",
    id: "headphones",
    cat: "Hardware",
  },
  {
    name: "Health And Safety",
    id: "health_and_safety",
    cat: "Social",
  },
  {
    name: "Help",
    id: "help",
    cat: "Common Actions",
  },
  {
    name: "Help Center",
    id: "help_center",
    cat: "Common Actions",
  },
  {
    name: "Hexagon",
    id: "hexagon",
    cat: "Text Formatting",
  },
  {
    name: "Hiking",
    id: "hiking",
    cat: "Activities",
  },
  {
    name: "Hive",
    id: "hive",
    cat: "Social",
  },
  {
    name: "Home",
    id: "home",
    cat: "Travel",
  },
  {
    name: "Home Repair Service",
    id: "home_repair_service",
    cat: "Maps",
  },
  {
    name: "Home Work",
    id: "home_work",
    cat: "Maps",
  },
  {
    name: "Hotel",
    id: "hotel",
    cat: "Travel",
  },
  {
    name: "House",
    id: "house",
    cat: "Household",
  },
  {
    name: "House Siding",
    id: "house_siding",
    cat: "Household",
  },
  {
    name: "Import Contacts",
    id: "import_contacts",
    cat: "Communication",
  },
  {
    name: "Info",
    id: "info",
    cat: "Common Actions",
  },
  {
    name: "Interests",
    id: "interests",
    cat: "Common Actions",
  },
  {
    name: "Keyboard Arrow Down",
    id: "keyboard_arrow_down",
    cat: "Hardware",
  },
  {
    name: "Keyboard Arrow Left",
    id: "keyboard_arrow_left",
    cat: "Hardware",
  },
  {
    name: "Keyboard Arrow Right",
    id: "keyboard_arrow_right",
    cat: "Hardware",
  },
  {
    name: "Keyboard Arrow Up",
    id: "keyboard_arrow_up",
    cat: "Hardware",
  },
  {
    name: "Keyboard Backspace",
    id: "keyboard_backspace",
    cat: "Hardware",
  },
  {
    name: "Landscape",
    id: "landscape",
    cat: "Photo & Image",
  },
  {
    name: "Lens Blur",
    id: "lens_blur",
    cat: "Photo & Image",
  },
  {
    name: "Library Books",
    id: "library_books",
    cat: "Audio & Video",
  },
  {
    name: "Library Music",
    id: "library_music",
    cat: "Audio & Video",
  },
  {
    name: "Light",
    id: "light",
    cat: "Household",
  },
  {
    name: "List",
    id: "list",
    cat: "Text Formatting",
  },
  {
    name: "Live Help",
    id: "live_help",
    cat: "Communication",
  },
  {
    name: "Local Activity",
    id: "local_activity",
    cat: "Maps",
  },
  {
    name: "Local Cafe",
    id: "local_cafe",
    cat: "Travel",
  },
  {
    name: "Local Car wash",
    id: "local_car_wash",
    cat: "Maps",
  },
  {
    name: "Local Library",
    id: "local_library",
    cat: "Maps",
  },
  {
    name: "Local Mall",
    id: "local_mall",
    cat: "Maps",
  },
  {
    name: "Local Shipping",
    id: "local_shipping",
    cat: "Text Formatting",
  },
  {
    name: "Local Taxi",
    id: "local_taxi",
    cat: "Transportation",
  },
  {
    name: "Location City",
    id: "location_city",
    cat: "Travel",
  },
  {
    name: "Loyalty",
    id: "loyalty",
    cat: "Business",
  },
  {
    name: "Luggage",
    id: "luggage",
    cat: "Travel",
  },
  {
    name: "Lunch Dining",
    id: "lunch_dining",
    cat: "Travel",
  },
  {
    name: "Medical Information",
    id: "medical_information",
    cat: "Social",
  },
  {
    name: "Medical Services",
    id: "medical_services",
    cat: "Maps",
  },
  {
    name: "Medication",
    id: "medication",
    cat: "Social",
  },
  {
    name: "Menu",
    id: "menu",
    cat: "Travel",
  },
  {
    name: "Menu Book",
    id: "menu_book",
    cat: "Text Formatting",
  },
  {
    name: "Mode Comment",
    id: "mode_comment",
    cat: "Communication",
  },
  {
    name: "Mood",
    id: "mood",
    cat: "Social",
  },
  {
    name: "More Vert",
    id: "more_vert",
    cat: "UI actions",
  },
  {
    name: "Mosque",
    id: "mosque",
    cat: "Maps",
  },
  {
    name: "movie",
    id: "movie",
    cat: "Audio & Video",
  },
  {
    name: "Music Note",
    id: "music_note",
    cat: "Audio & Video",
  },
  {
    name: "Music Video",
    id: "music_video",
    cat: "Audio & Video",
  },
  {
    name: "Navigate Before",
    id: "navigate_before",
    cat: "Photo & Image",
  },
  {
    name: "Navigate Next",
    id: "navigate_next",
    cat: "Photo & Image",
  },
  {
    name: "Navigation",
    id: "navigation",
    cat: "Maps",
  },
  {
    name: "Near Me",
    id: "near_me",
    cat: "Maps",
  },
  {
    name: "Newspaper",
    id: "newspaper",
    cat: "Text Formatting",
  },
  {
    name: "North",
    id: "north",
    cat: "Maps",
  },
  {
    name: "North East",
    id: "north_east",
    cat: "Maps",
  },
  {
    name: "North West",
    id: "north_west",
    cat: "Maps",
  },
  {
    name: "Notes",
    id: "notes",
    cat: "Text Formatting",
  },
  {
    name: "Notifications Active",
    id: "notifications_active",
    cat: "Communication",
  },
  {
    name: "Paid",
    id: "paid",
    cat: "Business",
  },
  {
    name: "Payments",
    id: "payments",
    cat: "Business",
  },
  {
    name: "Pedal Bike",
    id: "pedal_bike",
    cat: "Transportation",
  },
  {
    name: "Pentagon",
    id: "pentagon",
    cat: "Text Formatting",
  },
  {
    name: "Person",
    id: "person",
    cat: "Social",
  },
  {
    name: "Person Add",
    id: "person_add",
    cat: "Social",
  },
  {
    name: "Person Search",
    id: "person_search",
    cat: "Communication",
  },
  {
    name: "Pest Control",
    id: "pest_control",
    cat: "Maps",
  },
  {
    name: "Pets",
    id: "pets",
    cat: "Social",
  },
  {
    name: "Phone In Talk",
    id: "phone_in_talk",
    cat: "Communication",
  },
  {
    name: "Piano",
    id: "piano",
    cat: "Activities",
  },
  {
    name: "Play Arrow",
    id: "play_arrow",
    cat: "Audio & Video",
  },
  {
    name: "Play Circle",
    id: "play_circle",
    cat: "Audio & Video",
  },
  {
    name: "Playlist Add",
    id: "playlist_add",
    cat: "Audio & Video",
  },
  {
    name: "Playlist Add Check",
    id: "playlist_add_check",
    cat: "Audio & Video",
  },
  {
    name: "Plumbing",
    id: "plumbing",
    cat: "Maps",
  },
  {
    name: "Podcasts",
    id: "podcasts",
    cat: "Audio & Video",
  },
  {
    name: "Policy",
    id: "policy",
    cat: "Privacy & Security",
  },
  {
    name: "Privacy Tip",
    id: "privacy_tip",
    cat: "Privacy & Security",
  },
  {
    name: "Psychology",
    id: "psychology",
    cat: "Social",
  },
  {
    name: "Public",
    id: "public",
    cat: "Social",
  },
  {
    name: "QR Code",
    id: "qr_code",
    cat: "Business",
  },
  {
    name: "QR Code 2",
    id: "qr_code_2",
    cat: "Business",
  },
  {
    name: "QR Code Scanner",
    id: "qr_code_scanner",
    cat: "Business",
  },
  {
    name: "Question Mark",
    id: "question_mark",
    cat: "Common Actions",
  },
  {
    name: "Quiz",
    id: "quiz",
    cat: "Social",
  },
  {
    name: "Radio",
    id: "radio",
    cat: "Audio & Video",
  },
  {
    name: "Real Estate Agent",
    id: "real_estate_agent",
    cat: "Activities",
  },
  {
    name: "Receipt",
    id: "receipt",
    cat: "Business",
  },
  {
    name: "Rectangle",
    id: "rectangle",
    cat: "Text Formatting",
  },
  {
    name: "Report",
    id: "report",
    cat: "Privacy & Security",
  },
  {
    name: "Restaurant",
    id: "restaurant",
    cat: "Maps",
  },
  {
    name: "Restaurant Menu",
    id: "restaurant_menu",
    cat: "Maps",
  },
  {
    name: "Reviews",
    id: "reviews",
    cat: "Communication",
  },
  {
    name: "Rocket Launch",
    id: "rocket_launch",
    cat: "Social",
  },
  {
    name: "Roofing",
    id: "roofing",
    cat: "Household",
  },
  {
    name: "RSS Feed",
    id: "rss_feed",
    cat: "Android",
  },
  {
    name: "Saved Search",
    id: "saved_search",
    cat: "UI actions",
  },
  {
    name: "Schedule",
    id: "schedule",
    cat: "Common Actions",
  },
  {
    name: "School",
    id: "school",
    cat: "Activities",
  },
  {
    name: "Science",
    id: "science",
    cat: "Activities",
  },
  {
    name: "Search",
    id: "search",
    cat: "Travel",
  },
  {
    name: "Security",
    id: "security",
    cat: "Privacy & Security",
  },
  {
    name: "Self Improvement",
    id: "self_improvement",
    cat: "Activities",
  },
  {
    name: "Sell",
    id: "sell",
    cat: "Business",
  },
  {
    name: "Send",
    id: "send",
    cat: "Communication",
  },
  {
    name: "Settings",
    id: "settings",
    cat: "Travel",
  },
  {
    name: "Settings Phone",
    id: "settings_phone",
    cat: "Communication",
  },
  {
    name: "Shield",
    id: "shield",
    cat: "Privacy & Security",
  },
  {
    name: "Shopping Bag",
    id: "shopping_bag",
    cat: "Business",
  },
  {
    name: "Shopping Basket",
    id: "shopping_basket",
    cat: "Business",
  },
  {
    name: "Shopping Cart",
    id: "shopping_cart",
    cat: "Business",
  },
  {
    name: "Sms",
    id: "sms",
    cat: "Communication",
  },
  {
    name: "Snowboarding",
    id: "snowboarding",
    cat: "Activities",
  },
  {
    name: "Snowing",
    id: "snowing",
    cat: "Social",
  },
  {
    name: "South",
    id: "south",
    cat: "Maps",
  },
  {
    name: "South East",
    id: "south_east",
    cat: "Maps",
  },
  {
    name: "South West",
    id: "south_west",
    cat: "Maps",
  },
  {
    name: "Spa",
    id: "spa",
    cat: "Travel",
  },
  {
    name: "Sports Baseball",
    id: "sports_baseball",
    cat: "Activities",
  },
  {
    name: "Sports Basketball",
    id: "sports_basketball",
    cat: "Activities",
  },
  {
    name: "Sports Cricket",
    id: "sports_cricket",
    cat: "Activities",
  },
  {
    name: "Sports Gymnastics",
    id: "sports_gymnastics",
    cat: "Activities",
  },
  {
    name: "Sports Martial Arts",
    id: "sports_martial_arts",
    cat: "Activities",
  },
  {
    name: "Sports Soccer",
    id: "sports_soccer",
    cat: "Activities",
  },
  {
    name: "Sports Tennis",
    id: "sports_tennis",
    cat: "Activities",
  },
  {
    name: "Sports Volleyball",
    id: "sports_volleyball",
    cat: "Activities",
  },
  {
    name: "Star Half",
    id: "star_half",
    cat: "UI actions",
  },
  {
    name: "Star Rate",
    id: "star_rate",
    cat: "UI actions",
  },
  {
    name: "Stars",
    id: "stars",
    cat: "Common Actions",
  },
  {
    name: "Store",
    id: "store",
    cat: "Business",
  },
  {
    name: "Storefront",
    id: "storefront",
    cat: "Business",
  },
  {
    name: "Straight",
    id: "straight",
    cat: "Hardware",
  },
  {
    name: "subscriptions",
    id: "subscriptions",
    cat: "Audio & Video",
  },
  {
    name: "Subtitles",
    id: "subtitles",
    cat: "Audio & Video",
  },
  {
    name: "Subway",
    id: "subway",
    cat: "Transportation",
  },
  {
    name: "Sunny",
    id: "sunny",
    cat: "Social",
  },
  {
    name: "Support Agent",
    id: "support_agent",
    cat: "Social",
  },
  {
    name: "Surfing",
    id: "surfing",
    cat: "Activities",
  },
  {
    name: "Table Restaurant",
    id: "table_restaurant",
    cat: "Household",
  },
  {
    name: "Temple Hindu",
    id: "temple_hindu",
    cat: "Maps",
  },
  {
    name: "Theaters",
    id: "theaters",
    cat: "Activities",
  },
  {
    name: "Thumb Up",
    id: "thumb_up",
    cat: "Social",
  },
  {
    name: "Thumbs Up down",
    id: "thumbs_up_down",
    cat: "Social",
  },
  {
    name: "Train",
    id: "train",
    cat: "Transportation",
  },
  {
    name: "Travel Explore",
    id: "travel_explore",
    cat: "Social",
  },
  {
    name: "Trending Flat",
    id: "trending_flat",
    cat: "Business",
  },
  {
    name: "Tty",
    id: "tty",
    cat: "Hardware",
  },
  {
    name: "Vaccines",
    id: "vaccines",
    cat: "Social",
  },
  {
    name: "Vaping Rooms",
    id: "vaping_rooms",
    cat: "Social",
  },
  {
    name: "Verified User",
    id: "verified_user",
    cat: "Privacy & Security",
  },
  {
    name: "Vertical Split",
    id: "vertical_split",
    cat: "Text Formatting",
  },
  {
    name: "Video Call",
    id: "video_call",
    cat: "Audio & Video",
  },
  {
    name: "Video Camera Front",
    id: "video_camera_front",
    cat: "Audio & Video",
  },
  {
    name: "Video Library",
    id: "video_library",
    cat: "Audio & Video",
  },
  {
    name: "Videocam",
    id: "videocam",
    cat: "Audio & Video",
  },
  {
    name: "View Agenda",
    id: "view_agenda",
    cat: "Text Formatting",
  },
  {
    name: "View Comfy Alt",
    id: "view_comfy_alt",
    cat: "UI actions",
  },
  {
    name: "View Cozy",
    id: "view_cozy",
    cat: "UI actions",
  },
  {
    name: "View Headline",
    id: "view_headline",
    cat: "Text Formatting",
  },
  {
    name: "View Timeline",
    id: "view_timeline",
    cat: "UI actions",
  },
  {
    name: "Warning",
    id: "warning",
    cat: "Common Actions",
  },
  {
    name: "Water Drop",
    id: "water_drop",
    cat: "Social",
  },
  {
    name: "Web",
    id: "web",
    cat: "Common Actions",
  },
  {
    name: "West",
    id: "west",
    cat: "Maps",
  },
  {
    name: "Wheelchair Pickup",
    id: "wheelchair_pickup",
    cat: "Travel",
  },
  {
    name: "Work",
    id: "work",
    cat: "Business",
  },
  {
    name: "Workspace Premium",
    id: "workspace_premium",
    cat: "Social",
  },
  {
    name: "Workspaces",
    id: "workspaces",
    cat: "Social",
  },
  {
    name: "Psychology Alt",
    id: "psychology_alt",
    cat: "Social",
  },
  {
    name: "Waving Hand",
    id: "waving_hand",
    cat: "Social",
  },
  {
    name: "Back Hand",
    id: "back_hand",
    cat: "Social",
  },
  {
    name: "Front Hand",
    id: "front_hand",
    cat: "Social",
  },
  {
    name: "Personal Injury",
    id: "personal_injury",
    cat: "Social",
  },
  {
    name: "Military Tech",
    id: "military_tech",
    cat: "Social",
  },
  {
    name: "Pages",
    id: "pages",
    cat: "Social",
  },
  {
    name: "History Edu",
    id: "history_edu",
    cat: "Social",
  },
  {
    name: "Sign Language",
    id: "sign_language",
    cat: "Social",
  },
  {
    name: "Sports Motorsports",
    id: "sports_motorsports",
    cat: "Social",
  },
  {
    name: "Outdoor Grill",
    id: "outdoor_grill",
    cat: "Social",
  },
  {
    name: "Masks",
    id: "masks",
    cat: "Social",
  },
  {
    name: "Groups 3",
    id: "groups_3",
    cat: "Social",
  },
  {
    name: "Notifications",
    id: "notifications",
    cat: "Social",
  },
  {
    name: "Engineering",
    id: "engineering",
    cat: "Social",
  },
  {
    name: "Man",
    id: "man",
    cat: "Social",
  },
  {
    name: "Woman",
    id: "woman",
    cat: "Social",
  },
  {
    name: "Text Snippet",
    id: "text_snippet",
    cat: "File",
  },
  {
    name: "Ballot",
    id: "ballot",
    cat: "Content",
  },
  {
    name: "Biotech",
    id: "biotech",
    cat: "Content",
  },
  {
    name: "Inventory",
    id: "inventory",
    cat: "Content",
  },
  {
    name: "Inventory 2",
    id: "inventory_2",
    cat: "Content",
  },
  {
    name: "Waves",
    id: "waves",
    cat: "Content",
  },
  {
    name: "Iron",
    id: "iron",
    cat: "Places",
  },
  {
    name: "Holiday Village",
    id: "holiday_village",
    cat: "Places",
  },
  {
    name: "AC Unit",
    id: "ac_unit",
    cat: "Places",
  },
  {
    name: "Perm Phone Msg",
    id: "perm_phone_msg",
    cat: "Action",
  },
  {
    name: "Chrome Reader Mode",
    id: "chrome_reader_mode",
    cat: "Action",
  },
  {
    name: "Redeem",
    id: "redeem",
    cat: "Action",
  },
  {
    name: "Space Dashboard",
    id: "space_dashboard",
    cat: "Action",
  },
  {
    name: "Join Full",
    id: "join_full",
    cat: "Action",
  },
  {
    name: "Fact Check",
    id: "fact_check",
    cat: "Action",
  },
  {
    name: "Query Builder",
    id: "query_builder",
    cat: "Action",
  },
  {
    name: "Location On",
    id: "location_on",
    cat: "Communication",
  },
  {
    name: "Priority High",
    id: "priority_high",
    cat: "Notification",
  },
  {
    name: "Star",
    id: "star",
    cat: "Toggle",
  },
  {
    name: "Blur Linear",
    id: "blur_linear",
    cat: "Image",
  },
  {
    name: "Filter Center Focus",
    id: "filter_center_focus",
    cat: "Image",
  },
  {
    name: "Filter Vintage",
    id: "filter_vintage",
    cat: "Image",
  },
  {
    name: "Receipt Long",
    id: "receipt_long",
    cat: "Image",
  },
  {
    name: "Blur On",
    id: "blur_on",
    cat: "Image",
  },
  {
    name: "Auto Awesome",
    id: "auto_awesome",
    cat: "Image",
  },
  {
    name: "Garage",
    id: "garage",
    cat: "Search",
  },
  {
    name: "Post Add",
    id: "post_add",
    cat: "Editor",
  },
  {
    name: "Monitor Heart",
    id: "monitor_heart",
    cat: "Device",
  },
  {
    name: "Splitscreen",
    id: "splitscreen",
    cat: "Device",
  },
  {
    name: "Phishing",
    id: "phishing",
    cat: "Device",
  },
  {
    name: "Medication Liquid",
    id: "medication_liquid",
    cat: "Device",
  },
  {
    name: "Local Hospital",
    id: "local_hospital",
    cat: "Maps",
  },
  {
    name: "Local Pharmacy",
    id: "local_pharmacy",
    cat: "Maps",
  },
  {
    name: "Emergency Share",
    id: "emergency_share",
    cat: "Maps",
  },
  {
    name: "Map",
    id: "map",
    cat: "Maps",
  },
  {
    name: "Local Florist",
    id: "local_florist",
    cat: "Maps",
  },
  {
    name: "Two Wheeler",
    id: "two_wheeler",
    cat: "Maps",
  },
  {
    name: "Temple Buddhist",
    id: "temple_buddhist",
    cat: "Maps",
  },
  {
    name: "Dry Cleaning",
    id: "dry_cleaning",
    cat: "Maps",
  },
  {
    name: "Stadium",
    id: "stadium",
    cat: "Maps",
  },
  {
    name: "Celebration",
    id: "celebration",
    cat: "Maps",
  },
  {
    name: "Warehouse",
    id: "warehouse",
    cat: "Maps",
  },
];

export const material_icons_cat = [
  "Action",
  "Activities",
  "Android",
  "Audio & Video",
  "Business",
  "Common Actions",
  "Communication",
  "Content",
  "Device",
  "Editor",
  "File",
  "Hardware",
  "Household",
  "Image",
  "Maps",
  "Notification",
  "Photo & Image",
  "Places",
  "Privacy & Security",
  "Search",
  "Social",
  "Text Formatting",
  "Toggle",
  "Transportation",
  "Travel",
  "UI actions",
];

export enum LOGIN_TYPES {
  MSISDN = "MSISDN",
  EMAIL = "EMAIL",
  NO_LOGIN = "NO_LOGIN",
  FACEBOOK = "Facebook",
  LINKEDIN = "Linkedin",
  TWITTER = "Twitter",
}

export const EMAIL_VERIFICATION_MESSAGE = `
<h4 style="text-align:center;">$app_name - Verification Code</h4>
<h2 style="text-align:center;">$code</h2>
<p>Thank you for signing up for $app_name. Please enter this verification code in the app to complete the signup process. If you received this code in error, please ignore this message.</p>
`;

export const SMS_VERIFICATION_MESSAGE = `<#> $app_name code: $code Please use the code above for registration`;

export enum VendorsTypes {
  SMS = "sms_http",
  WHATSAPP = "whatsapp",
  VIBER = "viber",
}
export enum VendorsNames {
  CLICKATELL = "clickatell",
  VONAGE = "vonage",
  INFOBIP = "infobip",
}

export const mobCountries = [
  {
    isoCode2: "AF",
    isoCode3: "AFG",
    dial: "93",
    name: "Afghanistan",
  },
  {
    isoCode2: "AL",
    isoCode3: "ALB",
    dial: "355",
    name: "Albania",
  },
  {
    isoCode2: "DZ",
    isoCode3: "DZA",
    dial: "213",
    name: "Algeria",
  },
  {
    isoCode2: "AS",
    isoCode3: "ASM",
    dial: "1 - 684",
    name: "American Samoa",
  },
  {
    isoCode2: "AD",
    isoCode3: "AND",
    dial: "376",
    name: "Andorra",
  },
  {
    isoCode2: "AO",
    isoCode3: "AGO",
    dial: "244",
    name: "Angola",
  },
  {
    isoCode2: "AI",
    isoCode3: "AIA",
    dial: "1 - 264",
    name: "Anguilla",
  },
  {
    isoCode2: "AQ",
    isoCode3: "ATA",
    dial: "672",
    name: "Antarctica",
  },
  {
    isoCode2: "AG",
    isoCode3: "ATG",
    dial: "1 - 268",
    name: "Antigua & Barbuda",
  },
  {
    isoCode2: "AR",
    isoCode3: "ARG",
    dial: "54",
    name: "Argentina",
  },
  {
    isoCode2: "AM",
    isoCode3: "ARM",
    dial: "374",
    name: "Armenia",
  },
  {
    isoCode2: "AW",
    isoCode3: "ABW",
    dial: "297",
    name: "Aruba",
  },
  {
    isoCode2: "AU",
    isoCode3: "AUS",
    dial: "61",
    name: "Australia",
  },
  {
    isoCode2: "AT",
    isoCode3: "AUT",
    dial: "43",
    name: "Austria",
  },
  {
    isoCode2: "AZ",
    isoCode3: "AZE",
    dial: "994",
    name: "Azerbaijan",
  },
  {
    isoCode2: "BS",
    isoCode3: "BHS",
    dial: "1 - 242",
    name: "Bahamas",
  },
  {
    isoCode2: "BH",
    isoCode3: "BHR",
    dial: "973",
    name: "Bahrain",
  },
  {
    isoCode2: "BD",
    isoCode3: "BGD",
    dial: "880",
    name: "Bangladesh",
  },
  {
    isoCode2: "BB",
    isoCode3: "BRB",
    dial: "1 - 246",
    name: "Barbados",
  },
  {
    isoCode2: "BY",
    isoCode3: "BLR",
    dial: "375",
    name: "Belarus",
  },
  {
    isoCode2: "BE",
    isoCode3: "BEL",
    dial: "32",
    name: "Belgium",
  },
  {
    isoCode2: "BZ",
    isoCode3: "BLZ",
    dial: "501",
    name: "Belize",
  },

  {
    isoCode2: "BJ",
    isoCode3: "BEN",
    dial: "229",
    name: "Benin",
  },

  {
    isoCode2: "BM",
    isoCode3: "BMU",
    dial: "1 - 441",
    name: "Bermuda",
  },
  {
    isoCode2: "BT",
    isoCode3: "BTN",
    dial: "975",
    name: "Bhutan",
  },
  {
    isoCode2: "BO",
    isoCode3: "BOL",
    dial: "591",
    name: "Bolivia",
  },
  {
    isoCode2: "BQ",
    isoCode3: "BES",
    dial: "599",
    name: "Caribbean Netherlands",
  },
  {
    isoCode2: "BA",
    isoCode3: "BIH",
    dial: "387",
    name: "Bosnia & Herzegovina",
  },
  {
    isoCode2: "BW",
    isoCode3: "BWA",
    dial: "267",
    name: "Botswana",
  },

  {
    isoCode2: "BV",
    isoCode3: "BVT",
    dial: "47",
    name: "Bouvet Island",
  },
  {
    isoCode2: "BR",
    isoCode3: "BRA",
    dial: "55",
    name: "Brazil",
  },
  {
    isoCode2: "IO",
    isoCode3: "IOT",
    dial: "246",
    name: "British Indian Ocean Territory",
  },

  {
    isoCode2: "BN",
    isoCode3: "BRN",
    dial: "673",
    name: "Brunei",
  },

  {
    isoCode2: "BG",
    isoCode3: "BGR",
    dial: "359",
    name: "Bulgaria",
  },
  {
    isoCode2: "BF",
    isoCode3: "BFA",
    dial: "226",
    name: "Burkina Faso",
  },
  {
    isoCode2: "BI",
    isoCode3: "BDI",
    dial: "257",
    name: "Burundi",
  },

  {
    isoCode2: "KH",
    isoCode3: "KHM",
    dial: "855",
    name: "Cambodia",
  },

  {
    isoCode2: "CM",
    isoCode3: "CMR",
    dial: "237",
    name: "Cameroon",
  },
  {
    isoCode2: "CA",
    isoCode3: "CAN",
    dial: "1",
    name: "Canada",
  },
  {
    isoCode2: "CV",
    isoCode3: "CPV",
    dial: "238",
    name: "Cape Verde",
  },
  {
    isoCode2: "KY",
    isoCode3: "CYM",
    dial: "1 - 345",
    name: "Cayman Islands",
  },
  {
    isoCode2: "CF",
    isoCode3: "CAF",
    dial: "236",
    name: "Central African Republic",
  },
  {
    isoCode2: "TD",
    isoCode3: "TCD",
    dial: "235",
    name: "Chad",
  },
  {
    isoCode2: "CL",
    isoCode3: "CHL",
    dial: "56",
    name: "Chile",
  },
  {
    isoCode2: "CN",
    isoCode3: "CHN",
    dial: "86",
    name: "China",
  },
  {
    isoCode2: "CX",
    isoCode3: "CXR",
    dial: "61",
    name: "Christmas Island",
  },
  {
    isoCode2: "CC",
    isoCode3: "CCK",
    dial: "61",
    name: "Cocos (Keeling) Islands",
  },
  {
    isoCode2: "CO",
    isoCode3: "COL",
    dial: "57",
    name: "Colombia",
  },
  {
    isoCode2: "KM",
    isoCode3: "COM",
    dial: "269",
    name: "Comoros",
  },
  {
    isoCode2: "CG",
    isoCode3: "COG",
    dial: "242",
    name: "Congo - Brazzaville",
  },
  {
    isoCode2: "CD",
    isoCode3: "COD",
    dial: "243",
    name: "Congo - Kinshasa",
  },
  {
    isoCode2: "CK",
    isoCode3: "COK",
    dial: "682",
    name: "Cook Islands",
  },
  {
    isoCode2: "CR",
    isoCode3: "CRI",
    dial: "506",
    name: "Costa Rica",
  },
  {
    isoCode2: "HR",
    isoCode3: "HRV",
    dial: "385",
    name: "Croatia",
  },
  {
    isoCode2: "CU",
    isoCode3: "CUB",
    dial: "53",
    name: "Cuba",
  },
  {
    isoCode2: "CW",
    isoCode3: "CUW",
    dial: "599",
    name: "Curaçao",
  },
  {
    isoCode2: "CY",
    isoCode3: "CYP",
    dial: "357",
    name: "Cyprus",
  },
  {
    isoCode2: "CZ",
    isoCode3: "CZE",
    dial: "420",
    name: "Czechia",
  },
  {
    isoCode2: "CI",
    isoCode3: "CIV",
    dial: "225",
    name: "Côte d’Ivoire",
  },
  {
    isoCode2: "DK",
    isoCode3: "DNK",
    dial: "45",
    name: "Denmark",
  },
  {
    isoCode2: "DJ",
    isoCode3: "DJI",
    dial: "253",
    name: "Djibouti",
  },

  {
    isoCode2: "DM",
    isoCode3: "DMA",
    dial: "1 - 767",
    name: "Dominica",
  },
  {
    isoCode2: "DO",
    isoCode3: "DOM",
    dial: "1 - 809 ",
    name: "Dominican Republic (1 - 809)",
  },
  {
    isoCode2: "DO",
    isoCode3: "DOM",
    dial: "1 -  829",
    name: "Dominican Republic (1 - 829)",
  },
  {
    isoCode2: "DO",
    isoCode3: "DOM",
    dial: "1 - 849",
    name: "Dominican Republic (1 - 849)",
  },
  {
    isoCode2: "EC",
    isoCode3: "ECU",
    dial: "593",
    name: "Ecuador",
  },

  {
    isoCode2: "EG",
    isoCode3: "EGY",
    dial: "20",
    name: "Egypt",
  },
  {
    isoCode2: "SV",
    isoCode3: "SLV",
    dial: "503",
    name: "El Salvador",
  },
  {
    isoCode2: "GQ",
    isoCode3: "GNQ",
    dial: "240",
    name: "Equatorial Guinea",
  },
  {
    isoCode2: "ER",
    isoCode3: "ERI",
    dial: "291",
    name: "Eritrea",
  },

  {
    isoCode2: "EE",
    isoCode3: "EST",
    dial: "372",
    name: "Estonia",
  },
  {
    isoCode2: "ET",
    isoCode3: "ETH",
    dial: "251",
    name: "Ethiopia",
  },
  {
    isoCode2: "FK",
    isoCode3: "FLK",
    dial: "500",
    name: "Falkland Islands (Islas Malvinas)",
  },

  {
    isoCode2: "FO",
    isoCode3: "FRO",
    dial: "298",
    name: "Faroe Islands",
  },
  {
    isoCode2: "FJ",
    isoCode3: "FJI",
    dial: "679",
    name: "Fiji",
  },
  {
    isoCode2: "FI",
    isoCode3: "FIN",
    dial: "358",
    name: "Finland",
  },
  {
    isoCode2: "FR",
    isoCode3: "FRA",
    dial: "33",
    name: "France",
  },
  {
    isoCode2: "GF",
    isoCode3: "GUF",
    dial: "594",
    name: "French Guiana",
  },
  {
    isoCode2: "PF",
    isoCode3: "PYF",
    dial: "689",
    name: "French Polynesia",
  },
  {
    isoCode2: "TF",
    isoCode3: "ATF",
    dial: "262",
    name: "French Southern Territories",
  },
  {
    isoCode2: "GA",
    isoCode3: "GAB",
    dial: "241",
    name: "Gabon",
  },
  {
    isoCode2: "GM",
    isoCode3: "GMB",
    dial: "220",
    name: "Gambia",
  },

  {
    isoCode2: "GE",
    isoCode3: "GEO",
    dial: "995",
    name: "Georgia",
  },
  {
    isoCode2: "DE",
    isoCode3: "DEU",
    dial: "49",
    name: "Germany",
  },
  {
    isoCode2: "GH",
    isoCode3: "GHA",
    dial: "233",
    name: "Ghana",
  },
  {
    isoCode2: "GI",
    isoCode3: "GIB",
    dial: "350",
    name: "Gibraltar",
  },

  {
    isoCode2: "GR",
    isoCode3: "GRC",
    dial: "30",
    name: "Greece",
  },
  {
    isoCode2: "GL",
    isoCode3: "GRL",
    dial: "299",
    name: "Greenland",
  },
  {
    isoCode2: "GD",
    isoCode3: "GRD",
    dial: "1 - 473",
    name: "Grenada",
  },
  {
    isoCode2: "GP",
    isoCode3: "GLP",
    dial: "590",
    name: "Guadeloupe",
  },
  {
    isoCode2: "GU",
    isoCode3: "GUM",
    dial: "1 - 671",
    name: "Guam",
  },
  {
    isoCode2: "GT",
    isoCode3: "GTM",
    dial: "502",
    name: "Guatemala",
  },
  {
    isoCode2: "GG",
    isoCode3: "GGY",
    dial: "44",
    name: "Guernsey",
  },
  {
    isoCode2: "GN",
    isoCode3: "GIN",
    dial: "224",
    name: "Guinea",
  },
  {
    isoCode2: "GW",
    isoCode3: "GNB",
    dial: "245",
    name: "Guinea-Bissau",
  },
  {
    isoCode2: "GY",
    isoCode3: "GUY",
    dial: "592",
    name: "Guyana",
  },
  {
    isoCode2: "HT",
    isoCode3: "HTI",
    dial: "509",
    name: "Haiti",
  },
  {
    isoCode2: "HM",
    isoCode3: "HMD",
    dial: "672",
    name: "Heard & McDonald Islands",
  },
  {
    isoCode2: "VA",
    isoCode3: "VAT",
    dial: "39 - 06",
    name: "Vatican City",
  },
  {
    isoCode2: "HN",
    isoCode3: "HND",
    dial: "504",
    name: "Honduras",
  },
  {
    isoCode2: "HK",
    isoCode3: "HKG",
    dial: "852",
    name: "Hong Kong",
  },
  {
    isoCode2: "HU",
    isoCode3: "HUN",
    dial: "36",
    name: "Hungary",
  },
  {
    isoCode2: "IS",
    isoCode3: "ISL",
    dial: "354",
    name: "Iceland",
  },
  {
    isoCode2: "IN",
    isoCode3: "IND",
    dial: "91",
    name: "India",
  },
  {
    isoCode2: "ID",
    isoCode3: "IDN",
    dial: "62",
    name: "Indonesia",
  },
  {
    isoCode2: "IR",
    isoCode3: "IRN",
    dial: "98",
    name: "Iran",
  },
  {
    isoCode2: "IQ",
    isoCode3: "IRQ",
    dial: "964",
    name: "Iraq",
  },
  {
    isoCode2: "IE",
    isoCode3: "IRL",
    dial: "353",
    name: "Ireland",
  },
  {
    isoCode2: "IM",
    isoCode3: "IMN",
    dial: "44",
    name: "Isle of Man",
  },
  {
    isoCode2: "IL",
    isoCode3: "ISR",
    dial: "972",
    name: "Israel",
  },

  {
    isoCode2: "IL",
    isoCode3: "ISR",
    dial: "972",
    name: "Israel",
  },

  {
    isoCode2: "IT",
    isoCode3: "ITA",
    dial: "39",
    name: "Italy",
  },
  {
    isoCode2: "JM",
    isoCode3: "JAM",
    dial: "1 - 876",
    name: "Jamaica",
  },
  {
    isoCode2: "JP",
    isoCode3: "JPN",
    dial: "81",
    name: "Japan",
  },
  {
    isoCode2: "JE",
    isoCode3: "JEY",
    dial: "44",
    name: "Jersey",
  },
  {
    isoCode2: "JO",
    isoCode3: "JOR",
    dial: "962",
    name: "Jordan",
  },
  {
    isoCode2: "KZ",
    isoCode3: "KAZ",
    dial: "7",
    name: "Kazakhstan",
  },
  {
    isoCode2: "KE",
    isoCode3: "KEN",
    dial: "254",
    name: "Kenya",
  },
  {
    isoCode2: "KI",
    isoCode3: "KIR",
    dial: "686",
    name: "Kiribati",
  },
  {
    isoCode2: "KP",
    isoCode3: "PRK",
    dial: "850",
    name: "North Korea",
  },

  {
    isoCode2: "KR",
    isoCode3: "KOR",
    dial: "82",
    name: "South Korea",
  },
  {
    isoCode2: "KW",
    isoCode3: "KWT",
    dial: "965",
    name: "Kuwait",
  },
  {
    isoCode2: "KG",
    isoCode3: "KGZ",
    dial: "996",
    name: "Kyrgyzstan",
  },
  {
    isoCode2: "LA",
    isoCode3: "LAO",
    dial: "856",
    name: "Laos",
  },
  {
    isoCode2: "LV",
    isoCode3: "LVA",
    dial: "371",
    name: "Latvia",
  },
  {
    isoCode2: "LB",
    isoCode3: "LBN",
    dial: "961",
    name: "Lebanon",
  },
  {
    isoCode2: "LS",
    isoCode3: "LSO",
    dial: "266",
    name: "Lesotho",
  },
  {
    isoCode2: "LR",
    isoCode3: "LBR",
    dial: "231",
    name: "Liberia",
  },
  {
    isoCode2: "LY",
    isoCode3: "LBY",
    dial: "218",
    name: "Libya",
  },
  {
    isoCode2: "LI",
    isoCode3: "LIE",
    dial: "423",
    name: "Liechtenstein",
  },
  {
    isoCode2: "LT",
    isoCode3: "LTU",
    dial: "370",
    name: "Lithuania",
  },

  {
    isoCode2: "LU",
    isoCode3: "LUX",
    dial: "352",
    name: "Luxembourg",
  },

  {
    isoCode2: "MO",
    isoCode3: "MAC",
    dial: "853",
    name: "Macau",
  },
  {
    isoCode2: "MK",
    isoCode3: "MKD",
    dial: "389",
    name: "Macedonia (FYROM)",
  },

  {
    isoCode2: "MG",
    isoCode3: "MDG",
    dial: "261",
    name: "Madagascar",
  },
  {
    isoCode2: "MW",
    isoCode3: "MWI",
    dial: "265",
    name: "Malawi",
  },
  {
    isoCode2: "MY",
    isoCode3: "MYS",
    dial: "60",
    name: "Malaysia",
  },

  {
    isoCode2: "MV",
    isoCode3: "MDV",
    dial: "960",
    name: "Maldives",
  },

  {
    isoCode2: "ML",
    isoCode3: "MLI",
    dial: "223",
    name: "Mali",
  },
  {
    isoCode2: "MT",
    isoCode3: "MLT",
    dial: "356",
    name: "Malta",
  },

  {
    isoCode2: "MH",
    isoCode3: "MHL",
    dial: "692",
    name: "Marshall Islands",
  },
  {
    isoCode2: "MQ",
    isoCode3: "MTQ",
    dial: "596",
    name: "Martinique",
  },
  {
    isoCode2: "MR",
    isoCode3: "MRT",
    dial: "222",
    name: "Mauritania",
  },
  {
    isoCode2: "MU",
    isoCode3: "MUS",
    dial: "230",
    name: "Mauritius",
  },
  {
    isoCode2: "YT",
    isoCode3: "MYT",
    dial: "262",
    name: "Mayotte",
  },

  {
    isoCode2: "MX",
    isoCode3: "MEX",
    dial: "52",
    name: "Mexico",
  },

  {
    isoCode2: "FM",
    isoCode3: "FSM",
    dial: "691",
    name: "Micronesia",
  },
  {
    isoCode2: "MD",
    isoCode3: "MDA",
    dial: "373",
    name: "Moldova",
  },
  {
    isoCode2: "MC",
    isoCode3: "MCO",
    dial: "377",
    name: "Monaco",
  },
  {
    isoCode2: "MN",
    isoCode3: "MNG",
    dial: "976",
    name: "Mongolia",
  },
  {
    isoCode2: "ME",
    isoCode3: "MNE",
    dial: "382",
    name: "Montenegro",
  },
  {
    isoCode2: "MS",
    isoCode3: "MSR",
    dial: "1 - 664",
    name: "Montserrat",
  },
  {
    isoCode2: "MA",
    isoCode3: "MAR",
    dial: "212",
    name: "Morocco",
  },
  {
    isoCode2: "MZ",
    isoCode3: "MOZ",
    dial: "258",
    name: "Mozambique",
  },
  {
    isoCode2: "MM",
    isoCode3: "MMR",
    dial: "95",
    name: "Myanmar (Burma)",
  },
  {
    isoCode2: "NA",
    isoCode3: "NAM",
    dial: "264",
    name: "Namibia",
  },
  {
    isoCode2: "NR",
    isoCode3: "NRU",
    dial: "674",
    name: "Nauru",
  },
  {
    isoCode2: "NP",
    isoCode3: "NPL",
    dial: "977",
    name: "Nepal",
  },
  {
    isoCode2: "NL",
    isoCode3: "NLD",
    dial: "31",
    name: "Netherlands",
  },
  {
    isoCode2: "NC",
    isoCode3: "NCL",
    dial: "687",
    name: "New Caledonia",
  },
  {
    isoCode2: "NZ",
    isoCode3: "NZL",
    dial: "64",
    name: "New Zealand",
  },
  {
    isoCode2: "NI",
    isoCode3: "NIC",
    dial: "505",
    name: "Nicaragua",
  },
  {
    isoCode2: "NE",
    isoCode3: "NER",
    dial: "227",
    name: "Niger",
  },
  {
    isoCode2: "NG",
    isoCode3: "NGA",
    dial: "234",
    name: "Nigeria",
  },
  {
    isoCode2: "NU",
    isoCode3: "NIU",
    dial: "683",
    name: "Niue",
  },
  {
    isoCode2: "NF",
    isoCode3: "NFK",
    dial: "672",
    name: "Norfolk Island",
  },
  {
    isoCode2: "MP",
    isoCode3: "MNP",
    dial: "1 - 670",
    name: "Northern Mariana Islands",
  },
  {
    isoCode2: "NO",
    isoCode3: "NOR",
    dial: "47",
    name: "Norway",
  },
  {
    isoCode2: "OM",
    isoCode3: "OMN",
    dial: "968",
    name: "Oman",
  },
  {
    isoCode2: "PK",
    isoCode3: "PAK",
    dial: "92",
    name: "Pakistan",
  },
  {
    isoCode2: "PW",
    isoCode3: "PLW",
    dial: "680",
    name: "Palau",
  },
  {
    isoCode2: "PS",
    isoCode3: "PSE",
    dial: "970",
    name: "Palestine",
  },
  {
    isoCode2: "PA",
    isoCode3: "PAN",
    dial: "507",
    name: "Panama",
  },

  {
    isoCode2: "PG",
    isoCode3: "PNG",
    dial: "675",
    name: "Papua New Guinea",
  },
  {
    isoCode2: "PY",
    isoCode3: "PRY",
    dial: "595",
    name: "Paraguay",
  },
  {
    isoCode2: "PE",
    isoCode3: "PER",
    dial: "51",
    name: "Peru",
  },
  {
    isoCode2: "PH",
    isoCode3: "PHL",
    dial: "63",
    name: "Philippines",
  },
  {
    isoCode2: "PN",
    isoCode3: "PCN",
    dial: "870",
    name: "Pitcairn Islands",
  },
  {
    isoCode2: "PL",
    isoCode3: "POL",
    dial: "48",
    name: "Poland",
  },
  {
    isoCode2: "PT",
    isoCode3: "PRT",
    dial: "351",
    name: "Portugal",
  },
  {
    isoCode2: "PR",
    isoCode3: "PRI",
    dial: "1",
    name: "Puerto Rico",
  },
  {
    isoCode2: "QA",
    isoCode3: "QAT",
    dial: "974",
    name: "Qatar",
  },
  {
    isoCode2: "RO",
    isoCode3: "ROU",
    dial: "40",
    name: "Romania",
  },
  {
    isoCode2: "RU",
    isoCode3: "RUS",
    dial: "7",
    name: "Russia",
  },
  {
    isoCode2: "RW",
    isoCode3: "RWA",
    dial: "250",
    name: "Rwanda",
  },
  {
    isoCode2: "RE",
    isoCode3: "REU",
    dial: "262",
    name: "Réunion",
  },
  {
    isoCode2: "BL",
    isoCode3: "BLM",
    dial: "590",
    name: "St. Barthélemy",
  },
  {
    isoCode2: "SH",
    isoCode3: "SHN",
    dial: "290",
    name: "St. Helena",
  },
  {
    isoCode2: "KN",
    isoCode3: "KNA",
    dial: "1 - 869",
    name: "St. Kitts & Nevis",
  },
  {
    isoCode2: "LC",
    isoCode3: "LCA",
    dial: "1 - 758",
    name: "St. Lucia",
  },
  {
    isoCode2: "MF",
    isoCode3: "MAF",
    dial: "590",
    name: "St. Martin",
  },
  {
    isoCode2: "PM",
    isoCode3: "SPM",
    dial: "508",
    name: "St. Pierre & Miquelon",
  },
  {
    isoCode2: "VC",
    isoCode3: "VCT",
    dial: "1 - 784",
    name: "St. Vincent & Grenadines",
  },
  {
    isoCode2: "WS",
    isoCode3: "WSM",
    dial: "685",
    name: "Samoa",
  },
  {
    isoCode2: "SM",
    isoCode3: "SMR",
    dial: "378",
    name: "San Marino",
  },
  {
    isoCode2: "ST",
    isoCode3: "STP",
    dial: "239",
    name: "São Tomé & Príncipe",
  },
  {
    isoCode2: "SA",
    isoCode3: "SAU",
    dial: "966",
    name: "Saudi Arabia",
  },
  {
    isoCode2: "SN",
    isoCode3: "SEN",
    dial: "221",
    name: "Senegal",
  },
  {
    isoCode2: "RS",
    isoCode3: "SRB",
    dial: "381",
    name: "Serbia",
  },
  {
    isoCode2: "SC",
    isoCode3: "SYC",
    dial: "248",
    name: "Seychelles",
  },
  {
    isoCode2: "SL",
    isoCode3: "SLE",
    dial: "232",
    name: "Sierra Leone",
  },
  {
    isoCode2: "SG",
    isoCode3: "SGP",
    dial: "65",
    name: "Singapore",
  },
  {
    isoCode2: "SX",
    isoCode3: "SXM",
    dial: "1 - 721",
    name: "Sint Maarten",
  },
  {
    isoCode2: "SK",
    isoCode3: "SVK",
    dial: "421",
    name: "Slovakia",
  },

  {
    isoCode2: "SI",
    isoCode3: "SVN",
    dial: "386",
    name: "Slovenia",
  },
  {
    isoCode2: "SB",
    isoCode3: "SLB",
    dial: "677",
    name: "Solomon Islands",
  },
  {
    isoCode2: "SO",
    isoCode3: "SOM",
    dial: "252",
    name: "Somalia",
  },
  {
    isoCode2: "ZA",
    isoCode3: "ZAF",
    dial: "27",
    name: "South Africa",
  },
  {
    isoCode2: "GS",
    isoCode3: "SGS",
    dial: "500",
    name: "South Georgia & South Sandwich Islands",
  },
  {
    isoCode2: "SS",
    isoCode3: "SSD",
    dial: "211",
    name: "South Sudan",
  },
  {
    isoCode2: "SS",
    isoCode3: "SSD",
    dial: "211",
    name: "South Sudan",
  },
  {
    isoCode2: "ES",
    isoCode3: "ESP",
    dial: "34",
    name: "Spain",
  },
  {
    isoCode2: "LK",
    isoCode3: "LKA",
    dial: "94",
    name: "Sri Lanka",
  },
  {
    isoCode2: "SD",
    isoCode3: "SDN",
    dial: "249",
    name: "Sudan",
  },
  {
    isoCode2: "SR",
    isoCode3: "SUR",
    dial: "597",
    name: "Suriname",
  },
  {
    isoCode2: "SJ",
    isoCode3: "SJM",
    dial: "47",
    name: "Svalbard & Jan Mayen",
  },
  {
    isoCode2: "SZ",
    isoCode3: "SWZ",
    dial: "268",
    name: "Swaziland",
  },
  {
    isoCode2: "SE",
    isoCode3: "SWE",
    dial: "46",
    name: "Sweden",
  },
  {
    isoCode2: "CH",
    isoCode3: "CHE",
    dial: "41",
    name: "Switzerland",
  },
  {
    isoCode2: "SY",
    isoCode3: "SYR",
    dial: "963",
    name: "Syria",
  },
  {
    isoCode2: "TW",
    isoCode3: "TWN",
    dial: "886",
    name: "Taiwan",
  },
  {
    isoCode2: "TJ",
    isoCode3: "TJK",
    dial: "992",
    name: "Tajikistan",
  },
  {
    isoCode2: "TZ",
    isoCode3: "TZA",
    dial: "255",
    name: "Tanzania",
  },
  {
    isoCode2: "TH",
    isoCode3: "THA",
    dial: "66",
    name: "Thailand",
  },
  {
    isoCode2: "TL",
    isoCode3: "TLS",
    dial: "670",
    name: "Timor-Leste",
  },
  {
    isoCode2: "TG",
    isoCode3: "TGO",
    dial: "228",
    name: "Togo",
  },
  {
    isoCode2: "TK",
    isoCode3: "TKL",
    dial: "690",
    name: "Tokelau",
  },
  {
    isoCode2: "TO",
    isoCode3: "TON",
    dial: "676",
    name: "Tonga",
  },
  {
    isoCode2: "TT",
    isoCode3: "TTO",
    dial: "1 - 868",
    name: "Trinidad & Tobago",
  },
  {
    isoCode2: "TN",
    isoCode3: "TUN",
    dial: "216",
    name: "Tunisia",
  },
  {
    isoCode2: "TR",
    isoCode3: "TUR",
    dial: "90",
    name: "Turkey",
  },
  {
    isoCode2: "TM",
    isoCode3: "TKM",
    dial: "993",
    name: "Turkmenistan",
  },
  {
    isoCode2: "TC",
    isoCode3: "TCA",
    dial: "1 - 649",
    name: "Turks & Caicos Islands",
  },
  {
    isoCode2: "TV",
    isoCode3: "TUV",
    dial: "688",
    name: "Tuvalu",
  },
  {
    isoCode2: "UG",
    isoCode3: "UGA",
    dial: "256",
    name: "Uganda",
  },
  {
    isoCode2: "UA",
    isoCode3: "UKR",
    dial: "380",
    name: "Ukraine",
  },

  {
    isoCode2: "AE",
    isoCode3: "ARE",
    dial: "971",
    name: "United Arab Emirates",
  },
  {
    isoCode2: "GB",
    isoCode3: "GBR",
    dial: "44",
    name: "United Kingdom",
  },
  {
    isoCode2: "US",
    isoCode3: "USA",
    dial: "1",
    name: "United States",
  },
  {
    isoCode2: "UM",
    isoCode3: "UMI",
    dial: "1",
    name: "U.S. Outlying Islands",
  },
  {
    isoCode2: "UY",
    isoCode3: "URY",
    dial: "598",
    name: "Uruguay",
  },
  {
    isoCode2: "UZ",
    isoCode3: "UZB",
    dial: "998",
    name: "Uzbekistan",
  },
  {
    isoCode2: "VU",
    isoCode3: "VUT",
    dial: "678",
    name: "Vanuatu",
  },

  {
    isoCode2: "VE",
    isoCode3: "VEN",
    dial: "58",
    name: "Venezuela",
  },
  {
    isoCode2: "VN",
    isoCode3: "VNM",
    dial: "84",
    name: "Vietnam",
  },
  {
    isoCode2: "VG",
    isoCode3: "VGB",
    dial: "1 - 284",
    name: "British Virgin Islands",
  },
  {
    isoCode2: "VI",
    isoCode3: "VIR",
    dial: "1 - 340",
    name: "U.S. Virgin Islands",
  },
  {
    isoCode2: "WF",
    isoCode3: "WLF",
    dial: "681",
    name: "Wallis & Futuna",
  },
  {
    isoCode2: "EH",
    isoCode3: "ESH",
    dial: "212",
    name: "Western Sahara",
  },
  {
    isoCode2: "YE",
    isoCode3: "YEM",
    dial: "967",
    name: "Yemen",
  },
  {
    isoCode2: "ZM",
    isoCode3: "ZMB",
    dial: "260",
    name: "Zambia",
  },
  {
    isoCode2: "ZW",
    isoCode3: "ZWE",
    dial: "263",
    name: "Zimbabwe",
  },
  {
    isoCode2: "AX",
    isoCode3: "ALA",
    dial: "358",
    name: "Åland Islands",
  },
];

export enum PaymentProviderIDs {
  WEACCEPT = "WEACCEPT",
  STRIPE = "STRIPE",
  PAYTABS = "PAYTABS",
  PAYPAL = "PAYPAL",
  GOOGLEPROVIDER = "GOOGLEADS",
  CASH = "CASH",
  INAPPGOOGLE = "INAPP_GOOGLE",
  STRIPE_CONNECT = "STRIPE_CONNECT",
}

export const plan1 = {
  id: "plan_1000",
  name: "Basic",
  tagline: "Jump Start Your Dream App Now!",
  price: "$59",
  total: 59,
  desc: "Per month billed monthly",
  save: "$120 off ($49/mo)",
  per: "Month",
  annually: false,
  type: "main_plan",
  totalPrice: "$59.00",
  annuallyPlanID: 'plan_11000'
};
export const plan2 = {
  id: "plan_2000",
  name: "Professional",
  tagline: "Get your First 500 App Users on Board Now!",
  price: "$159",
  total: 159,
  desc: "Per month billed monthly",
  save: "$240 off ($139/mo)",
  per: "Month",
  annually: false,
  type: "main_plan",
  totalPrice: "$159.00",
  annuallyPlanID: 'plan_12000'
};
export const plan3 = {
  id: "plan_3000",
  name: "Premium",
  tagline: "Expand your Outreach & Grow your App Now!",
  price: "$359",
  total: 359,
  desc: "Per month billed monthly",
  save: "$720 off ($299/mo)",
  per: "Month",
  annually: false,
  type: "main_plan",
  totalPrice: "$359.00",
  annuallyPlanID: 'plan_13000'
};
export const plan5 = {
  id: "plan_11000",
  name: "Basic",
  tagline: "Jump Start Your Dream App Now!",
  price: "$49",
  main_price: "$59",
  total: 588,
  desc: "Per month billed annually",
  save: "$120 discount applied",
  per: "Year",
  annually: true,
  type: "main_plan",
  totalPrice: "$588.00",
  monthlyPlanID: 'plan_1000'
};
export const plan6 = {
  id: "plan_12000",
  name: "Professional",
  tagline: "For small teams",
  price: "$139",
  main_price: "$159",
  total: 1668,
  desc: "Per month billed annually",
  save: "$240 discount applied",
  per: "Year",
  annually: true,
  type: "main_plan",
  totalPrice: "$1,668.00",
  monthlyPlanID: 'plan_2000'
};
export const plan7 = {
  id: "plan_13000",
  name: "Premium",
  tagline: "As your business scales",
  price: "$299",
  main_price: "$359",
  total: 3588,
  desc: "Per month billed annually",
  save: "$720 discount applied",
  per: "Year",
  annually: true,
  type: "main_plan",
  totalPrice: "$3,588.00",
  monthlyPlanID: 'plan_3000'
};

export const plan8 = {
  id: "plan_50",
  name: "Additional 5 Builds",
  price: "$10",
  totalPrice: "$10",
  total: 10,
  tagline: "Add additional 5 builds per month to your plan.",
  desc: "",
  save: "",
  per: "Month",
  annually: false,
  type: "sub_plan",
};


export enum ChatTypes {
  GROUP = 'Group',
  CHANNEL = 'Channel',
  EVENT_CHANNEL = 'EventChannel',
  EVENT_GROUP = 'EventGroup',
  BOOKING_CHANNEL = 'BookingChannel',
  BOOKING_GROUP = 'BookingGroup',
  INDIVIDUAL = 'Individual',
  VIRTUAL_APP = 'VirtualApp'
}

export const Choices_Styles= [
  {
    value: '1',
    label: 'Only Icon',
    label_ios: 'Only Icon',
  },
  {
    value: '2',
    label: 'Only Image',
    label_ios: 'Only Image',
  },
  {
    value: '3',
    label: 'No Icon or Image',
    label_ios: 'No Icon or Image',
  },
];
export const Buttons_Styles= [
  {
    value: '01',
    label: 'Elevated button',
    label_ios: 'Elevated button',
  },
  {
    value: '02',
    label: 'Filled Button',
    label_ios: 'Filled Button',
  },
  {
    value: '03',
    label: 'Tonal Button',
    label_ios: 'Tonal Button',
  },
  {
    value: '04',
    label: 'Outlined button',
    label_ios: 'Outlined button',
  },
  {
    value: '05',
    label: 'Text Button',
    label_ios: 'Text Button',
  },
];
export const Field_Text_Styles= [
  {
    value: '01',
    label: 'Outline Field',
    label_ios: 'Outline Field',
  },
  {
    value: '02',
    label: 'Text Field',
    label_ios: 'Text Field',
  },
  {
    value: '03',
    label: 'Elevated Field',
    label_ios: 'Elevated Field',
  }
];
export const Card_Styles= [
  {
    value: '03',
    label: 'Card 03',
    label_ios: 'Outline Field',
  },
  {
    value: '04',
    label: 'Card 04',
    label_ios: 'Text Field',
  },
  {
    value: '05',
    label: 'Card 05',
    label_ios: 'Elevated Field',
  },
  {
    value: '06',
    label: 'Card 06',
    label_ios: 'Elevated Field',
  },
  {
    value: '07',
    label: 'Card 07',
    label_ios: 'Elevated Field',
  },
];

export const DropDown_Styles= [
  {
    value: '1',
    label: 'Outline Field',
    label_ios: 'Outline Field',
  },
  {
    value: '2',
    label: 'Text Field',
    label_ios: 'Text Field',
  },
  {
    value: '3',
    label: 'Elevated Field',
    label_ios: 'Elevated Field',
  }
];

export enum ADMIN_PRIVILEGES {
  CHANGE_CHANNEL_INFO = '2',
  POST_MESSAGES = '4',
  EDIT_MESSAGE_OF_OTHERS = '8',
  DELETE_MESSAGE_OF_OTHERS = '16',
  INVITE_PARTICIPANT = '32',
  INVITE_ADMIN = '64',
  REPLY_TO_MESSAGES = '128',
  MOBILE_ACCESS = '256',
  WEB_ACCESS = '512',
  EDIT_ADMIN_PRIVILEGES = '1024',
  REMOVE_PARTICIPANT = '2048',
  BAN_PARTICIPANT = '4096',
  GET_USER_LOGIN_ID = '32768'
}

export const colorSchemaDefault = {
  color_schema: {
      style: {
          lightStatusBar: "1",
          lightBottomStatusBar: "0"
      },
      schemes: {
          light: {
              primary: "#0081a6",
              onPrimary: "#ffffff",
              primaryContainer: "#bee9ff",
              onPrimaryContainer: "#001f2a",
              secondary: "#4f616b",
              onSecondary: "#ffffff",
              secondaryContainer: "#d2e5f1",
              onSecondaryContainer: "#0b1e26",
              tertiary: "#777395",
              onTertiary: "#ffffff",
              tertiaryContainer: "#e3dfff",
              onTertiaryContainer: "#1a1834",
              error: "#ba1a1a",
              onError: "#ffffff",
              errorContainer: "#ffdad6",
              onErrorContainer: "#410002",
              surface: "#f9f9fb",
              onSurface: "#191c1d",
              surfaceVariant: "#dde3e8",
              onSurfaceVariant: "#41484c",
              outline: "#71787c",
              outlineVariant: "#c1c7cc",
              shadow: "#000000",
              scrim: "#000000",
              surfaceDim: "#d9dadc",
              surfaceDimmer: "#d3d4d6",
              surfaceBright: "#f9f9fb",
              surfaceContainerLowest: "#ffffff",
              surfaceContainerLow: "#f3f3f5",
              surfaceContainer: "#edeef0",
              surfaceContainerHigh: "#e7e8ea",
              surfaceContainerHighest: "#e1e2e4",
              secondaryDim: "#4a5c66",
              surfaceInverse: "#2e3132",
              onSurfaceInverse: "#f0f1f2",
              PrimaryInverse: "#71d2fd",
              success: "#006b5c",
              onSuccess: "#ffffff",
              successContainer: "#64fade",
              onSuccessContainer: "#00201b",
              warning: "#725c00",
              onWarning: "#ffffff",
              warningContainer: "#ffe082",
              onWarningContainer: "#231b00",
              mPrimaryContainerBright: "#d3efff",
              mPrimaryContainer: "#bee9ff",
              mOnPrimaryContainer: "#001f2a",
              mPrimaryInverseTransparent: "#E071d2fd",
              mSurfaceContainerBright: "#ffffff",
              mSurfaceContainerHighest: "#e1e2e4",
              mOnSurfaceVariant: "#41484c",
              m2Primary: "#0081a6",
              m2OnSurfaceVariant: "#41484c",
              m2APrimary: "#0081a6",
              m2Surface: "#f9f9fb",
              m2ASurface: "#f9f9fb",
              m2AonSurfaceVariant: "#dde3e8",
              m2CSurface: "#f9f9fb",
              m2DSurface: "#f9f9fb",
              m2BSurface: "#f9f9fb",
              m2SurfaceContainer: "#edeef0",
              m2SurfaceContainerHigh: "#e7e8ea"
          },
          dark: {
              primary: "#52b6e0",
              onPrimary: "#003546",
              primaryContainer: "#004d65",
              onPrimaryContainer: "#bee9ff",
              secondary: "#b6c9d5",
              onSecondary: "#21333c",
              secondaryContainer: "#374953",
              onSecondaryContainer: "#d2e5f1",
              tertiary: "#aba7cb",
              onTertiary: "#2f2d4a",
              tertiaryContainer: "#464362",
              onTertiaryContainer: "#e3dfff",
              error: "#ffb4ab",
              onError: "#690005",
              errorContainer: "#93000a",
              onErrorContainer: "#ffb4ab",
              surface: "#111415",
              onSurface: "#e1e2e4",
              surfaceVariant: "#41484c",
              onSurfaceVariant: "#c1c7cc",
              outline: "#8b9296",
              outlineVariant: "#41484c",
              shadow: "#000000",
              scrim: "#000000",
              surfaceDim: "#111415",
              surfaceDimmer: "#151819",
              surfaceBright: "#37393b",
              surfaceContainerLowest: "#0c0f10",
              surfaceContainerLow: "#191c1d",
              surfaceContainer: "#1d2022",
              surfaceContainerHigh: "#282a2c",
              surfaceContainerHighest: "#333537",
              secondaryDim: "#bbcfda",
              surfaceInverse: "#e1e2e4",
              onSurfaceInverse: "#2e3132",
              PrimaryInverse: "#006685",
              success: "#3fddc2",
              onSuccess: "#00382f",
              successContainer: "#005045",
              onSuccessContainer: "#64fade",
              warning: "#e8c349",
              onWarning: "#3c2f00",
              warningContainer: "#564500",
              onWarningContainer: "#ffe082",
              mPrimaryContainerBright: "#00485e",
              mPrimaryContainer: "#004d65",
              mOnPrimaryContainer: "#bee9ff",
              mPrimaryInverseTransparent: "#E0006685",
              mSurfaceContainerBright: "#0c0f10",
              mSurfaceContainerHighest: "#333537",
              mOnSurfaceVariant: "#c1c7cc",
              m2Primary: "#52b6e0",
              m2OnSurfaceVariant: "#c1c7cc",
              m2APrimary: "#52b6e0",
              m2Surface: "#111415",
              m2ASurface: "#111415",
              m2AonSurfaceVariant: "#41484c",
              m2CSurface: "#111415",
              m2DSurface: "#111415",
              m2BSurface: "#111415",
              m2SurfaceContainer: "#1d2022",
              m2SurfaceContainerHigh: "#282a2c"
          }
      },
      palettes: {
          primary: {
              hue: 229.35268996702683,
              chroma: 44.883647601464276,
              keyColor: {
                  argb: 4278290864,
                  internalHue: 229.04375170263648,
                  internalChroma: 44.79407939459641,
                  internalTone: 52.984895457174616,
                  id: "#0189b0"
              },
              cache: {}
          },
          secondary: {
              hue: 229.35268996702683,
              chroma: 14.961215867154758,
              keyColor: {
                  argb: 4284971652,
                  internalHue: 228.65371895863223,
                  internalChroma: 14.978264018296022,
                  internalTone: 50.05957644716398,
                  id: "#677a84"
              },
              cache: {}
          },
          tertiary: {
              hue: 289.35268996702683,
              chroma: 22.441823800732138,
              keyColor: {
                  argb: 4285821330,
                  internalHue: 289.28084816703415,
                  internalChroma: 22.34939746501028,
                  internalTone: 49.00556560004621,
                  id: "#747192"
              },
              cache: {}
          },
          error: {
              hue: 25,
              chroma: 84,
              keyColor: {
                  argb: 4292753200,
                  internalHue: 25.01013502609243,
                  internalChroma: 84.04509263062816,
                  internalTone: 50.06767986742766,
                  id: "#de3730"
              },
              cache: {}
          },
          neutral: {
              hue: 229.35268996702683,
              chroma: 3.7403039667886895,
              keyColor: {
                  argb: 4285888377,
                  internalHue: 231.50647682272847,
                  internalChroma: 3.842704712420745,
                  internalTone: 49.925123500048116,
                  id: "#757779"
              },
              cache: {}
          },
          neutralVariant: {
              hue: 229.35268996702683,
              chroma: 7.480607933577379,
              keyColor: {
                  argb: 4285626492,
                  internalHue: 227.08928131553697,
                  internalChroma: 7.3212821900644505,
                  internalTone: 49.97638507930856,
                  id: "#71787c"
              },
              cache: {}
          }
      },
      source: "#2596be"
  },
  color_schema_ios: {
      palettes: {
          secondary: { keyColor: { id: "#767778" } },
          tertiary: { keyColor: { id: "#5e7a94" } },
          neutralVariant: { keyColor: { id: "#74777f" } },
          neutral: { keyColor: { id: "#79797d" } },
          error: { keyColor: { id: "#de3730" } },
          primary: { keyColor: { id: "#0075b1" } }
      },
      schemes: {
          dark: {
              primary: "#2294be",
              onPrimary: "#003546",
              primaryContainer: "#004d65",
              onPrimaryContainer: "#bee9ff",
              secondary: "#8b9eaa",
              onSecondary: "#1f333c",
              secondaryContainer: "#374953",
              onSecondaryContainer: "#d2e5f1",
              tertiary: "#a3c4e1",
              onTertiary: "#00324a",
              tertiaryContainer: "#004a69",
              onTertiaryContainer: "#c0e8ff",
              error: "#ffb4ab",
              onError: "#690005",
              errorContainer: "#93000a",
              onErrorContainer: "#ffb4ab",
              surface: "#111415",
              onSurface: "#e1e2e4",
              surfaceVariant: "#41484c",
              onSurfaceVariant: "#c1c7cc",
              outline: "#8b9296",
              outlineVariant: "#41484c",
              shadow: "#000000",
              scrim: "#000000",
              surfaceDim: "#111415",
              surfaceBright: "#37393b",
              surfaceInverse: "#e1e2e4",
              onSurfaceInverse: "#2e3132",
              PrimaryInverse: "#006685"
          }
      }
  }
};

