import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import customBuild from "src/ckeditor5/build/ckeditor";
import {
  LOGIN_TYPES,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Smtp, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from "@ckeditor/ckeditor5-core";
import { BuilderService } from "src/app/sharedservices/builder.service";
import {
  GetSmtp,
  SetSmtpMethod,
} from "src/app/core/services/outbound";

import Axios, { AxiosResponse, AxiosError } from "axios";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import Swal from "sweetalert2";

@Component({
  selector: "app-gen-apploginsmtp-server",
  templateUrl: "./gen-apploginsmtp-server.component.html",
  styleUrls: ["./gen-apploginsmtp-server.component.scss"],
})
export class GenAppLoginSmtpServerComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService,
    private http: HttpClient
  ) {}

  smtpServerform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() page_id: string;
  button: MenuButton;
  mode: string;
  public Editor = customBuild;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  verifySubscription: any;
  smtpConfigSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  component_ref: any;
  login_types = LOGIN_TYPES;

  message: any;

  submitted = false;

  loadding = false;

  smtpOpj: Smtp;

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);
    this.smtpServerform = this.formBuilder.group({
      active: new UntypedFormControl(0),
      name: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      host: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      secure: new UntypedFormControl({ value: false, disabled: true }),
      port: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      user: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
      password: new UntypedFormControl({ value: "", disabled: true }, [
        Validators.required,
      ]),
    });

    this.field_id = "lable";

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          this.buttonFunction();
        }
      }
    );

    this.smtpConfigSubscription = this.ws.smtpConfigContainer$.subscribe(
      (data) => {
        // console.log("verifyData"+ verifyData);
        if (data) {
          this.smtpOpj = { ...data.smtp };
          this.smtpServerform = this.formBuilder.group({
            active: new UntypedFormControl(
              (data.smtp && data.smtp.active) || 0
            ),
            name: new UntypedFormControl(
              {
                value: (data.smtp && data.smtp.name) || "",
                disabled: data.smtp && data.smtp.active ? false : true,
              },
              [Validators.required]
            ),
            host: new UntypedFormControl(
              {
                value: (data.smtp && data.smtp.host) || "",
                disabled: data.smtp && data.smtp.active ? false : true,
              },
              [Validators.required]
            ),
            secure: new UntypedFormControl({
              value: data.smtp && data.smtp.secure ? true : false,
              disabled: data.smtp && data.smtp.active ? false : true,
            }),
            port: new UntypedFormControl(
              {
                value: (data.smtp && data.smtp.port) || "",
                disabled: data.smtp && data.smtp.active ? false : true,
              },
              [Validators.required]
            ),
            user: new UntypedFormControl(
              {
                value: (data.smtp && data.smtp.user.name) || "",
                disabled: data.smtp && data.smtp.active ? false : true,
              },
              [Validators.required]
            ),
            password: new UntypedFormControl(
              {
                value: (data.smtp && data.smtp.user.password) || "",
                disabled: data.smtp && data.smtp.active ? false : true,
              },
              [Validators.required]
            ),
          });
        }
      }
    );

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          this.ws.processMethod(new GetSmtp(this.component_ref));
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;
    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  onSubmit() {
    this.submitted = true;
    this.loadding = true;
    if (this.smtpServerform.invalid) {
      return;
    } else {
      this.setSmtpServer();
    }
  }

  async setSmtpServer() {
    let smtp;
    if (this.smtpServerform.value.active) {
      try {
        await Axios({
          method: "get",
          url:
            environment.smtp_api_link +
            "?dns=" +
            this.smtpServerform.value.host +
            "&port=" +
            this.smtpServerform.value.port +
            "&usr=" +
            this.smtpServerform.value.user +
            "&pass=" +
            this.smtpServerform.value.password +
            "&secure=" +
            this.smtpServerform.value.secure,
        })
          .then((response) => {
            smtp = response.data;
            if(smtp.errno){
              Swal.fire({
                title: "Validation Error",
                html: "Your SMTP Server data is not vaild",
                icon: "error",
                confirmButtonText: 'Close',
                confirmButtonColor: '#556ee6'
              });
            }
          })
          .catch((err) => {
            this.loadding = false;

            if (err) {
              console.log(err);

            }
          });
      } catch (error) {
        // console.log(error);
      }
    }

    const smtpOpject: Smtp = {
      name: this.smtpServerform.value.name || this.smtpOpj.name,
      host: this.smtpServerform.value.host || this.smtpOpj.host,
      port: this.smtpServerform.value.port || this.smtpOpj.port,
      active: this.smtpServerform.value.active,
      secure: this.smtpServerform.value.secure,
      user: {
        name: this.smtpServerform.value.user || this.smtpOpj.user.name,
        password:
          this.smtpServerform.value.password || this.smtpOpj.user.password,
      },
    };
    this.ws.processMethod(new SetSmtpMethod(smtpOpject, this.component_ref));
    this.loadding = false;
  }

  buttonFunction() {
    this.button_label = this.builderService.getButtonSplashItem(
      this.button,
      "button_label",
      this.data.splashTab.param.splash.style
    );
    if (this.button_label) {
      this.smtpServerform = this.formBuilder.group({
        lableControl: new UntypedFormControl(this.button_label),
      });
    }
  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.button.button_label = this.f.lableControl.value;
      this.updateButtonData();
    }
  }

  get f() {
    if (this.smtpServerform && this.smtpServerform.controls) {
      return this.smtpServerform.controls;
    }
  }

  colorChange(event) {
    this.button.button_label_color = this.labelColor;
    this.updateButtonData();
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "splash" } },
      this.mode,
      true
    );
  }

  public onReady(editor: customBuild): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(editor.ui.view.toolbar.element!, element);
  }

  enableSMTPServer(event) {
    this.smtpServerform.patchValue({
      active: event ? 1 : 0,
    });

    if (event) {
      this.smtpServerform.get("name").enable();
      this.smtpServerform.get("host").enable();
      this.smtpServerform.get("port").enable();
      this.smtpServerform.get("secure").enable();
      this.smtpServerform.get("user").enable();
      this.smtpServerform.get("password").enable();
    } else {
      this.smtpServerform.get("name").disable();
      this.smtpServerform.get("host").disable();
      this.smtpServerform.get("port").disable();
      this.smtpServerform.get("secure").disable();
      this.smtpServerform.get("user").disable();
      this.smtpServerform.get("password").disable();
    }
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.smtpConfigSubscription) {
      this.smtpConfigSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }
}
