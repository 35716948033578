
<div class="card m-0 bg-transparent">
    <div class="card-body">
    <div class="row mb-3">
      <div class="col-12">
        <label class="offcanvas-header-text w-100" style="text-align: left">
        Component Lable
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
        <input
            type="text"
            id="title"
            #tabTitle
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="title"
            (change)="titleChange(tabTitle?.value)"

          />
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-5">
        <label class="offcanvas-header-text w-100" style="text-align: left">
          Android Icon
        </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"

          >
            <button
              type="button"
              class="form-control input-lg offcanvas-primary border-0"  (click)="openSelector(selectorAndroid)">
              <i class= "fs-4" [class]="icon?.type =='mir'? 'material-icons-outlined': 'material-icons'" >{{icon?.id}}</i>
            </button>
            <button
              type="button"
              class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorAndroid)" >
              <i class="mdi mdi-chevron-down"></i>
            </button>
          </div>
       </div>

       <div class="col-5">
        <label class="offcanvas-header-text w-100" style="text-align: left">
        iOS Icon
        </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"

          >
            <button
              type="button"
              class="form-control input-lg offcanvas-primary border-0"  (click)="openSelector(selectorIOS)">
              <i class= "fs-4" [class]="icon_ios?.type=='mir'? 'material-icons-outlined': 'material-icons'">{{icon_ios?.id}}</i>
            </button>
            <button
              type="button"
              class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorIOS)" >
              <i class="mdi mdi-chevron-down"></i>
            </button>
          </div>
       </div>
    </div>

  <ng-template #selectorAndroid let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="selector">Choose Android Icon </h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      ></button>
    </div>
    <div class="modal-body">
      <app-icons-selector
        (selectedIcon)="iconChange($event)"
      ></app-icons-selector>
    </div>
  </ng-template>
  <ng-template #selectorIOS let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="selector">Choose iOS Icon</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      ></button>
    </div>
    <div class="modal-body">
      <app-icons-selector
        (selectedIcon)="iosIconChange($event)"
      ></app-icons-selector>
    </div>
  </ng-template>
