import { BuilderService } from 'src/app/sharedservices/builder.service';
import { Menu } from "../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-offcanvas-tab-drag",
  templateUrl: "./offcanvas-tab-drag.component.html",
  styleUrls: ["./offcanvas-tab-drag.component.scss"],
})
export class OffcanvasTabDragComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}

  @Input() data: Tab;
  @Input() mode: string;
  @Input() offcanvas_data: any[] = [];

  offCanvasSubscription: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "home";

  ngOnInit() {

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        // console.log("INSIDE off canv data", data);
        if (data) {
          switch (data.off_canvas_key) {
            case "home":
              console.log("TabNames.OPENGROUP", TabNames.OPENGROUP);
              let name = common_components_map[TabNames.OPENGROUP].name
              console.log("opengroup", this.TabComponent(TabNames.OPENGROUP),);
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "CUSTOM COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.MENU),
                        // this.TabComponent(TabNames.PAGE),
                            ],
                    },
                    {
                      cat: "CHANNEL COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.FEED),
                        this.TabComponent(TabNames.CHANNEL),
                        this.TabComponent(TabNames.CHANNELLIST),
                        this.TabComponent(TabNames.ONLINECHANNEL),
                           ],
                    },

                    {
                      cat: "CHAT COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.CHAT),
                        this.TabComponent(TabNames.VIDEOAUDIO),
                        this.TabComponent(TabNames.OPENBOT),
                        this.TabComponent(TabNames.CONTACT),
                        this.TabComponent(TabNames.SPEEDDIALER),
                        this.TabComponent(TabNames.CALL_LOG),
                               ],
                    },
                    {
                      cat: "GROUP CHATS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.GROUP),
                        this.TabComponent(TabNames.OPENGROUP),

                        this.TabComponent(TabNames.ONLINEGROUP),
                        // this.TabComponent(TabNames.ONBOARDING),
                            ],
                    },

                    {
                      cat: "Mobile Commerce",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.STORE),
                        // this.TabComponent(TabNames.ORDERLIST),
                      ],
                    },

                    {
                      cat: "Booking and Queuing",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.BOOKINGMARKETPLACE),
                        // this.TabComponent(TabNames.BOOKINGLIST),
                        // this.TabComponent(TabNames.QUEUINGLIST),
                         // this.TabComponent(TabNames.EVENTLIST),
                        // this.TabComponent(TabNames.VALIDATE_TICKET),
                        // this.TabComponent(TabNames.REDEEM_TICKET),

                      ],
                    },
                     {
                      cat: "ESSENTIALS COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.CALENDAR),
                        this.TabComponent(TabNames.INVITATION),
                        this.TabComponent(TabNames.QR),
                        this.TabComponent(TabNames.WEBVIEW),
                        this.TabComponent(TabNames.VIDEO),
                       ],
                    },

                    {
                      cat: "SEARCH COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.MAP_SEARCH),
                        this.TabComponent(TabNames.SEARCH),
                      ],
                    },
                  ],
                },
              ];

              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;
            case "side":
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "CUSTOM COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.MENU),
                        // this.TabComponent(TabNames.PAGE),
                            ],
                    },

                    {
                      cat: "CHANNEL COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.FEED),
                        this.TabComponent(TabNames.CHANNEL),
                        this.TabComponent(TabNames.CHANNELLIST),
                        this.TabComponent(TabNames.ONLINECHANNEL),
                           ],
                    },

                    {
                      cat: "CHAT COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.CHAT),
                        this.TabComponent(TabNames.VIDEOAUDIO),
                        this.TabComponent(TabNames.OPENBOT),
                        this.TabComponent(TabNames.CONTACT),
                        this.TabComponent(TabNames.SPEEDDIALER),
                        this.TabComponent(TabNames.CALL_LOG),
                               ],
                    },
                    {
                      cat: "GROUP CHATS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.GROUP),
                        this.TabComponent(TabNames.OPENGROUP),
                        // this.TabComponent(TabNames.ONBOARDING),
                        this.TabComponent(TabNames.ONLINEGROUP),
                            ],
                    },

                    {
                      cat: "Mobile Commerce",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.STORE),
                        // this.TabComponent(TabNames.ORDERLIST),
                      ],
                    },

                    {
                      cat: "Booking and Queuing",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.BOOKINGMARKETPLACE),
                        // this.TabComponent(TabNames.BOOKINGLIST),
                        // this.TabComponent(TabNames.QUEUINGLIST),
                         // this.TabComponent(TabNames.EVENTLIST),
                        // this.TabComponent(TabNames.VALIDATE_TICKET),
                        // this.TabComponent(TabNames.REDEEM_TICKET),

                      ],
                    },
                     {
                      cat: "ESSENTIALS COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.SETTINGS),
                        this.TabComponent(TabNames.CALENDAR),
                        this.TabComponent(TabNames.INVITATION),
                        this.TabComponent(TabNames.QR),
                        this.TabComponent(TabNames.WEBVIEW),
                        this.TabComponent(TabNames.VIDEO),
                       ],
                    },

                    {
                      cat: "SEARCH COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.MAP_SEARCH),
                        this.TabComponent(TabNames.SEARCH),
                      ],
                    },
                  ],
                },
              ];

              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;

                  this.offcanvas_data = [
                    {
                      tab: "Basic",
                      data: [
                        {
                          cat: "Full Custom Pages",
                          show: true,
                          list: [
                            {
                              module: TabNames.MENU,
                              name: "Custom Native Workflow",
                              icon: "fas fa-network-wired",
                              hint: "Full custom native cells with API access",
                            },
                            {
                              module: TabNames.PAGE,
                              name: "Custom Wepages",
                              icon: common_components_map[TabNames.PAGE]
                                .icon,
                              hint: "Full custom webpages",
                            },
                          ],
                        },

                        {
                          cat: "Messeging Channels",
                          show: true,
                          list: [
                                                  {
                              module: TabNames.CHANNEL,
                              name: "Channels Dashboard",
                              icon: common_components_map[TabNames.CHANNEL]
                                .icon,
                              hint: "Display a dashboard of active channels the user has joined.",
                            },

                            {
                              module: TabNames.CHANNELLIST,
                              name: "My Active Channels",
                              icon: common_components_map[
                                TabNames.CHANNELLIST
                              ].icon,
                              hint: "Display a list of all channels the user has joined.",
                            },

                            {
                              module: TabNames.ONLINECHANNEL,
                              name: "App Channels List",
                              icon: common_components_map[
                                TabNames.ONLINECHANNEL
                              ].icon,
                              hint: "Display all avaliable App channels list.",
                            },
                          ],
                        },

                        {
                          cat: "Messeging Chats",
                          show: true,
                          list: [
                            {
                              module: TabNames.CHAT,
                              name: "Chat Messenger",
                              icon: common_components_map[TabNames.CHAT]
                                .icon,
                              hint: "A full functioning messenger enables users to chat with contact or group and share photos, videos, voice notes, and multimedia files. Ordered by recent conversions.",
                            },

                            {
                              module: TabNames.CHAT,
                              name: "Messenger with Video/Audio Calls",
                              icon: "fas fa-phone-alt",
                              hint: "Allow user to chat and make Video and Audio Calls from your App.",
                            },

                            {
                              module: TabNames.SPEEDDIALER,
                              name: "Video and Audio Calls Dialer",
                              icon: "bx bx-dialpad",
                              hint: "Allow users to make Video and Audio Calls from your App.",
                            },

                            {
                              module: TabNames.CALL_LOG,
                              name: "Call History",
                              icon: "mdi mdi-phone-log-outline",
                              hint: "User recent received and sent Video and Audio calls.",
                            },
                            {
                              module: TabNames.CONTACT,
                              name: "User Contacts",
                              icon: "mdi mdi-contacts",
                              hint: "Users contact allows user to invite contact to the app, join channel, group and start chat or call.",
                            },

                            {
                              module: TabNames.OPENBOT,
                              name: "Bot Chat",
                              icon: common_components_map[TabNames.OPENBOT]
                                .icon,
                              hint: "Integrate third-party bots, build your own, or choose from ready made bots to perform certain functions within your App.",
                            },
                          ],
                        },

                        {
                          cat: "Group Chats",
                          show: true,
                          list: [
                            {
                              module: TabNames.GROUP,
                              name: "Groups Dashboard",
                              icon: common_components_map[TabNames.GROUP]
                                .icon,
                              hint: "Allow users to access their joined group chats, send multimedia files and search throughout their conversations",
                            },

                            {
                              module: TabNames.ONLINEGROUP,
                              name: "App Group List",
                              icon: common_components_map[
                                TabNames.ONLINEGROUP
                              ].icon,
                              hint: "Display all avaliable App group list.",
                            },

                            {
                              module: TabNames.OPENGROUP,
                              name: "Chat With A Group",
                              icon: "bx bx-chat",
                              hint: "Allow user to chat with a specific group. All group chats created on your App can include up to 10k users.",
                            },

                            {
                              module: TabNames.OPENGROUP,
                              name: "Onboarding and Support Group",
                              icon: "fas fa-handshake",
                              hint: "User chats in private with Group Adminstrator based on one to one chat.",
                            },
                          ],
                        },

                        {
                          cat: "Mobile Commerce",
                          show: true,
                          list: [
                            {
                              module: TabNames.MENU,
                              name: "Mobile Shop",
                              icon: "fas fa-shopping-cart",
                              hint: "Create a world class mobile store with different sub-stores, collections, and products.",
                            },

                            {
                              module: TabNames.ORDERLIST,
                              name: "User Order List",
                              icon: common_components_map[
                                TabNames.ORDERLIST
                              ].icon,
                              hint: "Display user recent order history.",
                            },
                          ],
                        },

                        {
                          cat: "Booking and Queuing",
                          show: true,
                          list: [
                            {
                              module: TabNames.BOOKING,
                              name: "My Active Bookings",
                              icon: "mdi mdi-calendar-clock",
                              hint: "Show all active booking the user reserved recently",
                            },

                            {
                              module: TabNames.BOOKINGLIST,
                              name: "App Booking List",
                              icon: common_components_map[
                                TabNames.BOOKINGLIST
                              ].icon,
                              hint: "Display all avaliable App booking list. Allow user to book slot or ticket",
                            },

                            {
                              module: TabNames.QUEUINGLIST,
                              name: "App Booking List",
                              icon: common_components_map[
                                TabNames.QUEUINGLIST
                              ].icon,
                              hint: "Display all avaliable App queuing list. Allow user to reserve slot or ticket in the queue service",
                            },

                            {
                              module: TabNames.VALIDATE_TICKET,
                              name: "Ticket Validation",
                              icon: common_components_map[
                                TabNames.VALIDATE_TICKET
                              ].icon,
                              hint: "Allows adminstators to validate the user booking or queuing tickets using QR scan",
                            },

                            {
                              module: TabNames.REDEEM_TICKET,
                              name: "Ticket Redeemtion",
                              icon: common_components_map[
                                TabNames.REDEEM_TICKET
                              ].icon,
                              hint: "Allows adminstators to redeem or cancle the user booking or queuing tickets using QR scan",
                            },
                          ],
                        },

                        {
                          cat: "Events and Callender",
                          show: true,
                          list: [
                            {
                              module: TabNames.EVENT,
                              name: "My Active Events",
                              icon: common_components_map[TabNames.EVENT]
                                .icon,
                              hint: "Show events the user subscribed to",
                            },

                            {
                              module: TabNames.EVENTLIST,
                              name: "App Event List",
                              icon: common_components_map[
                                TabNames.EVENTLIST
                              ].icon,
                              hint: "Display all avaliable App booking list.",
                            },
                            {
                              module: TabNames.CALENDAR,
                              name: "My Calendar",
                              icon: common_components_map[
                                TabNames.CALENDAR
                              ].icon,
                              hint: "The App calendar will display all user's activities within the App and upcoming events. The calendar syncs optionally events to the user's device calendar",
                            },
                          ],
                        },

                        {
                          cat: "Essentials Modules",
                          show: true,
                          list: [
                            {
                              module: TabNames.INVITATION,
                              name: "Invitation Dashboard",
                              icon: common_components_map[
                                TabNames.INVITATION
                              ].icon,
                              hint: "Allow your App users to send manage received invitation for joining channel, group, chat and/or upgrade member to adminstator",
                            },
                            {
                              module: TabNames.CALENDAR,
                              name: "My Calendar",
                              icon: common_components_map[
                                TabNames.CALENDAR
                              ].icon,
                              hint: "The App calendar will display all user's activities within the App and upcoming events. The calendar syncs optionally events to the user's device calendar",
                            },
                            {
                              module: TabNames.QR,
                              name: "QR Scanner",
                              icon: common_components_map[TabNames.QR]
                                .icon,
                              hint: "Allow users to scan avaliable QRs to join chat, group, channel and login to Webchat or Web-Admin",
                            },
                            {
                              module: TabNames.WEBVIEW,
                              name: "Webview Page",
                              icon: common_components_map[TabNames.WEBVIEW]
                                .icon,
                              hint: "Allow you to redirect users to your website, blog or any external webpage of your choice to be displayed within the App",
                            },
                            {
                              module: TabNames.VIDEO,
                              name: "Video Page",
                              icon: common_components_map[TabNames.VIDEO]
                                .icon,
                              hint: "With the new Video module, you can include a link that will redirect the users to a Video of your choice",
                            },
                            {
                              module: TabNames.SETTINGS,
                              name: "User Settings",
                              icon: common_components_map[
                                TabNames.SETTINGS
                              ].icon,
                              hint: "Allow user to control thier own Downloads, Media storage, Account, Notifications, and Chats settings. This module is essential to get your App approved by Apple.",
                            },
                          ],
                        },

                        {
                          cat: "Map and Search ",
                          show: true,
                          list: [
                            {
                              module: TabNames.MAP_SEARCH,
                              name: "Map Search",
                              icon: common_components_map[
                                TabNames.INVITATION
                              ].icon,
                              hint: "Allow users to explore their neighborhoods and find nearby places based on their interest and geo-location.",
                            },
                            {
                              module: TabNames.SEARCH,
                              name: "Search Page",
                              icon: common_components_map[TabNames.SEARCH]
                                .icon,
                              hint: "Include a powerful search tool that work throughout the App contents and service",
                            },
                          ],
                        },
                      ],
                    },
                  ];
                  this.off_canvas_key = data.off_canvas_key;
                  if (data.show) {
                    this.showOffcanvas();
                  } else {
                    this.hideOffcanvas();
                  }

                  break;
            case "next":
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "CUSTOM COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.MENU),
                        // this.TabComponent(TabNames.PAGE),
                            ],
                    },

                    {
                      cat: "CHANNEL COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.FEED),
                        this.TabComponent(TabNames.CHANNEL),
                        this.TabComponent(TabNames.CHANNELLIST),
                        this.TabComponent(TabNames.ONLINECHANNEL),
                           ],
                    },

                    {
                      cat: "CHAT COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.CHAT),
                        this.TabComponent(TabNames.VIDEOAUDIO),
                        this.TabComponent(TabNames.OPENBOT),
                        this.TabComponent(TabNames.CONTACT),
                        this.TabComponent(TabNames.SPEEDDIALER),
                        this.TabComponent(TabNames.CALL_LOG),
                               ],
                    },
                    {
                      cat: "GROUP CHATS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.GROUP),
                        this.TabComponent(TabNames.OPENGROUP),
                        // this.TabComponent(TabNames.ONBOARDING),
                        this.TabComponent(TabNames.ONLINEGROUP),
                            ],
                    },

                    {
                      cat: "Mobile Commerce",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.STORE),
                        // this.TabComponent(TabNames.ORDERLIST),
                      ],
                    },

                    {
                      cat: "Booking and Queuing",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.BOOKINGMARKETPLACE),
                        // this.TabComponent(TabNames.BOOKINGLIST),
                        // this.TabComponent(TabNames.QUEUINGLIST),
                         // this.TabComponent(TabNames.EVENTLIST),
                        // this.TabComponent(TabNames.VALIDATE_TICKET),
                        // this.TabComponent(TabNames.REDEEM_TICKET),

                      ],
                    },
                     {
                      cat: "ESSENTIALS COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.SETTINGS),
                        this.TabComponent(TabNames.CALENDAR),
                        this.TabComponent(TabNames.INVITATION),
                        this.TabComponent(TabNames.QR),
                        this.TabComponent(TabNames.WEBVIEW),
                        this.TabComponent(TabNames.VIDEO),
                       ],
                    },

                    {
                      cat: "SEARCH COMPONENTS",
                      show: true,
                      list: [
                        this.TabComponent(TabNames.MAP_SEARCH),
                        this.TabComponent(TabNames.SEARCH),
                      ],
                    },
                  ],
                },
              ];

              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;
              break;

           }
        }
      }
    );
  }

  drag(event: any, i: any) {
    let data = i;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "next":
        event.dataTransfer.setData("module", data.module);
        break;
      case "menu":
       case "page":
        event.dataTransfer.setData("button", JSON.stringify(data));
        break;
    }
  }


  onClick(item) {
    let data = item;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
      case "next":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: data.module,
        });
        break;
      case "menu":
      case "page":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: JSON.stringify(data),
        });
        break;
    }
  }

  hideOffcanvas() {
    document.querySelector("#offcanvasScrolling").classList.remove("show");
    document.querySelector("#faide").classList.remove("animate-fede-out");
    document.querySelector("#faide").classList.add("animate-fede-in");
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrolling").classList.add("show");
  }



  TabComponent(module) {
    let component =
      {
        module: module,
        name: common_components_map[module].name,
        icon: common_components_map[module].icon,
        hint:common_components_map[module].hint,
      }
    return component
  }
  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
