
  <div class=" not-allowed ">
    <!-- ********* Android Active **************** -->
    <div  *ngIf="heading" class="  m-0 p-0  d-flex justify-content-start"  [ngClass]="fontSmaller?'p-0': 'px-2'">
      <p class=" m-0 text-wrap w-auto fw-bolder" [ngStyle]="headingColor">
        {{heading}}
    </p>
    </div>

    <div  *ngIf="subheading" class="  m-0 p-0  d-flex justify-content-start"  [ngClass]="fontSmaller?'p-0': 'px-2 py-1'">
      <p class=" m-0 text-wrap w-auto" [ngStyle]="subheadingColor">
        {{subheading}}
    </p>
    </div>

    <div *ngIf=" !mirror && isAndroid">
      <div class=" card m-0 rounded" [ngClass]="fontSmaller?'p-0': 'p-0'" [ngStyle]="setButtonBg" [ngClass]="shadow? 'shadow z-3':''"
      [style]=" 'border:' + outlined_border + '; border-color: ' + border_color + '!important'">
        <div *ngFor="let option of options; index as index;">
          <div class="row m-0 p-0 w-100 " style="height: {{cellHeight}}"
            [ngStyle]="activeOption[option.id]? optionBgColor: null" [ngClass]="optionPadding">

            <div *ngIf="leading_icon" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center">
              <!-- <i *ngIf="option.icon" class="float-none w-auto m-0 px-1 " [ngStyle]="leadingIconColor"
                class="{{option.icon}}"> </i> -->
                <i *ngIf="option.icon" class="float-none w-auto m-0 px-1" [ngStyle]="leadingIconColor" [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
              <img *ngIf="option.image && !option.icon" [src]="option.image" class="float-none w-auto m-0 px-1 "
                alt="avatar" class="rounded-circle" [ngStyle]="leadingIconColor">
            </div>

            <div [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"
              class="  m-0 p-0  d-flex justify-content-start align-items-center ">
              <p class=" m-0 p-0 text-wrap w-auto px-1 d-flex justify-content-start align-items-center"
                [ngStyle]="optionlTextColor">
                {{option.label}}
              </p>
            </div>

            <div *ngIf="trailing_icon"
              class="col-1 m-0 p-0 d-flex justify-content-center align-items-center form-check form-check-right">
              <i class="float-none w-auto  m-0 px-1 not-allowed"
                [ngStyle]="activeOption[option.id]? optionSelectedColor: traillingIconColor"
                [ngClass]="activeOption[option.id]?radioSelected:radio">
              </i>
            </div>

          </div>

          <div *ngIf="divider && index < noOptions-1 "
            attr.style="{{'border-bottom: solid 1px; border-color: ' + border_color + ' !important' }}">
          </div>

        </div>
      </div>
    </div>


    <div *ngIf=" mirror && isAndroid">
      <div class=" card m-0 rounded" [ngClass]="fontSmaller?'p-0': 'p-0'" [ngStyle]="setButtonBg" [ngClass]="shadow? 'shadow z-3':''"
      [style]="'border:' + outlined_border + '; border-color: ' + border_color + '!important'">
        <div *ngFor="let option of options; index as index;">
          <div class="row m-0 p-0 w-100 " style="height: {{cellHeight}}"
            [ngStyle]="activeOption[option.id]? optionBgColor: null" [ngClass]="optionPadding">

            <div *ngIf="trailing_icon"
              class="col-1 m-0 p-0 d-flex justify-content-center align-items-center form-check form-check-right">
              <i class="float-none w-auto  m-0 px-1  not-allowed"
                [ngStyle]="activeOption[option.id]?optionSelectedColor: traillingIconColor"
                [ngClass]="activeOption[option.id]?radioSelected:radio">
              </i>
            </div>

            <div [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"
              class="  m-0 p-0  d-flex justify-content-start align-items-center ">
              <p class=" m-0 p-0 text-wrap w-auto px-1 d-flex justify-content-start align-items-center"
                [ngStyle]="optionlTextColor">
                {{option.label}}
              </p>
            </div>

            <div *ngIf="leading_icon" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center">
              <i *ngIf="option.icon" class="float-none w-auto m-0 px-1 " [ngStyle]="leadingIconColor"
                class="{{option.icon}}"> </i>
              <img *ngIf="option.image && !option.icon" [src]="option.image" class="float-none w-auto m-0 px-1 "
                alt="avatar" class="rounded-circle" [ngStyle]="leadingIconColor">
            </div>

          </div>

          <div *ngIf="divider && index < noOptions-1 "
            attr.style="{{'border-bottom: solid 1px; border-color: ' + border_color + ' !important' }}">
          </div>

        </div>
      </div>
    </div>

    <!-- ********* iOS Active and Default **************** -->

    <div *ngIf=" !mirror && !isAndroid">
      <div class=" card m-0 rounded" [ngClass]="fontSmaller?'p-0': 'p-0'"  [ngStyle]="setButtonBg" [ngClass]="shadow? 'shadow z-3':''"
      [style]="'border:' + outlined_border + '; border-color: ' + border_color + '!important'">
      <div *ngFor="let option of options; index as index;">
          <div class="row m-0 p-0 w-100 " style="height: {{cellHeight}}"
            [ngStyle]="activeOption[option.id]? optionBgColor: null" [ngClass]="optionPadding">

            <div *ngIf="leading_icon" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center">
              <!-- <i *ngIf="option.icon" class="float-none w-auto m-0 px-1 " [ngStyle]="leadingIconColor"
                class="{{option.icon}}"> </i> -->
                <i *ngIf="option.icon" class="float-none w-auto m-0 px-1" [ngStyle]="leadingIconColor" [class]="builderService?.getIcon(option?.icon).type=='mir'?'material-icons-outlined':'material-icons'" >{{builderService?.getIcon(option?.icon).id}}</i>
              <img *ngIf="option.image && !option.icon" [src]="option.image" class="float-none w-auto m-0 px-1 "
                alt="avatar" class="rounded-circle" [ngStyle]="leadingIconColor">
            </div>

            <div [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"
              class="  m-0 p-0  d-flex justify-content-start align-items-center ">
              <p class=" m-0 p-0 text-wrap w-auto px-1 d-flex justify-content-start align-items-center"
                [ngStyle]="optionlTextColor">
                {{option.label}}
              </p>
            </div>

            <div *ngIf="trailing_icon"
              class="col-1 m-0 p-0 d-flex justify-content-center align-items-center form-check form-check-right">
              <i class="float-none w-auto  m-0 px-1 not-allowed"
                [ngStyle]="activeOption[option.id]? optionSelectedColor: traillingIconColor"
                [ngClass]="activeOption[option.id]?radioSelected:radio">
              </i>
            </div>

          </div>

          <div *ngIf="!divider && index < noOptions-1 "
          [style]="'border-bottom: solid 1px; border-color: ' + border_color + ' !important'">
        </div>

        </div>
      </div>
    </div>


    <div *ngIf=" mirror && !isAndroid">
      <div class=" card m-0 rounded" [ngClass]="fontSmaller?'p-0': 'p-0'" [ngClass]="outlined? 'shadow z-3':''"
        attr.style="{{ outlined_border + 'border-color: ' + border_default_color + ' !important' }}">
        <div *ngFor="let option of options; index as index;">
          <div class="row m-0 p-0 w-100 " style="height: {{cellHeight}}"
            [ngStyle]="activeOption[option.id]? optionBgColor: null" [ngClass]="optionPadding">

            <div *ngIf="trailing_icon"
              class="col-1 m-0 p-0 d-flex justify-content-center align-items-center form-check form-check-right">
              <i class="float-none w-auto  m-0 px-1 not-allowed"
                [ngStyle]="activeOption[option.id]? optionSelectedColor: traillingIconColor"
                [ngClass]="activeOption[option.id]?radioSelected:radio">
              </i>
            </div>

            <div [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"
              class="  m-0 p-0  d-flex justify-content-start align-items-center ">
              <p class=" m-0 p-0 text-wrap w-auto px-1 d-flex justify-content-start align-items-center"
                [ngStyle]="optionlTextColor">
                {{option.label}}
              </p>
            </div>

            <div *ngIf="leading_icon" class="col-1 m-0 p-0 d-flex justify-content-center align-items-center">
              <i *ngIf="option.icon" class="float-none w-auto m-0 px-1 " [ngStyle]="leadingIconColor"
                class="{{option.icon}}"> </i>
              <img *ngIf="option.image && !option.icon" [src]="option.image" class="float-none w-auto m-0 px-1 "
                alt="avatar" class="rounded-circle" [ngStyle]="leadingIconColor">
            </div>

          </div>

          <div *ngIf="divider && index < noOptions-1 "
            attr.style="{{'border-bottom: solid 1px; border-color: ' + border_color + ' !important' }}">
          </div>

        </div>
      </div>
    </div>


      <div *ngIf="helper || error" class="ps-1" >
        <p *ngIf="!error" class="p-0 m-0" [ngStyle]="helperColor">
        {{helper}}
        </p>
        <p *ngIf="error" class="p-0 m-0" [ngStyle]="errorColor">
          {{error}}
          </p>
      </div>
    </div>


