import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import customBuild from "src/ckeditor5/build/ckeditor";
import {
  EMAIL_VERIFICATION_MESSAGE,
  LOGIN_TYPES,
  SMS_VERIFICATION_MESSAGE,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from "@ckeditor/ckeditor5-core";
import { BuilderService } from "src/app/sharedservices/builder.service";
import {
  ClearVerificationTemplate,
  GetVerificationTemplate,
  SendVerificationTemplate,
} from "src/app/core/services/outbound";

@Component({
  selector: "app-gen-apploginmessage-html",
  templateUrl: "./gen-apploginmessage-html.component.html",
  styleUrls: ["./gen-apploginmessage-html.component.scss"],
})
export class GenAppLoginMessageHtmlComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) {}

  messageform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() page_id: string;
  button: MenuButton;
  mode: string;
  public Editor = customBuild;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  verifySubscription: any;
  verificationTemplateSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  component_ref: any;

  defaultConfig: EditorConfig = {
    fontColor: {
      colors: [
        "#000000",
        "#808080",
        "#c0c0c0",
        "#ffffff",
        "#800000",
        "#ff0000",
        "#800080",
        "#ff00ff",
        "#008000",
        "#00ff00",
        "#808000",
        "#ffff00",
        "#000080",
        "#0000ff",
        "#008080",
        "#00ffff",
      ],
      colorPicker: {
        format: "hex",
      },
    },
    toolbar: {
      items: ["undo", "redo", "Heading", "bold", "italic", "fontColor"],
    },
    language: "en",
  };

  login_types = LOGIN_TYPES;

  message: any;

  submitted = false;

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);
    this.messageform = this.formBuilder.group({
      lableControl: new UntypedFormControl(
        this.page_id === this.login_types.EMAIL
          ? EMAIL_VERIFICATION_MESSAGE
          : SMS_VERIFICATION_MESSAGE,
        [Validators.required]
      ),
      messageType: new UntypedFormControl("0"),
    });

    this.field_id = "lable";

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          this.buttonFunction();
        }
      }
    );

    this.verificationTemplateSubscription =
      this.ws.verificationTemplateContainer$.subscribe((data) => {
        // console.log("verifyData"+ verifyData);
        if (data && data.ref === this.component_ref) {
          this.message = { email: data.email, sms: data.sms };
          this.messageform = this.formBuilder.group({
            lableControl: new UntypedFormControl(
              this.page_id === this.login_types.EMAIL && data.email
                ? data.email
                : this.page_id === this.login_types.MSISDN && data.sms
                ? data.sms
                : this.page_id === this.login_types.EMAIL
                ? EMAIL_VERIFICATION_MESSAGE
                : SMS_VERIFICATION_MESSAGE,
              [Validators.required]
            ),
            messageType: new UntypedFormControl(
              (this.page_id === this.login_types.EMAIL && data.email) ||
              (this.page_id === this.login_types.EMAIL && data.sms)
                ? "1"
                : "0"
            ),
          });
        }
      });

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          this.ws.processMethod(
            new GetVerificationTemplate(this.component_ref)
          );
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;
    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  onSubmit() {
    this.submitted = true;
    if (this.messageform.invalid) {
      return;
    } else {
      const email =
        this.page_id === this.login_types.EMAIL
          ? this.messageform.value.lableControl
          : this.message.email;
      const sms =
        this.page_id === this.login_types.MSISDN
          ? this.messageform.value.lableControl
          : this.message.sms;

      const appName = this.messageform.value.lableControl.match(
        /(?:^|\W)\$app_name(?:$|\W)/gm
      );
      const code = this.messageform.value.lableControl.match(
        /(?:^|\W)\$code(?:$|\W)/gm
      );


      if (appName && code) {
        const ref = this.mdw.makeRef(16);
        this.ws.processMethod(
          new SendVerificationTemplate(email, sms, ref)
        );
      } else {
        let text = "Variables are missing";
        if (!appName) {
          text += " $app_name";
        }

        if (!code) {
          text += " $code";
        }

        this.messageform.controls["lableControl"].setErrors({ message: text });
      }
    }
  }

  buttonFunction() {
    this.button_label = this.builderService.getButtonSplashItem(
      this.button,
      "button_label",
      this.data.splashTab.param.splash.style
    );
    if (this.button_label) {
      this.messageform = this.formBuilder.group({
        lableControl: new UntypedFormControl(this.button_label),
      });
    }
  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.button.button_label = this.f.lableControl.value;
      this.updateButtonData();
    }
  }

  get f() {
    if (this.messageform && this.messageform.controls) {
      return this.messageform.controls;
    }
  }

  colorChange(event) {
    this.button.button_label_color = this.labelColor;
    this.updateButtonData();
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "splash" } },
      this.mode,
      true
    );
  }

  public onReady(editor: customBuild): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(editor.ui.view.toolbar.element!, element);
  }

  resetToDefulatMessage() {
    this.ws.processMethod(
      new ClearVerificationTemplate(
        this.page_id === this.login_types.EMAIL,
        this.page_id === this.login_types.MSISDN,
        this.component_ref
      )
    );
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.verificationTemplateSubscription) {
      this.verificationTemplateSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }
}
