import { MenuItem } from "../sidebar/menu.model";

export enum MENUIDS {
  APPBUILDER = 1,
  APPBUILDERLIST,
  MOBILESTPORESETTING,
  DASHBOARD,
  CHANNELS,
  CHATMANGER,
  CHAT,
  CHATSCHEDULE,
  POLLS,
  CONTENTMANGER,
  PAGES,
  ARTICLE,
  GROUPS,
  COMMERCE,
  MOBILESHOP,
  COLLECTIONS,
  PRODUCTS,
  ORDERS,
  BOOKINGANDEVENTS,
  BOOKINGS,
  EVENTS,
  CALENDARS,
  USERENGAGEMENT,
  COUPONS,
  CAMPAIGNS,
  USERMANAGEMENT,
  USERS,
  USERSTAGS,
  INTEGRATIONANDAUTOMATION,
  // CHATBOT,
  API,
  EXTENSIONS,
  INSTALLED,
  ADMIN,
  ADMINLIST,
  SETTINGS,
  SETTINGSLIST,
  SIGNUPFILTER,
  WHITELISTANDBLACKLIST,
  TAXSETTING,
  COMMERCESETTINGS,
  SHIPPINGSETTINGS
}
export const MENU: MenuItem[] = [
  {
    id: MENUIDS.APPBUILDER,
    label: "MENUITEMS.APPBUILDER.LIST.APP",
    icon: "bx bxs-user-detail",
    link: "/appbuilder",
    // subItems: [
      // {
      //   id: MENUIDS.APPBUILDERLIST,
      //   label: "MENUITEMS.APPBUILDER.LIST.APPTEST",
      //   link: "/appbuilder/test",
      //   parentId: MENUIDS.APPBUILDER,
      // },
      // {
      //   id: MENUIDS.APPBUILDERLIST,
      //   label: "MENUITEMS.APPBUILDER.LIST.APP",
      //   link: "/appbuilder",
      //   parentId: MENUIDS.APPBUILDER,
      // },
      // {
      //   id: MENUIDS.MOBILESTPORESETTING,
      //   label: "MENUITEMS.APPBUILDER.LIST.MOBILESTPORESETTING",
      //   link: "/appbuilder",
      //   parentId: MENUIDS.APPBUILDER,
      // },
    // ],
  },
  // {
  //   id: MENUIDS.DASHBOARD,
  //   label: "MENUITEMS.DASHBOARD.TEXT",
  //   icon: 'bx bx-home-circle',
  //   link: "/appbuilder",
  // },
  {
    id: MENUIDS.CHANNELS,
    label: "MENUITEMS.CHANNELS.TEXT",
    icon: 'mdi mdi-rss',
    link: "/channels",
  },
  {
    id: MENUIDS.CHATMANGER,
    label: "MENUITEMS.CHATMANGER.TEXT",
    icon: "bx bx-chat",
    subItems: [
      {
        id: MENUIDS.CHAT,
        label: "MENUITEMS.CHATMANGER.LIST.CHAT",
        link: "/message",
        parentId: MENUIDS.CHATMANGER,
      },
      {
        id: MENUIDS.CHATSCHEDULE,
        label: "MENUITEMS.CHATMANGER.LIST.SCHEDULE",
        link: "/mschedule",
        parentId: MENUIDS.CHATMANGER,
      },
      {
        id: MENUIDS.POLLS,
        label: "MENUITEMS.CHATMANGER.LIST.POLLS",
        link: "/polls",
        parentId: MENUIDS.CHATMANGER,
      },
      {
        id: MENUIDS.GROUPS,
        label: "MENUITEMS.CHATMANGER.LIST.GROUPS",
        link: "/groups",
        parentId: MENUIDS.CHATMANGER,
      },

    ],
  },
  {
    id: MENUIDS.COMMERCE,
    label: "MENUITEMS.COMMERCE.TEXT",
    icon: "bx bx-store",
    subItems: [
      {
        id: MENUIDS.MOBILESHOP,
        label: "MENUITEMS.COMMERCE.LIST.MOBILESHOP",
        link: "/stores",
        parentId: MENUIDS.COMMERCE,
      },
      {
        id: MENUIDS.COLLECTIONS,
        label: "MENUITEMS.COMMERCE.LIST.COLLECTIONS",
        link: "/collections",
        parentId: MENUIDS.COMMERCE,
      },
      {
        id: MENUIDS.PRODUCTS,
        label: "MENUITEMS.COMMERCE.LIST.PRODUCTS",
        link: "/products",
        parentId: MENUIDS.COMMERCE,
      },
      {
        id: MENUIDS.ORDERS,
        label: "MENUITEMS.COMMERCE.LIST.ORDERS",
        link: "/paymentOrders",
        parentId: MENUIDS.COMMERCE,
      },
    ],
  },
  {
    id: MENUIDS.BOOKINGANDEVENTS,
    label: "MENUITEMS.BOOKINGANDEVENTS.TEXT",
    icon: "mdi mdi-view-module",
    subItems: [
      {
        id: MENUIDS.BOOKINGS,
        label: "MENUITEMS.BOOKINGANDEVENTS.LIST.BOOKINGS",
        link: "/bookings",
        parentId: MENUIDS.BOOKINGANDEVENTS,
      },
      {
        id: MENUIDS.EVENTS,
        label: "MENUITEMS.BOOKINGANDEVENTS.LIST.EVENTS",
        link: "/events",
        parentId: MENUIDS.BOOKINGANDEVENTS,
      },
      {
        id: MENUIDS.CALENDARS,
        label: "MENUITEMS.BOOKINGANDEVENTS.LIST.CALENDARS",
        link: "/calendars",
        parentId: MENUIDS.BOOKINGANDEVENTS,
      }
    ],
  },
  {
    id: MENUIDS.USERENGAGEMENT,
    label: "MENUITEMS.USERENGAGEMENT.TEXT",
    icon: "mdi mdi-wallet-giftcard",
    subItems: [
      {
        id: MENUIDS.COUPONS,
        label: "MENUITEMS.USERENGAGEMENT.LIST.COUPONS",
        link: "/coupons",
        parentId: MENUIDS.USERENGAGEMENT,
      },
      {
        id: MENUIDS.CAMPAIGNS,
        label: "MENUITEMS.USERENGAGEMENT.LIST.CAMPAIGNS",
        link: "/campaigns",
        parentId: MENUIDS.USERENGAGEMENT,
      },
    ],
  },
  {
    id: MENUIDS.USERMANAGEMENT,
    label: "MENUITEMS.USERMANAGEMENT.TEXT",
    icon: "bx bxs-user-detail",
    subItems: [
      // {
      //   id: MENUIDS.USERSROLES,
      //   label: "MENUITEMS.USERMANAGEMENT.LIST.USERSROLES",
      //   link: "/roles",
      //   parentId: MENUIDS.USERMANAGEMENT,
      // },
      {
        id: MENUIDS.USERS,
        label: "MENUITEMS.USERMANAGEMENT.LIST.USERS",
        link: "/users",
        parentId: MENUIDS.USERMANAGEMENT,
      },
      {
        id: MENUIDS.USERSTAGS,
        label: "MENUITEMS.USERMANAGEMENT.LIST.USERSTAGS",
        link: "/tags",
        parentId: MENUIDS.USERMANAGEMENT,
      },
    ],
  },
  {
    id: MENUIDS.INTEGRATIONANDAUTOMATION,
    label: "MENUITEMS.INTEGRATIONANDAUTOMATION.TEXT",
    icon: "bx bxs-user-detail",
    subItems: [
      // {
      //   id: MENUIDS.CHATBOT,
      //   label: "MENUITEMS.INTEGRATIONANDAUTOMATION.LIST.CHATBOT",
      //   link: "/bots",
      //   parentId: MENUIDS.INTEGRATIONANDAUTOMATION,
      // },
      {
        id: MENUIDS.API,
        label: "MENUITEMS.INTEGRATIONANDAUTOMATION.LIST.API",
        link: "/api",
        parentId: MENUIDS.INTEGRATIONANDAUTOMATION,
      },
    ],
  },
  {
    id: MENUIDS.EXTENSIONS,
    label: "MENUITEMS.EXTENSIONS.TEXT",
    icon: "bx bxs-user-detail",
    subItems: [
      {
        id: MENUIDS.INSTALLED,
        label: "MENUITEMS.EXTENSIONS.LIST.INSTALLED",
        link: "/installed",
        parentId: MENUIDS.EXTENSIONS,
      }
    ],
  },
  {
    id: MENUIDS.CONTENTMANGER,
    label: "MENUITEMS.CONTENTMANGER.TEXT",
    icon: "mdi mdi-view-module",
    subItems: [
      {
        id: MENUIDS.PAGES,
        label: "MENUITEMS.CONTENTMANGER.LIST.PAGES",
        link: "/pages",
        parentId: MENUIDS.CONTENTMANGER,
      },
      {
        id: MENUIDS.ARTICLE,
        label: "MENUITEMS.CONTENTMANGER.LIST.ARTICLE",
        link: "/article",
        parentId: MENUIDS.CONTENTMANGER,
      },
    ],
  },
  {
    id: MENUIDS.ADMIN,
    label: "MENUITEMS.ADMIN.TEXT",
    icon: "bx bx-lock-alt",
    subItems: [
      // {
      //   id: 11,
      //   label: "MENUITEMS.ADMIN.LIST.API",
      //   link: "/admin/api",
      //   parentId: 10,
      // },
      {
        id: MENUIDS.ADMINLIST,
        label: "MENUITEMS.ADMIN.LIST.TRANSFER",
        link: "/admin/transfer",
        parentId: MENUIDS.ADMIN,
      },
    ],
  },
  {
    id: MENUIDS.SETTINGS,
    label: "MENUITEMS.SETTINGS.TEXT",
    icon: "mdi mdi-cog",
    subItems: [
      // {
      //   id: MENUIDS.SETTINGSLIST,
      //   label: "MENUITEMS.SETTINGS.LIST.CUSTOMFIELDS",
      //   link: "/custom-fields",
      //   parentId: MENUIDS.SETTINGS,
      // },
      {
        id: MENUIDS.SIGNUPFILTER,
        label: "MENUITEMS.SETTINGS.LIST.SIGNUPFILTER",
        link: "/signup-filter",
        parentId: MENUIDS.SETTINGS,
      },
      {
        id: MENUIDS.WHITELISTANDBLACKLIST,
        label: "MENUITEMS.SETTINGS.LIST.WHITELISTANDBLACKLIST",
        link: "/whitelist-and-blacklist",
        parentId: MENUIDS.SETTINGS,
      },
      {
        id: MENUIDS.TAXSETTING,
        label: "MENUITEMS.SETTINGS.LIST.TAXSETTING",
        link: "/tax-settings",
        parentId: MENUIDS.SETTINGS,
      },
      {
        id: MENUIDS.COMMERCESETTINGS,
        label: "MENUITEMS.SETTINGS.LIST.COMMERCESETTINGS",
        link: "/commerce-settings",
        parentId: MENUIDS.SETTINGS,
      },
      {
        id: MENUIDS.SHIPPINGSETTINGS,
        label: "MENUITEMS.SETTINGS.LIST.SHIPPINGSETTINGS",
        link: "/shipping-settings",
        parentId: MENUIDS.SETTINGS,
      },
    ],
  },
];
