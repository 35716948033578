<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">

      <div class="col-12 w-100" >
        <label
          for="text-color"
          class="offcanvas-header-text w-100"
          style="text-align: left"
        >
          Label
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
        <form class="needs-validation w-100" [formGroup]="labelform">
          <input
            type="text"
            id="lablecolor"
            formControlName="lableControl"
            class="form-control input-lg offcanvas-primary border-0"
            [value]='button_label'
            (change)="buttonLabel()"
            placeholder="Lable"
            [ngClass]="{'is-invalid': f.lableControl.errors}"
          />
          <div *ngIf="f.lableControl.errors" class="invalid-feedback">
            <span *ngIf="f.lableControl.errors.required"> Field is required</span>
            <span *ngIf="f.lableControl.errors.maxlength"> Actual length is {{f.lableControl.errors.maxlength.actualLength}} required not more than {{f.lableControl.errors.maxlength.requiredLength}} chars.</span>
            </div>
        </form>
        </div>
      </div>

      <div *ngIf="config?.color"  class="row d-flex justify-content-between align-items-center mb-3">
        <div class="col-8">
          <label for="bg-color" class="offcanvas-header-text w-100" style="text-align: left">
            Custom Color
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="bg-color"
              class="form-control input-lg offcanvas-primary border-0"
              (change)="colorChange($event, true)"
              [value]="labelColor"
              [readOnly]="!enableCustomColor"

            />
            <span
              class="input-group-text p-1 border-0 offcanvas-primary"
            >
              <span
                class="input-group-text colorpicker-input-addon h-100"
                [style.background]="labelColor"
                [cpOutputFormat]="'hex'"
                [(colorPicker)]="labelColor"
                [cpPosition]="'bottom-left'"
                (colorPickerChange)="colorChange($event, false)"
                [cpDisabled]="!enableCustomColor"
                >
                </span>
            </span>
          </div>
        </div>
      <div class="col-4 d-flex justify-content-end">
        <ui-switch [color]="offcanvasColors.activeColor" [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor" [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor" [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off" [checked]="enableCustomColor" checkedLabel="On" size="small" class="me-1"
          (change)="toggle($event)"></ui-switch>
      </div>
    </div>
    </div>
  </div>
</div>
