import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import customBuild from "src/ckeditor5/build/ckeditor";
import {
  VendorsNames,
  VendorsTypes,
  mobCountries,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  MenuButton,
  Smtp,
  Tab,
  ThirdPartyInfoUI,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";
import {
  GetMessagingVendor,
  SaveThirdPartyInfoMethod,
  TestThirdPartyInfoMethod,
} from "src/app/core/services/outbound";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import Swal from "sweetalert2";

@Component({
  selector: "app-gen-apploginvendors-mobile",
  templateUrl: "./gen-apploginvendors-mobile.component.html",
  styleUrls: ["./gen-apploginvendors-mobile.component.scss"],
})
export class GenAppLoginVendorsMobileComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService,
    private modalService: BsModalService
  ) {}

  vendorform: UntypedFormGroup; // bootstrap validation form
  vendorTestForm: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() page_id: string;
  button: MenuButton;
  mode: string;
  public Editor = customBuild;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  verifySubscription: any;
  messagingVendorSubscription: any;
  testedMessagingSubscription: any;
  thirdPartyInfoSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  component_ref: any;

  message: any;

  submitted = false;
  vendorTestSubmitted = false;

  loadding = false;

  smtpOpj: Smtp;
  vendorsNames = VendorsNames;
  vendorsTypes = VendorsTypes;
  app_info: any = {};
  showTest = false;
  modalRef?: BsModalRef;
  mobCountries = mobCountries;
  oldVendor: any;

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);
    this.vendorform = this.formBuilder.group({
      name: new UntypedFormControl(this.vendorsNames.VONAGE, [
        Validators.required,
      ]),
      apiKey: new UntypedFormControl("", [Validators.required]),
      apiBaseURL: new UntypedFormControl("", [Validators.required]),
      type: new UntypedFormControl(this.vendorsTypes.SMS, [
        Validators.required,
      ]),
    });

    this.vendorTestForm = this.formBuilder.group({
      msisdn: new UntypedFormControl("", [Validators.required]),
      country: new UntypedFormControl("", [Validators.required]),
      dailCode: new UntypedFormControl(""),
    });

    this.field_id = "lable";

    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

          this.buttonFunction();
        }
      }
    );

    this.messagingVendorSubscription =
      this.ws.messagingVendorContainer$.subscribe((data) => {
        // console.log("verifyData"+ verifyData);
        if (data) {
          if (data.vendor) {
            if (data.vendor.length > 0) {
              this.showTest = true;
            } else {
              this.showTest = false;
            }
            this.vendorform = this.formBuilder.group({
              name: new UntypedFormControl(
                (data.vendor[0] && data.vendor[0].name) ||
                  this.vendorform.value.name ||
                  this.vendorsNames.VONAGE,
                [Validators.required]
              ),
              apiKey: new UntypedFormControl(
                (data.vendor[0] && data.vendor[0].access.user) ||
                  (data.vendor[0] && data.vendor[0].access.api_key) ||
                  "",

                [Validators.required]
              ),
              apiBaseURL: new UntypedFormControl(
                (data.vendor[0] && data.vendor[0].access.password) ||
                  (data.vendor[0] && data.vendor[0].access.base_url) ||
                  "",

                [Validators.required]
              ),
              type: new UntypedFormControl(
                (data.vendor[0] && data.vendor[0].type) ||
                  this.vendorsTypes.SMS,

                [Validators.required]
              ),
            });
          }
        }
      });

    this.thirdPartyInfoSubscription =
      this.ws.thirdPartyInfoContainer$.subscribe((data) => {
        // console.log("verifyData"+ verifyData);
        if (data) {
          this.showTest = true;
          this.submitted = false;
          if (this.oldVendor && this.oldVendor.name !== data.vendors[0].name) {
            Swal.fire(
              "Vendor's Note",
              `This configuration step will take 5 minutes to be completed & activated.`,
              "warning"
            );
          }
        }
      });
    this.testedMessagingSubscription =
      this.ws.testedMessagingVendorContainer$.subscribe((data) => {
        // console.log("verifyData"+ verifyData);
        if (data) {
          Swal.fire(
            "Good job!",
            `The verification code has been sent to the number you entered. The verification code is ${data.tac}. If you didn't receive the code, please check your configuration and/or your credit balance with the 3rd party vendor.`,
            "success"
          );

          this.vendorTestSubmitted = false;
        }
      });
    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          const appInfo = this.indexDBService.getItem("item", "app_info");
          appInfo.onsuccess = (event) => {
            if (appInfo.result) {
              this.app_info = appInfo.result;
              if (this.app_info.vendors && this.app_info.vendors.length > 0) {
                this.getMessageVendor(this.app_info.vendors[0].name);
              }
            }
          };
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;
    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  onSubmit() {
    this.submitted = true;
    if (this.vendorform.invalid) {
      return;
    } else {
      this.setSmtpServer();
    }
  }

  async setSmtpServer() {
    const newVendor: ThirdPartyInfoUI = {
      name: this.vendorform.value.name,
      type: this.vendorform.value.type,
      access: {},
    };

    if (this.vendorform.value.name === this.vendorsNames.VONAGE) {
      newVendor.access.user = this.vendorform.value.apiKey;
      newVendor.access.password = this.vendorform.value.apiBaseURL;
    } else if (this.vendorform.value.name === this.vendorsNames.INFOBIP) {
      newVendor.access.api_key = this.vendorform.value.apiKey;
      newVendor.access.base_url = this.vendorform.value.apiBaseURL;
    }

    this.oldVendor =
      this.app_info["vendors"] && this.app_info["vendors"].length > 0
        ? this.app_info["vendors"][0]
        : null;

    this.app_info["vendors"] = [
      {
        name: this.vendorform.value.name,
        type: this.vendorform.value.type,
      },
    ];
    this.indexDBService.updateItem("item", this.app_info);
    this.ws.processMethod(
      new SaveThirdPartyInfoMethod([newVendor], this.component_ref)
    );
  }

  buttonFunction() {
    this.button_label = this.builderService.getButtonSplashItem(
      this.button,
      "button_label",
      this.data.splashTab.param.splash.style
    );
    if (this.button_label) {
      this.vendorform = this.formBuilder.group({
        lableControl: new UntypedFormControl(this.button_label),
      });
    }
  }

  buttonLabel() {
    if (this.f && this.f.lableControl.valid) {
      this.button.button_label = this.f.lableControl.value;
      this.updateButtonData();
    }
  }

  get f() {
    if (this.vendorform && this.vendorform.controls) {
      return this.vendorform.controls;
    }
  }
  get vendorTest() {
    if (this.vendorTestForm && this.vendorTestForm.controls) {
      return this.vendorTestForm.controls;
    }
  }

  colorChange(event) {
    this.button.button_label_color = this.labelColor;
    this.updateButtonData();
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "splash" } },
      this.mode,
      true
    );
  }

  public onReady(editor: customBuild): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(editor.ui.view.toolbar.element!, element);
  }

  selectVendor() {
    this.getMessageVendor(this.vendorform.value.name);
  }

  getMessageVendor(name) {
    this.ws.processMethod(new GetMessagingVendor(name, this.component_ref));
  }

  toggleVendorTest(centerDataModal) {
    this.modalRef = this.modalService.show(centerDataModal, {
      class: "modal-dialog-centered",
    });
  }

  selectCountry() {
    const dailIOS = this.vendorTestForm.value.country.split(",");
    this.vendorTestForm.patchValue({
      dailCode: "+" + dailIOS[0],
    });
  }

  onVendorTestSubmit() {
    this.vendorTestSubmitted = true;
    if (this.vendorTestForm.invalid) {
      return;
    } else {
      const dailIOS = this.vendorTestForm.value.country.split(",");
      const dial = dailIOS[0].replaceAll(" ", "");
      const countryCode = dial.split("-")[0];
      const pattern = new RegExp("^0+");
      const dialNo = dial.replaceAll("-", "");
      const testVendor = {
        countryCode: countryCode,
        msisdn: dialNo + this.vendorTestForm.value.msisdn.replace(pattern, ""),
        name: this.vendorform.value.name,
        type: this.vendorform.value.type,
      };
      this.ws.processMethod(
        new TestThirdPartyInfoMethod(testVendor, this.component_ref)
      );
    }
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.messagingVendorSubscription) {
      this.messagingVendorSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
    if (this.testedMessagingSubscription) {
      this.testedMessagingSubscription.unsubscribe();
    }
    if (this.thirdPartyInfoSubscription) {
      this.thirdPartyInfoSubscription.unsubscribe();
    }
  }
}
