import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  LOGIN_TYPES,
  mobCountries,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import {
  GetTacDemoAccount,
  RegisterDemoAccount,
  SignUpDemoAccount,
} from "src/app/core/services/outbound";
import { v4 as uuidv4 } from "uuid";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";

@Component({
  selector: "app-gen-verificationaccount",
  templateUrl: "./gen-verificationaccount.component.html",
  styleUrls: ["./gen-verificationaccount.component.scss"],
})
export class GenVerificationAccountComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private _ref: ChangeDetectorRef,
  ) {}

  verificationform: UntypedFormGroup; // bootstrap validation form
  @ViewChild("verifyAccount") verifyAccount;
  @Input() button_id: string;
  @Input() page_id: string;
  button: MenuButton;
  mode: string;
  // default values
  offCanvasSubscription: any;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  verifySubscription: any;
  verificationTemplateSubscription: any;
  signedUpDemoAccountSubscription: any;
  registeredDemoAccountSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  component_ref: any;

  message: any;

  submitted = false;
  appInfo: any = { id: "app_info" };

  mobCountries = mobCountries;

  login_types = LOGIN_TYPES;

  modalRef?: BsModalRef;

  inValid: boolean;
  app_info: any = {};

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "50px",
    },
  };

  demoAccData;

  refreshIntervalId:any;
  seconds=30;

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);

    this.verificationform = this.formBuilder.group({
      countryCode: new UntypedFormControl(""),
      countryIso: new UntypedFormControl(""),
      deviceId: new UntypedFormControl(""),
      countryName: new UntypedFormControl(""),
      email: new UntypedFormControl(""),
      msisdn: new UntypedFormControl(""),
      os: new UntypedFormControl("web"),
      osVersion: new UntypedFormControl("1"),
      type: new UntypedFormControl(
        this.page_id === this.login_types.EMAIL ? "email" : "sms_http"
      ),
      country: new UntypedFormControl("", [Validators.required]),
      tac: new UntypedFormControl(""),
      dailCode: new UntypedFormControl(""),
    });

    this.signedUpDemoAccountSubscription =
      this.ws.signedUpDemoAccountContainer$.subscribe((data) => {
        if (data) {
          this.modalRef = this.modalService.show(this.verifyAccount, {
            class: "modal-dialog-centered",
          });
          clearInterval(this.refreshIntervalId);
        }
      });
    this.registeredDemoAccountSubscription =
      this.ws.registeredDemoAccountContainer$.subscribe((data) => {
        if (data) {
          this.modalRef.hide();
          this.setAppInfo();
        }
      });

    this.verifySubscription = this.ws.verifyContainer$.subscribe(
      (verifyData) => {
        // console.log("verifyData"+ verifyData);
        if (verifyData) {
          const appInfo = this.indexDBService.getItem("item", "app_info");
          appInfo.onsuccess = (event) => {
            if (appInfo.result) {
              this.app_info = appInfo.result;
              if (
                this.app_info &&
                this.app_info.demoAccData &&
                this.app_info.demoAccData.type === this.page_id
              ) {
                this.demoAccData = this.app_info.demoAccData;
                this.verificationform = this.formBuilder.group({
                  countryCode: new UntypedFormControl(
                    this.app_info.demoAccData.countryDial || ""
                  ),
                  countryIso: new UntypedFormControl(
                    this.app_info.demoAccData.isoCode || ""
                  ),
                  deviceId: new UntypedFormControl(""),
                  countryName: new UntypedFormControl(
                    this.app_info.demoAccData.countryName || ""
                  ),
                  email: new UntypedFormControl(
                    this.app_info.demoAccData.email || "",
                    [Validators.required]
                  ),
                  msisdn: new UntypedFormControl(
                    this.app_info.demoAccData.msisdn || ""
                  ),
                  os: new UntypedFormControl("web"),
                  osVersion: new UntypedFormControl("1"),
                  type: new UntypedFormControl(
                    this.page_id === this.login_types.EMAIL
                      ? "Email"
                      : "sms_http"
                  ),
                  country: new UntypedFormControl("", [Validators.required]),
                  tac: new UntypedFormControl(""),
                  dailCode: new UntypedFormControl(""),
                });
              }
            }
          };
        }
      }
    );

    this.field_id = "lable";

    this.sortedButtonList = [];
    this.buttonList = {};
  }

  get f() {
    if (this.verificationform && this.verificationform.controls) {
      return this.verificationform.controls;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.verificationform.invalid) {
      return;
    } else {
      this.setVerification();
    }
  }

  setVerification() {
    const uuid = uuidv4();
    const dialIso = this.verificationform.value.country.split(",");
    const countryCode = dialIso[0].replaceAll("-", "").replaceAll(" ", "");
    this.verificationform.patchValue({
      deviceId: uuid,
      countryIso: dialIso[1],
      countryCode: countryCode,
      countryName: dialIso[2],
    });
    const pattern = new RegExp("^0+");
    const msisdn = this.verificationform.value.msisdn.replace(pattern, "");
    const contact =
      this.page_id === this.login_types.EMAIL
        ? this.verificationform.value.email
        : countryCode + msisdn;
    this.ws.processMethod(
      new SignUpDemoAccount(
        this.verificationform.value.deviceId,
        this.verificationform.value.os,
        this.verificationform.value.osVersion,
        contact,
        contact,
        this.verificationform.value.type,
        this.verificationform.value.countryCode,
        this.verificationform.value.countryIso,
        this.component_ref
      )
    );
  }

  onOtpChange(otp: any) {
    if (otp.toString().length != 6) {
      this.inValid = true;
      return;
    }
    this.inValid = false;
    // this.otp = otp;
    this.verificationform.patchValue({
      tac: otp,
    });
  }

  registerDemoAccount() {
    const dialIso = this.verificationform.value.country.split(",");
    const countryCode = dialIso[0].replaceAll("-", "").replaceAll(" ", "");
    const pattern = new RegExp("^0+");
    const msisdn = this.verificationform.value.msisdn.replace(pattern, "");
    const contact =
      this.page_id === this.login_types.EMAIL
        ? this.verificationform.value.email
        : countryCode + msisdn;
    this.ws.processMethod(
      new RegisterDemoAccount(
        this.verificationform.value.deviceId,
        this.verificationform.value.os,
        this.verificationform.value.osVersion,
        contact,
        contact,
        this.verificationform.value.type,
        this.verificationform.value.countryCode,
        this.verificationform.value.tac,
        this.component_ref
      )
    );
  }

  setAppInfo() {
    const contact =
      this.page_id === this.login_types.EMAIL
        ? this.verificationform.value.email
        : this.verificationform.value.msisdn;
    this.app_info["demoAccData"] = {
      countryDial: this.verificationform.value.countryCode,
      countryName: this.verificationform.value.countryName,
      email: contact,
      msisdn: contact,
      type: this.page_id,
      isoCode: this.verificationform.value.countryIso,
    };
    this.demoAccData = this.app_info.demoAccData;
    this.indexDBService.updateItem("item", this.app_info);
  }

  remove() {
    delete this.app_info["demoAccData"];
    this.demoAccData = null;
    this.indexDBService.updateItem("item", this.app_info);
  }

  selectCountry() {
    const dailIOS = this.verificationform.value.country.split(",");
    this.verificationform.patchValue({
      dailCode: "+" + dailIOS[0],
    });
  }

  resendTak() {
    const dialIso = this.verificationform.value.country.split(",");
    const countryCode = dialIso[0].replaceAll("-", "").replaceAll(" ", "");
    const pattern = new RegExp("^0+");
    const msisdn = this.verificationform.value.msisdn.replace(pattern, "");
    const contact =
      this.page_id === this.login_types.EMAIL
        ? this.verificationform.value.email
        : countryCode + msisdn;
    this.ws.processMethod(
      new GetTacDemoAccount(countryCode, contact, this.component_ref)
    );
    clearInterval(this.refreshIntervalId);
    this.seconds=30;
    this.refreshIntervalId= setInterval(() => this.countdown(), 1000);
  }

  countdown() {
    let sec = this.seconds--;
    let timer = sec < 10 ? `0${sec}` : sec;
    document.getElementById("seconds").innerHTML = `(00:00:${timer})`;
    if(sec == 0){
      clearInterval(this.refreshIntervalId);
    }
    this._ref.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.verificationTemplateSubscription) {
      this.verificationTemplateSubscription.unsubscribe();
    }
    if (this.registeredDemoAccountSubscription) {
      this.registeredDemoAccountSubscription.unsubscribe();
    }
    if (this.signedUpDemoAccountSubscription) {
      this.signedUpDemoAccountSubscription.unsubscribe();
    }
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }
}
