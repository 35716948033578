import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { c1, c10, c5 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavBarComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) { }

  @Input() fontResize = false;
  @Input() fontSmaller = false;
  @Input() tab_id;

  updateBuilderComponentSubscribtion: any;
  colorsComponentSubscription: any;
  // default values

  tabs: Tab[] = [];
  colors: any = {};
  home;
  bg_color: string;
  text_color: string;
  isAndroid: string;
  active_icon_color: string
  active_title_color: string
  default_color: string
  currentTabSubscription: any;
  viewSubscription: any;
  sortedTabList: any =[];
  tabList: any;
  cat: string;
  current_tab_id: any;
  data: any;
  mode: string;
  current_tab: string;
  updateBuilderComponentSubscription: any;
  wide: boolean;
  theme: any;
  makeItSmaller: boolean;
  c1_Android: string = c1.ANDRIOD
  c1_Ios: string =c1.IOS
  color_schema: any;
  color_schema_ios: any;
  light: boolean = true;


  ngOnInit() {
    this.mode = localStorage.getItem("mode");
    this.data = {};
    this.data["sortedTabList"] = [];
    this.data["tabList"] = {};
    try {
      this.mobileTabBarInitialize()
    } catch (error) {
      if (localStorage.getItem("ndbx")) {
        setTimeout(async () => {
          this.mobileTabBarInitialize()
        }, 2000);
      } else {
        this.mobileTabBarInitialize()
      }
    }

    this.updateBuilderComponentSubscription =  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {
        this.mobileTabBarInitialize()

        this.wide = localStorage.getItem("mode")[0]==c1.ANDRIOD?
        localStorage.getItem("mode")[4]==c5.WIDE? true: false :
        localStorage.getItem("mode")[9]==c10.WIDE? true: false;

  }});

      this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe(
      (data) => {
        if (data && data["current_tab"]) {
          this.data = data;
          this.sortedTabList = structuredClone(data["sortedTabList"]);
          this.current_tab_id = data ["current_tab"].id;

          let index = this.sortedTabList.findIndex((e) => e.id == '0');

           if (index!== -1){
            this.sortedTabList.splice(index, 1);
          }
          this.wide = this.isAndroid == c1.ANDRIOD ?

          localStorage.getItem("mode")[4]==c5.WIDE? true: false
          :
          localStorage.getItem("mode")[9]==c10.WIDE? true: false;

          this.makeItSmaller = this.sortedTabList.length > 4? true: false;

        }
      })

      this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
        (data) => {
          if (data) {
            this.colors = data;
            this.light = data.light
            this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
            this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark
            this.colorIntialiaze();
          }
        })


    }
  mobileTabBarInitialize() {
    this.isAndroid = localStorage.getItem("mode")[0];
    this.theme = this.mdw.mobileTheme()
    const appHome = this.indexDBService.getItem("app", "home");
    appHome.onsuccess = (event) => {
      if (appHome.result) {
        this.colors.color_schema = appHome.result.app.tabs.color_schema;
        this.colors.color_schema_ios = appHome.result.app.tabs.color_schema_ios;
        this.color_schema = this.light? this.colors.color_schema.schemes.light:this.colors.color_schema.schemes.dark ;
        this.color_schema_ios = this.light? this.colors.color_schema_ios.schemes.light:  this.colors.color_schema_ios.schemes.dark

        this.colorIntialiaze();

  }

  }}



  colorIntialiaze(){
    if (this.isAndroid === c1.ANDRIOD) {
      this.bg_color = this.color_schema.m2ASurface;
      this.default_color = this.color_schema.m2OnSurfaceVariant;
      this.active_title_color = this.color_schema.m2Primary;
      this.active_icon_color = this.color_schema.m2Primary;

    } else if (this.isAndroid === c1.IOS) {
      this.bg_color = this.color_schema_ios.onPrimary;
      this.default_color = this.color_schema_ios.surfaceVariant;
      this.active_title_color = this.color_schema_ios.primary;
      this.active_icon_color = this.color_schema_ios.primary;
}

  }
    titleStyle(tab_id) {
      if (tab_id === this.current_tab_id &&  this.bg_color &&  this.active_title_color ) {
         return {  color: this.active_title_color  };
      } else if (tab_id !== this.current_tab_id &&  this.bg_color &&  this.default_color) {
        return { color: this.default_color };
      }
    }


   iconStyle(tab_id) {
      if (tab_id === this.current_tab_id &&  this.bg_color &&  this.active_icon_color ) {
        return {  color: this.active_icon_color  };
      } else if (tab_id !== this.current_tab_id &&  this.bg_color &&  this.default_color) {
        return { color: this.default_color };
      }
    }


  get backColors() {
    if (this.colors) {
      return {    background: this.bg_color };
    }
  }

  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  tabTrackBy(index, tab){
    return tab.tab_version;
  }

  ngOnDestroy() {

    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }

    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }

    if (this.colorsComponentSubscription) {
      this.colorsComponentSubscription.unsubscribe();
    }
  }
}
