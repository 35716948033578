<div
  class="offcanvas offcanvas-end navbar-brand-box p-0 z-1"
  style="top: 70px; width: 400px !important; visibility: visible !important"
  data-bs-scroll="true"
  data-bs-backdrop="false"
  tabindex="-1"
  id="offcanvasScrollingTabsConf"
  aria-labelledby="offcanvasScrollingLabel"
>
  <div class="offcanvas-header ps-0 mb-0 offcanvas-divider">
    <div id="sidebar-menu" class="offcanvas-menu p-0">
      <ul class="metismenu list-unstyled">
        <li draggable="false">
          <a class="side-nav-link-ref">
            <!-- <span>  Component: {{title}}</span> -->
            <h5 class="mb-0">
              {{ title }}
            </h5>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="text-reset enablePointer"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      (click)="hideOffcanvas()"
    >
      <i class="mdi mdi-close font-size-22 icon-close"></i>
    </div>
  </div>
  <div class="offcanvas-body p-0 m-0">
    <ngx-simplebar data-simplebar="init">
      <ng-container *ngIf="offcanvas_data.length == 1">
        <ng-container *ngFor="let tab of offcanvas_data">
          <div id="sidebar-menu" class="offcanvas-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled">
              <!-- {{menuItems}} -->
              <ng-container *ngFor="let cat of tab?.data; let i = index">
                <li
                  *ngIf="cat.show"
                  draggable="false"
                  class="bg-offcanvas-header"
                >
                  <a class="side-nav-link-ref">
                    <span>{{ cat.cat }}</span>
                  </a>
                </li>
                <div *ngFor="let item of cat?.list; let j = index">
                  <div
                    *ngIf="item?.stickyPost"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-sticky-post-component
                      [tab_id]="tab_id"
                    ></app-tab-sticky-post-component>
                  </div>


                  <div
                    *ngIf="item?.postFooter"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-post-footer-component
                      [tab_id]="tab_id"
                    ></app-tab-post-footer-component>
                  </div>
                  <div
                    *ngIf="item?.enableAds"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-enable-ads-component
                      [tab_id]="tab_id"
                    ></app-tab-enable-ads-component>
                  </div>
                  <div
                    *ngIf="item?.enableSetGroup"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-set-group-component
                      [tab_id]="tab_id"
                    ></app-tab-set-group-component>
                  </div>
                  <div
                    *ngIf="item?.enableSetBot"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-set-bot-component
                      [tab_id]="tab_id"
                    ></app-tab-set-bot-component>
                  </div>
                  <div
                    *ngIf="item?.enableVideoCall"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-enable-video-call-component
                      [tab_id]="tab_id"
                    ></app-tab-enable-video-call-component>
                  </div>
                  <div
                    *ngIf="item?.video"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-video-component
                      [tab_id]="tab_id"
                    ></app-tab-video-component>
                  </div>
                  <div
                    *ngIf="item?.invitationLink"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-invitation-link-component
                      [tab_id]="tab_id"
                    ></app-tab-invitation-link-component>
                  </div>
                  <div
                    *ngIf="item?.qr"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-qr-component
                      [tab_id]="tab_id"
                    ></app-tab-qr-component>
                  </div>
                  <div
                    *ngIf="item?.webView"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-web-view-component
                      [tab_id]="tab_id"
                    ></app-tab-web-view-component>
                  </div>
                  <div
                    *ngIf="item?.search"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-search-component
                      [tab_id]="tab_id"
                    ></app-tab-search-component>
                  </div>
                  <div
                    *ngIf="item?.mapSearch"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-map-search-component
                      [tab_id]="tab_id"
                    ></app-tab-map-search-component>
                  </div>
                  <div
                    *ngIf="item?.tabInfo"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-info-component
                      [tab_id]="tab_id"
                    ></app-tab-info-component>
                  </div>
                  <div
                    *ngIf="item?.chatWithAdmin"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-enable-chat-with-admin-component
                      [tab_id]="tab_id"
                    ></app-tab-enable-chat-with-admin-component>
                  </div>
                  <div
                    *ngIf="item?.chatWithAdminIcon"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-chat-with-admin-icon-component
                      [tab_id]="tab_id"
                    ></app-tab-chat-with-admin-icon-component>
                  </div>
                  <div
                    *ngIf="item?.postTags"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-post-tags-component
                      [tab_id]="tab_id"
                    ></app-tab-post-tags-component>
                  </div>
                  <div
                    *ngIf="item?.contextMenu"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-post-context-menu-component
                      [tab_id]="tab_id"
                    ></app-tab-post-context-menu-component>
                  </div>
                  <div
                    *ngIf="item?.bubble_theme"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-bubble-themes-component
                      [tab_id]="tab_id"
                    ></app-tab-bubble-themes-component>
                  </div>
                  <div
                    *ngIf="item?.enable_invitation_links"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-enable-invitation-link-component
                      [tab_id]="tab_id"
                    ></app-tab-enable-invitation-link-component>
                  </div>
                  <div
                    *ngIf="item?.channel_admin_talk"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-channel-chat-with-admin-component
                      [tab_id]="tab_id"
                    ></app-tab-channel-chat-with-admin-component>
                  </div>
                  <div
                    *ngIf="item?.channel_footer"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-channel-footer-component
                      [tab_id]="tab_id"
                    ></app-tab-channel-footer-component>
                  </div>
                  <div
                    *ngIf="item?.channel_context_menu"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-tab-channel-context-menu-component
                      [tab_id]="tab_id"
                    ></app-tab-channel-context-menu-component>
                  </div>
                </div>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>
      <tabset
        [justified]="true"
        class="nav-tabs nav-tabs-custom nav-justified tabPrimaryFilled"
        *ngIf="offcanvas_data.length > 1"
      >
        <ng-container *ngFor="let tab of offcanvas_data">
          <tab [heading]="tab?.tab">
            <div id="sidebar-menu" class="offcanvas-menu">
              <!-- Left Menu Start -->
              <ul class="metismenu list-unstyled">
                <!-- {{menuItems}} -->

                <ng-container *ngFor="let cat of tab?.data">
                  <li
                    *ngIf="cat.show"
                    draggable="false"
                    class="bg-offcanvas-header"
                  >
                    <a class="side-nav-link-ref">
                      <span>{{ cat.cat }}</span>
                    </a>
                  </li>

                  <div *ngFor="let item of cat?.list; let j = index">
                    <div
                      *ngIf="item?.stickyPost"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-sticky-post-component
                        [tab_id]="tab_id"
                      ></app-tab-sticky-post-component>
                    </div>
                    <div
                      *ngIf="item?.postFooter"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-post-footer-component
                        [tab_id]="tab_id"
                      ></app-tab-post-footer-component>
                    </div>
                    <div
                      *ngIf="item?.enableAds"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-enable-ads-component
                        [tab_id]="tab_id"
                      ></app-tab-enable-ads-component>
                    </div>
                    <div
                      *ngIf="item?.enableSetGroup"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-set-group-component
                        [tab_id]="tab_id"
                      ></app-tab-set-group-component>
                    </div>
                    <div
                      *ngIf="item?.enableSetBot"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-set-bot-component
                        [tab_id]="tab_id"
                      ></app-tab-set-bot-component>
                    </div>
                    <div
                      *ngIf="item?.enableVideoCall"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-enable-video-call-component
                        [tab_id]="tab_id"
                      ></app-tab-enable-video-call-component>
                    </div>

                    <div
                      *ngIf="item?.video"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-video-component
                        [tab_id]="tab_id"
                      ></app-tab-video-component>
                    </div>
                    <div
                      *ngIf="item?.invitationLink"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-invitation-link-component
                        [tab_id]="tab_id"
                      ></app-tab-invitation-link-component>
                    </div>
                    <div
                      *ngIf="item?.qr"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-qr-component
                        [tab_id]="tab_id"
                      ></app-tab-qr-component>
                    </div>
                    <div
                      *ngIf="item?.webView"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-web-view-component
                        [tab_id]="tab_id"
                      ></app-tab-web-view-component>
                    </div>
                    <div
                      *ngIf="item?.search"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-search-component
                        [tab_id]="tab_id"
                      ></app-tab-search-component>
                    </div>
                    <div
                      *ngIf="item?.mapSearch"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-map-search-component
                        [tab_id]="tab_id"
                      ></app-tab-map-search-component>
                    </div>
                    <div
                      *ngIf="item?.tabInfo"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-info-component
                        [tab_id]="tab_id"
                      ></app-tab-info-component>
                    </div>
                    <div
                      *ngIf="item?.chatWithAdmin"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-enable-chat-with-admin-component
                        [tab_id]="tab_id"
                      ></app-tab-enable-chat-with-admin-component>
                    </div>
                    <div
                      *ngIf="item?.chatWithAdminIcon"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-chat-with-admin-icon-component
                        [tab_id]="tab_id"
                      ></app-tab-chat-with-admin-icon-component>
                    </div>
                    <div
                      *ngIf="item?.postTags"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-post-tags-component
                        [tab_id]="tab_id"
                      ></app-tab-post-tags-component>
                    </div>
                    <div
                      *ngIf="item?.contextMenu"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-post-context-menu-component
                        [tab_id]="tab_id"
                      ></app-tab-post-context-menu-component>
                    </div>
                    <div
                      *ngIf="item?.bubble_theme"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-bubble-themes-component
                        [tab_id]="tab_id"
                      ></app-tab-bubble-themes-component>
                    </div>
                    <div
                      *ngIf="item?.enable_invitation_links"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-enable-invitation-link-component
                        [tab_id]="tab_id"
                      ></app-tab-enable-invitation-link-component>
                    </div>
                    <div
                      *ngIf="item?.channel_admin_talk"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-channel-chat-with-admin-component
                        [tab_id]="tab_id"
                      ></app-tab-channel-chat-with-admin-component>
                    </div>
                    <div
                      *ngIf="item?.channel_footer"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-channel-footer-component
                        [tab_id]="tab_id"
                      ></app-tab-channel-footer-component>
                    </div>
                    <div
                      *ngIf="item?.channel_context_menu"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-tab-channel-context-menu-component
                        [tab_id]="tab_id"
                      ></app-tab-channel-context-menu-component>
                    </div>
                  </div>
                </ng-container>
              </ul>
            </div>
          </tab>
        </ng-container>
      </tabset>
    </ngx-simplebar>
  </div>
</div>
