import { Injectable } from "@angular/core";
import {
  Chat,
  colorSchema,
  Menu,
  MenuButton,
  MenuRow,
  Page,
  Tab,
} from "../core/services/interface";
import { IndexDBService } from "../core/services/indexDB.service";
import { WebsocketService } from "../core/services/websocket.service";
import {
  CreateMyPage,
  SetAppConfig,
  SetChat,
  SetMyPage,
} from "../core/services/outbound";

import { MobileView, SPLASH_STYLE, TabNames, c1, c10, c11, c12, c13, c14, c15, c2, c3, c4, c5, c6, c7, c8, c9, common_button_migration_map, common_components_map, common_tab_migration_map, menu_button_map, splash_config_map } from "src/app/core/services/constants";
import { environment } from "src/environments/environment";
import { MiddlwareService } from "../core/services/middleware.service";
import { CorePalette, CorePaletteColors, Scheme, argbFromHex, hexFromArgb, themeFromImage, themeFromSourceColor } from "@material/material-color-utilities";
import { cl } from "@fullcalendar/core/internal-common";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BuilderService {
  AppConfigVersion: number = 0;
  offcanvas_data: any[] = [];

  constructor(
    public indexDBService: IndexDBService,
    public mdw: MiddlwareService,

  ) { }


  modes: any = [];


  /////////////////////////////////////////////////////////////////////////////////

  storeMenus(menus) {
    if (menus) {
      const menusIds = [];
      menus.forEach((menu) => {
        //// this for temp migration of old template and need to be removed later on  Hazem
        if (!menu.cat) {
          menu.cat = "menu";
        }
        if (!menu.tab_id) {
          // menu.tab_id = "1682858279"; /// it has to be removed Hazem
        }
        if (!menu.type) {
          menu.type = common_components_map[TabNames.MENU].type;
          menu.module = common_components_map[TabNames.MENU].type;
        }
        /////////////////////////////////////////////////////////////////////////////////////

        this.indexDBService.insertItem("menu", menu);
        let rowOrder = 0;
        menu.rows.forEach((row) => {
          //// this for temp migration of old template and need to be removed later on  Hazem
          if (!row.menu_id) {
            row.menu_id = menu.menu_id;
          }
          if (!row.row_id) {
            // row.row_id = this.mdw.makeRef(16);
            row.row_id = menu.menu_id + rowOrder;
          }
          if (!row.row_order) {
            row.row_order = rowOrder++;
          }
          ///////////////////////////////////////////////////////////////////////////////////
          let btnOrder = 0;
          row.buttons.forEach((btn) => {
            //// this for temp migration of old template and need to be removed later on  Hazem
            if (!btn.row_id) {
              btn.row_id = row.row_id;
            }
            if (!btn.button_order) {
              btn.button_order = btnOrder++;
            }

            if (!btn.button_code) {
              btn.button_code =
                common_button_migration_map[
                  btn.button_form
                ].button_code;
            }

            if (!btn.info) {
              btn.info = menu_button_map[btn.button_code].info;
            }

            ///////////////////////////////////////////////////////////////////////////////////

            this.indexDBService.insertItem("button", btn);
          });

          delete row.buttons;

          this.indexDBService.insertItem("row", row);
        });
      });
    }
  }

  storeMenu(menu) {
    if (menu) {
      this.indexDBService.insertItem("menu", menu);
      let rowOrder = 0;
      menu.rows.forEach((row) => {
        let btnOrder = 0;
        row.buttons.forEach((btn) => {
          this.indexDBService.insertItem("button", btn);
        });
        delete row.buttons;
        this.indexDBService.insertItem("row", row);
      });
    }
  }

  async deleteMenu(menu_id) {
    let menu = await this.constructMenuSync(menu_id);
    if (menu) {
      menu.rows.forEach((row) => {
        row.buttons.forEach((button) => {
          if (button.row_id == row.row_id) {
            const deletereq = this.indexDBService.deleteItemBykey(
              "button",
              button.button_id
            );
          }
        });
        const deletereq = this.indexDBService.deleteItemBykey(
          "row",
          row.row_id
        );
      });
      const deletereq = this.indexDBService.deleteItemBykey("menu", menu_id);
    }
  }

  async constructMenu(query) {
    return await this.indexDBService
      .getItemOnsucss("menu", query)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        menu.rows = await this.constructMenuRows(menu.menu_id);
        return menu;
      });
  }

  async constructMenusSync(query) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "cat", query)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        for (let i = 0; i < menus.length; i++) {
          menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
          newMenus.push(menus[i]);
        }
        return newMenus;
      });
  }

  async constructMenusALLSync(table, cat, tabList?) {
    let defaultStore = localStorage.getItem("store")
    return await this.indexDBService
      .getItemListOnsucss(table, null, null)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        // filter all tabs to get tab for stores and get their menu Group and only include those menus into the configuraiton.
        let stores = [];
        if (tabList){
          for (let i = 0; i < tabList.length; i++) {
            if (tabList[i].type == "store") {
              stores.push(tabList[i].menu_group)
            }
          }
        }

        for (let i = 0; i < menus.length; i++) {
          switch (cat) {
            case "app":
              //all
              if ((menus[i].cat != "splash" && menus[i].cat != "poll")) {
                if (menus[i].cat == "menu") {
                  menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
                  newMenus.push(menus[i]);
                } else if (menus[i].cat == "store" && stores.length > 0) {
                  for (let j = 0; j < stores.length; j++) {
                    if (menus[i].menu_group == stores[j]) {
                      menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
                      newMenus.push(menus[i]);
                    }
                  }
                }

              }
              break;
            case "splash":
              if (menus[i].cat == "splash") {
                menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
                newMenus.push(menus[i]);
              }
              break;

            case "store":
              //only store
              if (menus[i].cat == "store") {
                menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
                newMenus.push(menus[i]);
              }
              break;

            case "poll":
              //only poll
              if (menus[i].cat == "poll") {
                menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
                newMenus.push(menus[i]);
              }
              break;
          }
        }


        return newMenus;
      });
  }

  async constructMenusbyMenuGroupSync(menu_group) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "grp", menu_group)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        for (let i = 0; i < menus.length; i++) {
          menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
          newMenus.push(menus[i]);
        }
        return newMenus;
      });
  }


  async constructMenuSync(menuId) {
    return await this.indexDBService
      .getItemOnsucss("menu", menuId)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        if (menu) {
          menu.rows = await this.constructMenuRowsSync(menu.menu_id);
        }
        return await menu;
      });
  }

  async constructMenus(query) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "cat", query)
      .then(async (menuResponse: any) => {
        let menus = [];
        menuResponse.forEach(async (menu) => {
          let newMenu = menu;
          newMenu.rows = await this.constructMenuRows(menu.menu_id);
          menus.push(newMenu);
        });
        return await menus;
      });
  }

  async constructMenuRowsSync(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);
        let newRows = [];
        for (let i = 0; i < rows.length; i++) {
          rows[i].buttons = await this.constructMenuRowButtons(rows[i].row_id);
          newRows.push(rows[i]);
        }
        return newRows;
      });
  }

  async constructMenuRowButtons(rowId) {
    return await this.indexDBService
      .getItemListOnsucss("button", "row", rowId)
      .then(async (buttonResponse: any) => {
        let buttons = await this.sortButtons(buttonResponse);
        return buttons;
      });
  }

  async constructMenuRows(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);

        rows.forEach(async (row) => {
          let newRow = row;
          newRow.buttons = await this.constructMenuRowButtons(row.row_id);
        });
        return rows;
      });
  }

  sortRows(rows: any[]) {
    return rows.sort((a, b) => Number(a.row_order) - Number(b.row_order));
  }
  sortButtons(buttons: any[]) {
    return buttons.sort(
      (a, b) => Number(a.button_order) - Number(b.button_order)
    );
  }

  savePage(page) {
    if (page && page.cat == "page") {
      if (
        !localStorage.getItem("chat_id") ||
        page.group_id != localStorage.getItem("chat_id")
      ) {
        // Hazem it suppose to be main_chat
        page.cat = "cache";
      }
      page.url = `${environment.newpage_url}/${localStorage.getItem(
        "chat_id"
      )}/${page.id}`;

      const response = this.indexDBService.getItem("menu", page.id);
      response.onsuccess = (event) => {
        if (response.result) {
          let menu = response.result;

          if (!menu || (menu && menu.version < page.version)) {
            menu.menu_url = page.url;
            this.storeMenu(menu);
            // Hazem we may need to change tab here to allow mobile view to detect changes....
          }
        }
      };
    }
  }

  ///////////////////


  async constructOnlineChannelConfig() {
    let data: any = {};
    return await this.indexDBService
      .getItemOnsucss("app", "home")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            if (data.app.tabs && data.app.tabs.tabs) {
              return await this.indexDBService
                .getItemListOnsucss("tab", "cat", "home")
                .then(async (tabResponse) => {
                  data.app.tabs.tabs = tabResponse;
                  return await this.indexDBService
                    .getItemListOnsucss("tab", "cat", "next")
                    .then(async (tapMenu: Tab[]) => {
                      data.app.tabs.tabs = [...data.app.tabs.tabs, ...tapMenu];
                      let tempList = this.sortTabs(data.app.tabs.tabs);
                      data.app.tabs.tabs = [...tempList];
                      data.app.menus = await this.constructMenusALLSync("menu", "app", tempList);
                      this.constructAppStoreMenu();
                      return data;
                    });
                });
            } else {
              return data;
            }
          }
        }
      });
  }

  async constructOnlineAppConfig() {
    let data: any = { app: { tabs: { tabs: [{}] } } };
    return await this.indexDBService
      .getItemOnsucss("app", "side")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            return await this.indexDBService
              .getItemListOnsucss("tab", "cat", "side")
              .then(async (tabResponse) => {
                data.app.tabs = {};
                data.app.tabs.tabs = tabResponse;
                let tempList = this.sortTabs(data.app.tabs.tabs);
                data.app.tabs.tabs = [...tempList];
                return data;
              });
          }
        }
      });
  }

  async constructAppConfig() {
    ///Saving Splash first //////
    await this.constructSplash();
    /////////////////////////////
    let data: any = {};
    data.app = {};
    data.app.system = {};

    return await this.indexDBService
      .getItemOnsucss("item", "version")
      .then(async (versionResponse) => {
        if (versionResponse) {
          data.app.version = versionResponse;
          data.app.version.value++;
        }
        return await this.indexDBService
          .getItemOnsucss("item", "channel_default")
          .then(async (channelDefault) => {
            if (channelDefault) {
              data.app.channel_default = channelDefault;
            }
            return await this.indexDBService
              .getItemOnsucss("item", "settings")
              .then(async (settingsResponse) => {
                if (settingsResponse) {
                  data.app.settings = settingsResponse;
                }
                return await this.indexDBService
                  .getItemOnsucss("item", "splash")
                  .then(async (splashResponse) => {
                    if (splashResponse) {
                      data.app.splash = splashResponse;
                    }
                    return await this.indexDBService
                      .getItemOnsucss("item", "color")
                      .then(async (colorResponse) => {
                        if (colorResponse) {
                          data.app.system.color = colorResponse;
                        }
                        return await this.indexDBService
                          .getItemOnsucss("item", "app_info")
                          .then(async (response) => {
                            if (response) {
                              data.app.system.app_info = response;
                              // check for tabs
                              return await this.indexDBService
                                .getItemOnsucss("item", "logo_color")
                                .then(async (logoColor) => {
                                  if (logoColor) {
                                    data.app.system.app_info.logo_color = logoColor;
                                  }
                                  return await this.indexDBService
                                    .getItemOnsucss("item", "logo_color_ios")
                                    .then(async (logoColorIos) => {
                                      if (logoColorIos) {
                                        data.app.system.app_info.logo_color_ios =
                                          logoColorIos;
                                      }
                                      return await this.indexDBService
                                        .getItemOnsucss("item", "logo_white")
                                        .then(async (logoWhite) => {
                                          if (logoWhite) {
                                            data.app.system.app_info.logo_white =
                                              logoWhite;
                                          }
                                          return data;

                                        });
                                    });
                                });
                            } else { return data }
                          });
                      });
                  });
              });
          });
      });

  }



  async saveMobileViewMode() {
    // c1: android and ios
    // c2: full, home, side, component
    // c3: single, notab, manytab
    // c4: android home- top, bottom

    // c5: android home- wide and narraw

    // c6: ios home - side menu top, bottom
    // c7: ios home - standard and large title

    // c8: android no side(false) and side (true)
    // c9: ios no side(false) and side (true)

    // c10: ios home- wide and narraw

    localStorage.getItem("mode")
    await this.appHomeModeUpdate("mode", localStorage.getItem("mode"))
  }

  async appHomeModeUpdate(item, value) {
    return await this.indexDBService
      .getItemOnsucss("app", "home")
      .then(async (appHome) => {
        if (appHome) {
          let appHomeData: any = {}
          appHomeData = appHome;

          appHomeData.app.tabs[item] = value;

          if (value[3] == c4.TOP && value[4] == c5.WIDE) {
            appHomeData.app.tabs["tab_style"] = 0;
          }

          if (value[3] == c4.BOTTOM && value[4] == c5.WIDE) {
            appHomeData.app.tabs["tab_style"] = 1;
          }

          if (value[3] == c4.TOP && value[4] == c5.STANDARD) {
            appHomeData.app.tabs["tab_style"] = 2;
          }

          if (value[3] == c4.BOTTOM && value[4] == c5.STANDARD) {
            appHomeData.app.tabs["tab_style"] = 3;
          }


          if (value[9] == c10.WIDE) {
            appHomeData.app.tabs["tab_style_ios"] = 0;
          }

          if (value[9] == c10.STANDARD) {
            appHomeData.app.tabs["tab_style_ios"] = 1;
          }

          if (value[6] == c7.LARGE) {
            appHomeData.app.tabs["large_title"] = 1;
          } else {
            appHomeData.app.tabs["large_title"] = 0;
          }


          this.indexDBService.updateItem("app", appHomeData);
        }
        return true;
      })
  }

  async constructSplash() {
    let splash: any = { screens: [] }

    return await this.indexDBService
      .getItemListOnsucss("tab", "cat", "splash")
      .then(async (tabResponse) => {
        if (tabResponse && tabResponse[0] != null) {
          let tab = tabResponse[0];
          // splash = tab.param.splash;
          splash = this.getAlltabSplashItem(tab.param.splash, tab.param.splash.style)
          splash.style = tab.param.splash.style;
          splash.version = tab.tab_version;
          splash.id = 'splash';
          splash.menu_id = tab.menu_id;
          splash.tab_id = tab.id;


          let menus = await this.constructMenusALLSync("menu", "splash");
          if (menus) {
            let tempList = this.sortMenus(menus);
            menus = tempList;
            let screens = [];
            menus.forEach((menu) => {
              if (menu && menu.rows) {
                menu.rows.forEach((row) => {
                  if (row && row.buttons) {
                    row.buttons.forEach((button) => {
                      if (button) {
                        let splashScreen = {};
                        splashScreen["order"] = menu.menu_order;
                        splashScreen["menu_id"] = menu.menu_id;
                        splashScreen["code"] = tab.param.splash.style;
                        splashScreen["id"] = button.button_id;

                        // data start
                        splashScreen["title"] = this.readSplashItem(button, 'button_label', splash.style);
                        splashScreen["desc"] = this.readSplashItem(button, 'button_sublabel', splash.style);

                        splashScreen["image"] = this.readSplashItem(button, 'images', splash.style).url;
                        splashScreen["image_set"] = this.readSplashItem(button, 'images', splash.style).imageSet;

                        splashScreen["bg_image"] = this.readSplashItem(button, 'images', splash.style).bgImage;
                        splashScreen["bg_image_set"] = this.readSplashItem(button, 'images', splash.style).bgImageSet;

                        splashScreen["start_color"] = this.readSplashItem(button, 'button_bgstart', splash.style);
                        splashScreen["end_color"] = this.readSplashItem(button, 'button_bgend', splash.style);

                        // data end
                        splashScreen["version"] = button.button_version;
                        screens.push(splashScreen)
                      }

                    }
                    )
                  }

                })
              }

            })
            splash.screens = screens;
          }
          const response = this.indexDBService.updateItem("item", splash);
          response.onsuccess = (event) => {
            if (response.result) {

              return splash;

            }


          }

        }


      })
  }

  async constructAppStoreMenu() {
    return await this.indexDBService
      .getItemListOnsucss("tab", "cat", "store")
      .then(async (tabResponse) => {
        if (tabResponse) {
          let stores= [];
          let tabStoreList: any;
          tabStoreList =tabResponse
          for (let i = 0; i< tabStoreList.length; i++) {
            let tab = tabResponse[i];
            // tab params required
            tab = this.getAlltabStoreItem(tab)
            let shop = await this.constructMenusbyMenuGroupSync(tab.menu_group);
            tab['shop'] = shop;
           stores.push(tab)
          }

          this.mdw._storeContainer.next(stores);
        }
      })
  }


  getAlltabStoreItem(tab) {
    let data: any = {};
    data['id'] = tab.menu_group;
    data['menu_id'] = tab.menu_id;
    data['is_default'] = tab.id == localStorage.getItem("store")? 1: 0;
    data['name'] = tab.title;
    data['description'] = tab.desc;
    data['image'] = [{ url: tab.image_url }]
    data['version'] = tab.tab_version;
    if (tab.style) {
      data['style'] = tab.style;
    }

    return data;
  }
  async sendAppConfig() {
    await this.saveMobileViewMode();
    let onlineChannelConfig = await this.constructOnlineChannelConfig();

    let onlineAppConfig = await this.constructOnlineAppConfig();
    // console.log('onlineAppConfig =>', onlineAppConfig)
    let appConfig = await this.constructAppConfig();

    //  console.log('appConfig =>', appConfig)
    let menus: any = await this.constructMenus("home");

    let data: any = {};
    data.appConfig = appConfig;

    data.onlineAppConfig = onlineAppConfig;
    // copy in the side configuration as temp solution Hazem
    data.onlineAppConfig.app.channel_default = appConfig.app.channel_default

    data.onlineChannelConfig = onlineChannelConfig;
    if (appConfig && appConfig.app && appConfig.app.version) {
      this.indexDBService.updateItem("item", appConfig.app["version"]);
    }
    return data;
  }
  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  getIcon(str) {
    let icon: any
    icon = { type: "", id: "" };
    if (str) {
      icon.type = str.split(/_(.*)/s)[0];
      icon.id = str.split(/_(.*)/s)[1];
    }
    return icon;
  }

  setIcon(icon, type) {
    let str: any;
    if (icon) {
      if (type == "outlined") {
        str = "mir" + "-" + icon;
      }
      if (type == "filled") {
        str = "mis" + "-" + icon;
      }
      str = str.replaceAll("-", "_");
    } else {
      str = icon;
    }
    return str;
  }


  RemoveHeadingHTML(str) {
    if (str) {
      str = str.replaceAll("<h1>", "");
      str = str.replaceAll("<\/h1>", "");

      str = str.replaceAll("<h2>", "");
      str = str.replaceAll("<\/h2>", "");

      str = str.replaceAll("<h3>", "");
      str = str.replaceAll("<\/h3>", "");

      str = str.replaceAll("<h4>", "");
      str = str.replaceAll("<\/h4>", "");

      str = str.replaceAll("<h5>", "");
      str = str.replaceAll("<\/h5>", "");

      str = str.replaceAll("<h6>", "");
      str = str.replaceAll("<\/h6>", "");
    }

    // str = str.replaceAll("<h1", "<p");
    // str = str.replaceAll("<\/h1>", "<\/p>");

    // str = str.replaceAll("<h2", "<p");
    // str = str.replaceAll("<\/h2>", "<\/p>");

    // str = str.replaceAll("<h3", "<p");
    // str = str.replaceAll("<\/h3>", "<\/p>");

    // str = str.replaceAll("<h4", "<p");
    // str = str.replaceAll("<\/h4>", "<\/p>");

    // str = str.replaceAll("<h5", "<p");
    // str = str.replaceAll("<\/h5>", "<\/p>");

    // str = str.replaceAll("<h6", "<p");
    // str = str.replaceAll("<\/h6>", "<\/p>");

    return str
  }

  sortMenus(menus: Menu[]) {
    return menus.sort((a, b) => Number(a.menu_order) - Number(b.menu_order));

    // Hazem needs to populate the menu_order in order for this function to work.
  }

  getButtonSplashItem(button, item, style) {
    if (item === 'button_img_url') {
      if (style == SPLASH_STYLE.STYLE_01) {
        return button.button_images[style].url !== null ? button.button_images[style].url : splash_config_map[style].button_part.images[style].url;
      } else {
        return button.button_images['default'].url !== null ? button.button_images['default'].url : splash_config_map[style].button_part.images[style].url;
      }

    }
    if (item === 'button_bgstart') {
      return button.button_bgstart !== null ? button.button_bgstart : splash_config_map[style].button_part.start_color;
    }
    if (item === 'button_bgend') {
      return button.button_bgend !== null ? button.button_bgend : splash_config_map[style].button_part.end_color;
    }
    if (item === 'button_bgimage') {
      return button.button_bgimage !== null ? button.button_bgimage : splash_config_map[style].button_part.bg_image;
    }
    if (item === 'button_label') {
      return button.button_label !== null ? button.button_label : splash_config_map[style].button_part.title;
    }

  }


  getAlltabSplashItem(splash, style) {
    let data: any = {};
    data['title'] = this.getTabSplashItem(splash, 'title', style);
    data['button_title'] = this.getTabSplashItem(splash, 'button_title', style);
    data['button_bgcolor'] = this.getTabSplashItem(splash, 'button_bgcolor', style);
    data['button_text_color'] = this.getTabSplashItem(splash, 'button_text_color', style);
    data['tnc_text_color'] = this.getTabSplashItem(splash, 'tnc_text_color', style);
    data['tnc_link_color'] = this.getTabSplashItem(splash, 'tnc_link_color', style);
    if (style == '02' || style == '06' || style == '07') {
      data['tnc_bgcolor'] = null;
    } else {
      data['tnc_bgcolor'] = this.getTabSplashItem(splash, 'tnc_bgcolor', style);
    }

    return data;
  }




  getTabSplashItem(splash, item, style) {
    if (item == 'tnc_bgcolor') {
      return splash[item] !== null ? style == '02' ? null : splash[item] : splash_config_map[style].tab_part[item];
    } else {
      return splash[item] !== null ? splash[item] : splash_config_map[style].tab_part[item];
    }

  }

  readSplashItem(button, item, style) {
    if (style) {
      if (item === 'button_bgstart') {
        return button.button_bgstart !== null ? button.button_bgstart : splash_config_map[style].button_part.start_color;
      }

      if (item === 'button_bgend') {
        return button.button_bgend !== null ? button.button_bgend : splash_config_map[style].button_part.end_color;
      }

      if (item === 'button_label') {
        return button.button_label !== null ? button.button_label : splash_config_map[style].button_part.title;
      }

      if (item === 'button_sublabel') {
        return button.button_sublabel !== null ? button.button_sublabel : splash_config_map[style].button_part.desc;
      }

      if (item === 'images') {

        let images: any = {};

        switch (style) {
          //we will carry everything except image and bgimage
          case SPLASH_STYLE.STYLE_01:
            images['url'] = button.button_images['01'].url !== null ? button.button_images['01'].url : splash_config_map[style].button_part.images['01'].url;;
            images['imageSet'] = button.button_images['01'].url !== null ? button.button_images['01'].imageSet : splash_config_map[style].button_part.images['01'].imageSet;;
            images['bgImage'] = null;
            images['bgImageSet'] = null;

            return images;

            break;

          case SPLASH_STYLE.STYLE_03:
            images['url'] = null;
            images['imageSet'] = null;
            images['bgImage'] = button.button_bgimage !== null ? button.button_bgimage : splash_config_map[style].button_part.bg_image;
            images['bgImageSet'] = button.button_bgimageSet !== null ? button.button_bgimageSet : splash_config_map[style].button_part.bg_imageSet;

            return images;
            break;

          default:
            images['url'] = button.button_images['default'].url !== null ? button.button_images['default'].url : splash_config_map[style].button_part.images[style].url;;
            images['imageSet'] = button.button_images['default'].url !== null ? button.button_images['default'].imageSet : splash_config_map[style].button_part.images[style].imageSet;
            images['bgImage'] = null;
            images['bgImageSet'] = null;
            return images;
            break;

        }

      }
    }
  }

  getCellTheme(button, item, value) {
    let str = button.button_style;
    let c_value = value ? 1 : 0;

    //x000
    //x   : cell style
    //2nd : seperator
    //3rd : border
    //4th : mirror
    var regc1 = /(?<=^.{0})./gi;
    var regc2 = /(?<=^.{1})./gi;
    var regc3 = /(?<=^.{2})./gi;
    var regc4 = /(?<=^.{3})./gi;

    if (item == 'outlined') {
      return str = str.replace(regc3, c_value)
    }

    if (item == 'divider') {
      return str = str.replace(regc2, c_value)
    }

    if (item == 'mirror') {
      return str = str.replace(regc4, c_value)
    }
  }

  extraAndroidMaterialColor(palette, colors, os?, m2?, component?, m2_ios?, sender_bubble?, receiver_bubble?) {
    //  sender_bubble accepts 'primary', 'secondary' , 'tertiary'
    //  receiver_bubble accepts 'lowest, low, high,
    //
    if (os == null || os == c1.ANDRIOD) {
      let isBottom = localStorage.getItem("mode")[3] == c4.BOTTOM ? true : false;

      /// implement the colors of the sender and receiver bubbles
      // default
      if (m2 == null) {
        m2 = localStorage.getItem("mode")[10]
      }
      if (component == null) {
        component = localStorage.getItem("mode")[11]
      }

      if (os == null) {
        os = localStorage.getItem("mode")[0]
      }

      if (m2_ios == null) {
        m2_ios = localStorage.getItem("mode")[12];
      }

      if (sender_bubble == null) {
        switch (localStorage.getItem("mode")[13]) {
          case c14.PRIMARY:
            sender_bubble = "primary"
            break;
          case c14.SECONDARY:
            sender_bubble = "secondary"
            break;
          case c14.TERTIARY:
            sender_bubble = "tertiary"
            break;
          default:
            sender_bubble = "primary"
            break;
        }
      }
      if (receiver_bubble == null) {
        receiver_bubble = localStorage.getItem("mode")[14] ? localStorage.getItem("mode")[14] : c15.LOWEST;
      }


      ////////////////////////////
      //mPrimaryContainerBright   for sender bubble
      colors.schemes.light.mPrimaryContainerBright = hexFromArgb(colors.palettes[sender_bubble].tone(93));
      colors.schemes.dark.mPrimaryContainerBright = hexFromArgb(colors.palettes[sender_bubble].tone(28));

      //mPrimaryContainer   for sender bubble reply  90 and 30 are the primaryContainer
      colors.schemes.light.mPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(90));
      colors.schemes.dark.mPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(30));

      //mOnPrimaryContainer   for sender bubble reply headline
      colors.schemes.light.mOnPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(10));
      colors.schemes.dark.mOnPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(90));

      /// selected colors must have alpha with 88 to be transparent
      let light_mPrimaryInverseTransparent = hexFromArgb(colors.palettes[sender_bubble].tone(80));
      let dark_mPrimaryInverseTransparent = hexFromArgb(colors.palettes[sender_bubble].tone(40));

      colors.schemes.light.mPrimaryInverseTransparent = this.changeColorAlpha(light_mPrimaryInverseTransparent, 0.88);
      colors.schemes.dark.mPrimaryInverseTransparent = this.changeColorAlpha(dark_mPrimaryInverseTransparent, 0.88);


      switch (receiver_bubble) {
        case c15.LOW:
          //mSurfaceContainerBright   for receiver bubble
          colors.schemes.light.mSurfaceContainerBright = colors.schemes.light.surfaceContainerLow;
          colors.schemes.dark.mSurfaceContainerBright = colors.schemes.dark.surfaceContainerLow;
          break;

        case c15.HIGH:
          //surfaceContainerBright   for receiver bubble
          colors.schemes.light.mSurfaceContainerBright = colors.schemes.light.surfaceContainerHigh;
          colors.schemes.dark.mSurfaceContainerBright = colors.schemes.dark.surfaceContainerHigh;
          break;

        case c15.LOWEST:
          //surfaceContainerBright   for receiver bubble
          colors.schemes.light.mSurfaceContainerBright = colors.schemes.light.surfaceContainerLowest;
          colors.schemes.dark.mSurfaceContainerBright = colors.schemes.dark.surfaceContainerLowest;
          break;

      }

      /// mSurfaceContainerHighest for receiver bubble reply
      colors.schemes.light.mSurfaceContainerHighest = colors.schemes.light.surfaceContainerHighest;
      colors.schemes.dark.mSurfaceContainerHighest = colors.schemes.dark.surfaceContainerHighest;

      //mOnSurfaceVariant   for reciever bubble reply  headline and bar
      colors.schemes.light.mOnSurfaceVariant = colors.schemes.light.onSurfaceVariant;
      colors.schemes.dark.mOnSurfaceVariant = colors.schemes.dark.onSurfaceVariant;
      /////////////////////  END /////////////////////////

      if (m2 == c11.M2) {

        //m2
        if (component == c12.HOME || component == c12.ALL) {
          //m2Primary   : Active Tabs
          colors.schemes.light.m2Primary = colors.schemes.light.onPrimary;
          colors.schemes.dark.m2Primary = colors.schemes.dark.onPrimary;

          //m2onSurfaceVariant : Non-Active Tabs  // used to be surfaceContainerLow changed to surfaceDim
          colors.schemes.light.m2OnSurfaceVariant = colors.schemes.light.surfaceDim;
          colors.schemes.dark.m2OnSurfaceVariant = colors.schemes.dark.surfaceDim;

          //m2APrimary  :  slider in the Tabs
          colors.schemes.light.m2APrimary = hexFromArgb(colors.palettes['primary'].tone(35));  // primaryDark
          colors.schemes.dark.m2APrimary = hexFromArgb(colors.palettes['primary'].tone(85));


          //m2Surface    : Background
          colors.schemes.light.m2Surface = hexFromArgb(colors.palettes['primary'].tone(35)); // primaryDark
          colors.schemes.dark.m2Surface = hexFromArgb(colors.palettes['primary'].tone(85));


          //m2OnSurface    : Text on the Status Bar
          colors.schemes.light.m2OnSurface = colors.schemes.light.onPrimary;
          colors.schemes.dark.m2OnSurface = colors.schemes.dark.onPrimary;


          //m2ASurface  :  Background
          colors.schemes.light.m2ASurface = colors.schemes.light.primary;
          colors.schemes.dark.m2ASurface = colors.schemes.dark.primary;


          //m2AonSurfaceVariant  :  Text on badge
          colors.schemes.light.m2AonSurfaceVariant = colors.schemes.light.onSecondary
          colors.schemes.dark.m2AonSurfaceVariant = colors.schemes.dark.onSecondary;

          // m2CSurface  // general background
          colors.schemes.light.m2CSurface = colors.schemes.light.surfaceContainerLowest;
          colors.schemes.dark.m2CSurface = colors.schemes.dark.surfaceContainerLowest;


          //lightStatusBar  : used to color the text on the Status bar (false/true)
          colors.style.lightStatusBar = '0';

          //bottom device status bar  set only primary when the navigation bottom is in place.
          colors.schemes.light.m2DSurface = isBottom ? colors.schemes.light.primary : colors.schemes.light.surface;
          colors.schemes.dark.m2DSurface = isBottom ? colors.schemes.dark.primary : colors.schemes.dark.surface;

          //lightBottomNavigationBar  1 means light , 0 means dark
          colors.style.lightBottomNavigationBar = isBottom ? '1' : '0';

        }

        if (component == c12.SIDE || component == c12.ALL) {

          //m2BSurface  :   Drawer Background
          colors.schemes.light.m2BSurface = colors.schemes.light.surfaceContainerLowest;
          colors.schemes.dark.m2BSurface = colors.schemes.dark.surfaceContainerLowest;

          //m2SurfaceContainer  :   Navigation Drawer Badge background
          colors.schemes.light.m2SurfaceContainer = colors.schemes.light.secondary;
          colors.schemes.dark.m2SurfaceContainer = colors.schemes.dark.secondary;

          //m2SurfaceContainerHigh  :   Custom Top Navigation Drawer Background
          colors.schemes.light.m2SurfaceContainerHigh = colors.schemes.light.primary;
          colors.schemes.dark.m2SurfaceContainerHigh = colors.schemes.dark.primary;
        }

      } else {

        //m3
        if (component == c12.HOME || component == c12.ALL) {

          //m2Primary   : Active Tabs
          colors.schemes.light.m2Primary = colors.schemes.light.primary;
          colors.schemes.dark.m2Primary = colors.schemes.dark.primary;

          //m2onSurfaceVariant : Non-Active Tabs
          colors.schemes.light.m2OnSurfaceVariant = colors.schemes.light.onSurfaceVariant
          colors.schemes.dark.m2OnSurfaceVariant = colors.schemes.dark.onSurfaceVariant;

          //m2APrimary  :  slider in the Tabs
          colors.schemes.light.m2APrimary = colors.schemes.light.primary;
          colors.schemes.dark.m2APrimary = colors.schemes.dark.primary;

          //m2Surface    : Background
          colors.schemes.light.m2Surface = colors.schemes.light.surface;
          colors.schemes.dark.m2Surface = colors.schemes.dark.surface;

          //m2OnSurface    : Text on the Status Bar
          colors.schemes.light.m2Surface = colors.schemes.light.surface
          colors.schemes.dark.m2Surface = colors.schemes.dark.surface;

          //m2ASurface  :  Background
          colors.schemes.light.m2ASurface = colors.schemes.light.surface;
          colors.schemes.dark.m2ASurface = colors.schemes.dark.surface;

          //m2AonSurfaceVariant  :  Text on badge
          colors.schemes.light.m2AonSurfaceVariant = colors.schemes.light.surfaceVariant
          colors.schemes.dark.m2AonSurfaceVariant = colors.schemes.dark.surfaceVariant;

          // m2CSurface  // general background
          colors.schemes.light.m2CSurface = colors.schemes.light.surface;
          colors.schemes.dark.m2CSurface = colors.schemes.dark.surface;

          //lightStatusBar  : used to color the text on the Status bar (false/true)
          colors.style.lightStatusBar = '1';


          //bottom device status bar
          colors.schemes.light.m2DSurface = colors.schemes.light.surface;
          colors.schemes.dark.m2DSurface = colors.schemes.dark.surface;

          //lightBottomStatusBar  1 means light , 0 means dark
          colors.style.lightBottomStatusBar = '0';

        }

        if (component == c12.SIDE || component == c12.ALL) {

          //m2BSurface  :   Drawer Background
          colors.schemes.light.m2BSurface = colors.schemes.light.surface;
          colors.schemes.dark.m2BSurface = colors.schemes.dark.surface;

          //m2SurfaceContainer  :   Navigation Drawer Badge background
          colors.schemes.light.m2SurfaceContainer = colors.schemes.light.surfaceContainer;
          colors.schemes.dark.m2SurfaceContainer = colors.schemes.dark.surfaceContainer;

          //m2SurfaceContainerHigh  :   Custom Top Navigation Drawer Background
          colors.schemes.light.m2SurfaceContainerHigh = colors.schemes.light.surfaceContainerHigh;
          colors.schemes.dark.m2SurfaceContainerHigh = colors.schemes.dark.surfaceContainerHigh;
        }
      }

    } else if (os == c1.IOS) {
      if (m2_ios == c13.M2) {
        //m2Primary   : Active Tabs
        colors.schemes.light.n2onSurace = colors.schemes.light.primary;
        colors.schemes.dark.n2onSurace = colors.schemes.dark.primary;
      } else {
        colors.schemes.light.n2onSurace = colors.schemes.light.onSurface;
        colors.schemes.dark.n2onSurace = colors.schemes.dark.onSurface;
      }

    }
    return colors
  }


  buttonColor(buttonSchema, colorSchema, sourceColor, targetColor, light) {
    if (light) {
      // light
      if (buttonSchema) {
        return buttonSchema.schemes.light[sourceColor] ? buttonSchema.schemes.light[sourceColor] : colorSchema.schemes.light[targetColor]
      } else {
        return colorSchema.schemes.light[targetColor]
      }
    } else {
      // dark
      if (buttonSchema) {
        return buttonSchema.schemes.dark[sourceColor] ? buttonSchema.schemes.light[sourceColor] : colorSchema.schemes.dark[targetColor]
      } else {
        return colorSchema.schemes.dark[targetColor]
      }

    }
  }



  /// extract the dark color from original color.////////////////////
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c;
    let i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  addAlpha(color, opacity) {
    // coerce values so it is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }


  changeColorAlpha(color, opacity) {
    //if it has an alpha, remove it
    if (color.length > 7)
      color = color.substring(0, color.length - 2);

    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    let opacityHex = _opacity.toString(16).toUpperCase()

    // opacities near 0 need a trailing 0
    if (opacityHex.length == 1)
      opacityHex = "0" + opacityHex;

    return '#' + opacityHex + color.replace('#', '');
  }


  makeRef(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
}


